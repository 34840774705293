/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { parseEntityRef } from '@backstage/catalog-model';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { ScmIntegrationRegistry } from '@backstage/integration';
import { Observable } from '@backstage/types';
import qs from 'qs';
import ObservableImpl from 'zen-observable';
import {
  ListActionsResponse,
  LogEvent,
  ScaffolderApi,
  TemplateParameterSchema,
  ScaffolderScaffoldOptions,
  ScaffolderScaffoldResponse,
  ScaffolderStreamLogsOptions,
  ScaffolderGetIntegrationsListOptions,
  ScaffolderGetIntegrationsListResponse,
  ScaffolderTask,
  ScaffolderDryRunOptions,
  ScaffolderDryRunResponse,
} from './types';
import queryString from 'qs';

/**
 * This method checks for kind property inside the string and returns an object { kind: 'something' }
 * @param entityName
 * @param value
 * @returns
 */
export const checkForKind = (entityName: any, value: string) => {
  if (value.split(/:|\//).length <= 3) {
    if (value.includes(':')) {
      entityName.kind = value.substring(0, value.indexOf(':'));
    } else {
      entityName.kind = 'system';
    }
  }

  return entityName;
};

/**
 * This method checks for namespace property inside the string and returns an object { namespace: 'something' }
 * @param entityName
 * @param value
 * @returns
 */
export const checkForNamespace = (entityName: any, value: string) => {
  if (value.split(/:|\//).length <= 3) {
    if (value.includes('/')) {
      if (value.includes(':')) {
        entityName.namespace = value.substring(
          value.indexOf(':') + 1,
          value.indexOf('/'),
        );
      } else {
        entityName.namespace = value.substring(0, value.indexOf('/'));
      }
    } else {
      entityName.namespace = 'default';
    }
  }

  return entityName;
};

/**
 * This method checks for name property inside the string and returns an object { name: 'something' }
 * @param entityName
 * @param value
 * @returns
 */
export const checkForName = (entityName: any, value: string) => {
  if (value.split(/:|\//).length <= 3) {
    if (value.includes('/')) {
      entityName.name = value.substring(value.indexOf('/') + 1);
    }

    if (value.includes(':') && !value.includes('/')) {
      entityName.name = value.substring(value.indexOf(':') + 1);
    }

    if (!value.includes(':') && !value.includes('/')) {
      entityName.name = value;
    }
  }

  return entityName;
};

/**
 * This method deserialize a string into EntityName value composed of { kind, namespace, name }
 * @param value
 * @returns
 */
export const deserializeEntityRef = (value: string): any | undefined => {
  const entityName = [checkForKind, checkForNamespace, checkForName].reduce(
    (entityNameResult, checkAttribute) =>
      checkAttribute(entityNameResult, value),
    {},
  );

  if (!Object.keys(entityName).length) {
    return undefined;
  }

  return entityName;
};

/**
 * Utility API reference for the {@link ScaffolderApi}.
 *
 * @public
 */
export const scaffolderApiRef = createApiRef<ScaffolderApi>({
  id: 'plugin.scaffolder.service',
});

/**
 * An API to interact with the scaffolder backend.
 *
 * @public
 */
export class ScaffolderClient implements ScaffolderApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly scmIntegrationsApi: ScmIntegrationRegistry;
  private readonly fetchApi: FetchApi;
  private readonly identityApi?: IdentityApi;
  private readonly useLongPollingLogs: boolean;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi?: IdentityApi;
    scmIntegrationsApi: ScmIntegrationRegistry;
    useLongPollingLogs?: boolean;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi ?? { fetch };
    this.scmIntegrationsApi = options.scmIntegrationsApi;
    this.useLongPollingLogs = options.useLongPollingLogs ?? false;
    this.identityApi = options.identityApi;
  }

  async listTasks(options: {
    filterByOwnership: 'owned' | 'all';
  }): Promise<{ tasks: ScaffolderTask[] }> {
    if (!this.identityApi) {
      throw new Error(
        'IdentityApi is not available in the ScaffolderClient, please pass through the IdentityApi to the ScaffolderClient constructor in order to use the listTasks method',
      );
    }
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const { userEntityRef } = await this.identityApi.getBackstageIdentity();

    const query = queryString.stringify(
      options.filterByOwnership === 'owned' ? { createdBy: userEntityRef } : {},
    );

    const response = await this.fetchApi.fetch(`${baseUrl}/v2/tasks?${query}`);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async getIntegrationsList(
    options: ScaffolderGetIntegrationsListOptions,
  ): Promise<ScaffolderGetIntegrationsListResponse> {
    const integrations = [
      ...this.scmIntegrationsApi.azure.list(),
      ...this.scmIntegrationsApi.bitbucket
        .list()
        .filter(
          item =>
            !this.scmIntegrationsApi.bitbucketCloud.byHost(item.config.host) &&
            !this.scmIntegrationsApi.bitbucketServer.byHost(item.config.host),
        ),
      ...this.scmIntegrationsApi.bitbucketCloud.list(),
      ...this.scmIntegrationsApi.bitbucketServer.list(),
      ...this.scmIntegrationsApi.gerrit.list(),
      ...this.scmIntegrationsApi.github.list(),
      ...this.scmIntegrationsApi.gitlab.list(),
    ]
      .map(c => ({ type: c.type, title: c.title, host: c.config.host }))
      .filter(c => options.allowedHosts.includes(c.host));

    return {
      integrations,
    };
  }

  async getTemplateParameterSchema(
    templateRef: string,
  ): Promise<TemplateParameterSchema> {
    const { namespace, kind, name } = parseEntityRef(templateRef);

    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const templatePath = [namespace, kind, name]
      .map(s => encodeURIComponent(s))
      .join('/');

    const url = `${baseUrl}/v2/templates/${templatePath}/parameter-schema`;

    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const schema: TemplateParameterSchema = await response.json();
    return schema;
  }

  /**
   * Executes the scaffolding of a component, given a template and its
   * parameter values.
   *
   * @param options - The {@link ScaffolderScaffoldOptions} the scaffolding.
   */
  async scaffold(
    options: ScaffolderScaffoldOptions,
  ): Promise<ScaffolderScaffoldResponse> {
    const { templateRef, values, secrets = {} } = options;
    const url = `${await this.discoveryApi.getBaseUrl('scaffolder')}/v2/tasks`;
    const date = new Date();
    const formattedDate: string = `${date.toISOString()}`;
    const newValues = options.templateRef.startsWith('edittemplate')
      ? {
          modifiedByRef: templateRef,
          updatedDate: formattedDate,
        }
      : {
          useCaseTemplateID: templateRef,
          creationDate: formattedDate,
        };
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateRef,
        values: {
          ...values,
          ...newValues,
        },
        secrets,
      }),
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const { id } = (await response.json()) as { id: string };
    return { taskId: id };
  }

  async getTask(taskId: string): Promise<ScaffolderTask> {
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const url = `${baseUrl}/v2/tasks/${encodeURIComponent(taskId)}`;

    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  streamLogs(options: ScaffolderStreamLogsOptions): Observable<LogEvent> {
    if (this.useLongPollingLogs) {
      return this.streamLogsPolling(options);
    }

    return this.streamLogsEventStream(options);
  }

  async dryRun(
    options: ScaffolderDryRunOptions,
  ): Promise<ScaffolderDryRunResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const response = await this.fetchApi.fetch(`${baseUrl}/v2/dry-run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        template: options.template,
        values: options.values,
        secrets: options.secrets,
        directoryContents: options.directoryContents,
      }),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  private streamLogsEventStream({
    taskId,
    after,
  }: {
    taskId: string;
    after?: number;
  }): Observable<LogEvent> {
    return new ObservableImpl(subscriber => {
      const params = new URLSearchParams();
      if (after !== undefined) {
        params.set('after', String(Number(after)));
      }

      this.discoveryApi.getBaseUrl('scaffolder').then(
        baseUrl => {
          const url = `${baseUrl}/v2/tasks/${encodeURIComponent(
            taskId,
          )}/eventstream`;
          const eventSource = new EventSource(url, { withCredentials: true });
          eventSource.addEventListener('log', (event: any) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
          });
          eventSource.addEventListener('completion', (event: any) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
            eventSource.close();
            subscriber.complete();
          });
          eventSource.addEventListener('error', event => {
            subscriber.error(event);
          });
        },
        error => {
          subscriber.error(error);
        },
      );
    });
  }

  private streamLogsPolling({
    taskId,
    after: inputAfter,
  }: {
    taskId: string;
    after?: number;
  }): Observable<LogEvent> {
    let after = inputAfter;

    return new ObservableImpl(subscriber => {
      this.discoveryApi.getBaseUrl('scaffolder').then(async baseUrl => {
        while (!subscriber.closed) {
          const url = `${baseUrl}/v2/tasks/${encodeURIComponent(
            taskId,
          )}/events?${qs.stringify({ after })}`;
          const response = await this.fetchApi.fetch(url);

          if (!response.ok) {
            // wait for one second to not run into an
            await new Promise(resolve => setTimeout(resolve, 1000));
            continue;
          }

          const logs = (await response.json()) as LogEvent[];

          for (const event of logs) {
            after = Number(event.id);

            subscriber.next(event);

            if (event.type === 'completion') {
              subscriber.complete();
              return;
            }
          }
        }
      });
    });
  }

  async listActions(): Promise<ListActionsResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const response = await this.fetchApi.fetch(`${baseUrl}/v2/actions`);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
