import { EnumFilter } from '@agilelab/plugin-wb-platform';
import {
  useEntityList,
  EntityOrphanFilter,
  EntityErrorFilter,
} from '@backstage/plugin-catalog-react';
import React, { useState } from 'react';

export const CustomEntityProcessingStatusPicker: React.FC = () => {
  const { updateFilters } = useEntityList();

  const [selectedAdvancedItems, setSelectedAdvancedItems] = useState<string[]>(
    [],
  );

  function orphanChange(value: boolean) {
    updateFilters({
      orphan: value ? new EntityOrphanFilter(value) : undefined,
    });
  }

  function errorChange(value: boolean) {
    updateFilters({
      error: value ? new EntityErrorFilter(value) : undefined,
    });
  }

  const availableAdvancedItems = ['Is Orphan', 'Has Error'];

  return (
    <EnumFilter<string>
      value={selectedAdvancedItems}
      options={availableAdvancedItems}
      renderOption={o => o}
      renderValue={v => v}
      field="Processing status"
      onChange={v => {
        const value = v || [];
        setSelectedAdvancedItems(value);
        orphanChange(value.includes('Is Orphan'));
        errorChange(value.includes('Has Error'));
      }}
      onSearch={v =>
        availableAdvancedItems.filter(o => new RegExp(v, 'ig').test(o))
      }
    />
  );
};
