import { AppTheme } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import { CssBaseline, StyleRules, ThemeProvider } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import React, { useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  basicTheme,
  createCustomThemeOverrides,
  createTypography,
} from './LightAgileTheme';

const getPalette = async (): Promise<PaletteOptions | null> => {
  try {
    const res = await fetch('/custom_style/customPalette.json');
    const palette = await res.json();
    return palette;
  } catch (_error) {
    return null;
  }
};

const getOverrides = async (): Promise<Partial<StyleRules<any, {}>> | null> => {
  try {
    const res = await fetch('/custom_style/customOverrides.json');
    const palette = await res.json();
    return palette;
  } catch (_error) {
    return null;
  }
};

export default function useCustomTheme() {
  const {
    value: palette,
    loading: loadingPalette,
    error: errorPalette,
  } = useAsync(async () => {
    return await getPalette();
  });

  const {
    value: overrides,
    loading: loadingOverrides,
    error: errorOverrides,
  } = useAsync(async () => {
    return await getOverrides();
  });

  const customBasicTheme: BackstageTheme | null = useMemo(() => {
    if (palette || overrides) {
      const theme = {
        ...basicTheme,
        palette: {
          ...basicTheme.palette,
          ...palette,
        },
      } as BackstageTheme;
      return {
        ...theme,
        overrides: {
          // These are your custom overrides, either to `material-ui` or Backstage components.
          ...createCustomThemeOverrides(theme),
          ...overrides,
        },
        typography: {
          ...createTypography(theme),
        },
      };
    }
    return null;
  }, [palette, overrides]);

  return {
    error: errorPalette || errorOverrides,
    loading: loadingPalette || loadingOverrides,
    theme: customBasicTheme
      ? ({
          id: 'custom-theme-light',
          title: 'Custom Light',
          variant: 'light',
          Provider: ({ children }) => {
            return (
              <ThemeProvider theme={customBasicTheme}>
                <CssBaseline>{children}</CssBaseline>
              </ThemeProvider>
            );
          },
        } as Partial<AppTheme> & Omit<AppTheme, 'theme'>)
      : null,
  };
}
