import React, { useEffect, useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { alertApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import { editorWizardRouteRef } from '@agilelab/plugin-wb-scaffolder';
import {
  NO_EDIT_TEMPLATE_FOUND_ERROR,
  TOO_MANY_EDIT_TEMPLATES_ERROR,
} from '@agilelab/plugin-wb-platform-common';
import { Button as LinkButton } from '@backstage/core-components';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';

const useStyles = makeStyles(theme => ({
  editButton: {
    marginRight: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  optionButton: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const EditButton = (props: { entity: Entity }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const editorWizardRoute = useRouteRef(editorWizardRouteRef);
  const entityKind = props.entity.kind.toLocaleLowerCase();
  const entityRef = {
    name: props.entity.metadata.name,
    kind: entityKind,
    namespace: props.entity.metadata.namespace ?? 'default',
  };
  const hrefEditorWizard = editorWizardRoute(entityRef);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const { items } = await catalogApi.getEntities({
          filter: {
            kind: 'EditTemplate',
            'spec.useCaseTemplateId': (props.entity as WitboostEntity).spec.mesh
              .useCaseTemplateId,
          },
        });
        if (items.length === 0) {
          setErrorMessage(NO_EDIT_TEMPLATE_FOUND_ERROR);
        } else if (items.length > 1) {
          setErrorMessage(TOO_MANY_EDIT_TEMPLATES_ERROR);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    }

    fetchData();
  }, [props.entity, setErrorMessage, alertApi, catalogApi]);

  return (
    <Box style={{ paddingRight: '5px' }}>
      <Tooltip title={errorMessage}>
        <span>
          <LinkButton
            disableRipple
            startIcon={<FormatListBulleted />}
            to={hrefEditorWizard}
            disabled={errorMessage !== ''}
            color="primary"
            size="small"
            variant="contained"
            className={classes.editButton}
            style={{ textDecoration: 'none' }}
          >
            Editor Wizard
          </LinkButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default EditButton;
