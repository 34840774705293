import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button as LinkButton } from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { useAsync, useMountEffect } from '@react-hookz/web';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { WitboostComponent } from '@agilelab/plugin-wb-builder-common';
import { reverseProvisioningRouteRef } from '@agilelab/plugin-wb-scaffolder';
import { AutoFixHighIcon } from '@agilelab/plugin-wb-platform';
import { NO_REVERSE_PROVISIONING_TEMPLATE_FOUND_ERROR } from '@agilelab/plugin-wb-platform-common';

const useStyles = makeStyles(theme => ({
  importButton: {
    marginRight: '5px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  optionButton: {
    color: theme.palette.secondary.main,
    transition: 'color 0.3s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const ImportButton = (props: { entity: Entity }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const reverseProvisioningRoute = useRouteRef(reverseProvisioningRouteRef);
  const href = reverseProvisioningRoute({
    name: props.entity.metadata.name,
    kind: props.entity.kind.toLocaleLowerCase(),
    namespace: props.entity.metadata.namespace ?? 'default',
  });

  const [state, actions] = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'ReverseProvisioningTemplate',
        'spec.useCaseTemplateId': (props.entity as WitboostComponent).spec.mesh
          .useCaseTemplateId,
      },
      limit: 1,
    });
    return items.length === 1;
  });

  useMountEffect(actions.execute);

  return (
    <Box style={{ paddingRight: '5px' }}>
      <Tooltip
        title={
          state.status !== 'success' || !state.result
            ? NO_REVERSE_PROVISIONING_TEMPLATE_FOUND_ERROR
            : ''
        }
      >
        <span>
          <LinkButton
            disableRipple
            startIcon={<AutoFixHighIcon />}
            to={href}
            disabled={state.status !== 'success' || !state.result}
            size="small"
            color="primary"
            variant="contained"
            className={classes.importButton}
            style={{ textDecoration: 'none' }}
          >
            Reverse Provisioning
          </LinkButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ImportButton;
