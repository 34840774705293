import {
  CollapseButton,
  WbCard,
  WbCardActionButton,
  WbCardContent,
  WbEnvironmentSelector,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { builderDpPoliciesTest } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { usePermission } from '@backstage/plugin-permission-react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { panelCatalogApiRef } from '../../../api';
import { useControlPanel } from '../useControlPanel';
import { TestTable } from '../TestTable/TestTable';
import { TestCardProvider, useTestCard } from './useTestCard';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    select: {
      minWidth: 140,
      marginLeft: theme.spacing(1),
    },
    cardContent: {
      display: 'flex',
      height: '500px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'baseline',
    },
  }),
);

export function LaunchTestButton(props: {
  previewDescriptor: string | null | undefined;
  isLoadingDescriptor: boolean;
}) {
  const { previewDescriptor, isLoadingDescriptor } = props;
  const { entity } = useControlPanel();
  const { fetchTests } = useTestCard();
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const configApi = useApi(configApiRef);

  const [loading, setLoading] = useState(false);

  const launchPoliciesTests = useCallback(async () => {
    if (!previewDescriptor || isLoadingDescriptor) {
      alertApi.post({
        message: 'Cannot launch policies tests without a descriptor.',
        severity: 'error',
      });
      return;
    }
    setLoading(true);
    if (entity) {
      try {
        const useAsyncValidation = configApi.getOptionalBoolean(
          'catalog.enableAsyncValidation',
        );
        if (useAsyncValidation) {
          await panelCatalog.validateDescriptorAsync(
            previewDescriptor,
            entity?.metadata.name,
            await identityApi.getCredentials(),
          );
        } else {
          await panelCatalog.validateDescriptor(
            previewDescriptor,
            entity?.metadata.name,
            await identityApi.getCredentials(),
          );
        }
        fetchTests();
      } catch (error) {
        alertApi.post({
          error,
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    alertApi,
    configApi,
    entity,
    fetchTests,
    identityApi,
    panelCatalog,
    previewDescriptor,
    isLoadingDescriptor,
  ]);

  const { allowed: canLaunchTest } = usePermission({
    permission: builderDpPoliciesTest,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  return (
    <Tooltip
      title={
        canLaunchTest
          ? 'Test the descriptor for the selected release and environment. The test will verify that the descriptor is compliant with all the governance policies.'
          : 'You are not allowed run tests for the selected release and environment.'
      }
    >
      <span>
        <WbCardActionButton
          icon={<PlayIcon />}
          label="Test"
          onClick={launchPoliciesTests}
          loading={loading}
          disabled={loading}
        />
      </span>
    </Tooltip>
  );
}

export function ReleaseSelect() {
  const theme = useTheme();
  const classes = useStyles();
  const { releases, selectedRelease, setSelectedRelease } = useControlPanel();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== 'HEAD') {
      setSelectedRelease(
        releases?.find(r => r.metadata.name === event.target.value) || null,
      );
    } else {
      setSelectedRelease(null);
    }
  };

  return (
    <Box className={classes.header}>
      <Box className={classes.buttonsWrapper}>
        <Tooltip title="Select the release that will be used for tests and to display the descriptor. HEAD refers to the code currently found on the main repository branch.">
          <Typography
            component="span"
            variant="body2"
            style={{ fontWeight: 500 }}
          >
            Release:
          </Typography>
        </Tooltip>

        <TextField
          id="select-release"
          select
          size="small"
          value={selectedRelease?.metadata.name || 'HEAD'}
          onChange={handleChange}
          className={classes.select}
        >
          {releases &&
            releases.map(r => (
              <MenuItem key={r.metadata.name} value={r.metadata.name}>
                <strong
                  style={{
                    fontSize: 14,
                    color: r.metadata.isSnapshot
                      ? deepPurple[500]
                      : theme.palette.primary.main,
                  }}
                >
                  {r.metadata.version}
                </strong>
                <Typography
                  variant="caption"
                  component="span"
                  style={{ marginLeft: theme.spacing(1) }}
                >
                  (
                  {format(
                    parseISO(r.metadata.createdAt),
                    'yyyy/MM/dd HH:mm:ss',
                  )}
                  )
                </Typography>
              </MenuItem>
            ))}
          <MenuItem key="HEAD" value="HEAD">
            <Typography component="span" variant="body2" color="primary">
              HEAD
            </Typography>
            <Typography
              variant="caption"
              component="span"
              style={{ marginLeft: theme.spacing(1) }}
            >
              (actual)
            </Typography>
          </MenuItem>
        </TextField>
      </Box>
    </Box>
  );
}

export function TestCard(props: { onCollapse: () => void }) {
  const classes = useStyles();
  const {
    environment,
    entity,
    releases,
    fetchReleaseState,
    selectedRelease,
    fetchPreviewDescriptor,
    fetchPreviewDescriptorState,
  } = useControlPanel();

  useEffect(() => {
    fetchPreviewDescriptor();
  }, [environment, fetchPreviewDescriptor, selectedRelease]);

  if (!releases || !environment) return <></>;

  if (fetchReleaseState.loading) {
    return (
      <Card>
        <CardContent className={classes.cardContent}>
          <CircularProgress size={60} />
        </CardContent>
      </Card>
    );
  }

  return (
    <TestCardProvider
      entity={entity}
      environment={environment}
      release={selectedRelease}
    >
      <WbCard
        title="Test"
        cardStyle={{ height: '100%' }}
        leading={<CollapseButton onClick={props.onCollapse} />}
        actions={
          <Box style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
            <WbEnvironmentSelector />
            <LaunchTestButton
              previewDescriptor={fetchPreviewDescriptorState.value}
              isLoadingDescriptor={fetchPreviewDescriptorState.loading}
            />
          </Box>
        }
      >
        <WbCardContent style={{ height: '100%' }}>
          <Box display="flex" flexDirection="column" height="100%">
            <TestTable />
          </Box>
        </WbCardContent>
      </WbCard>
    </TestCardProvider>
  );
}
