import { Box } from '@material-ui/core';
import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

export const WbCardContent: React.FC<Props> = ({ children, style }) => {
  return (
    <Box style={style} padding="12px 16px">
      {children}
    </Box>
  );
};
