import { Policy, PolicySelector } from '@agilelab/plugin-wb-governance-common';
import {
  GenericField,
  WbTagsTableCell,
  WbCardContent,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import {
  snakeCaseToTitleCase,
  toHumanReadableInteractionType,
} from '@agilelab/plugin-wb-platform-common';
import { StatusField } from '../../../common/Field/StatusField';
import { GovernanceRegistryPoliciesSeverity } from '../../../GovernanceRegistryPage/GovernanceRegistryPolicies/GovernanceRegistryPoliciesSeverity';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(6),
    flexWrap: 'wrap',
  },
}));

function extractSelectorSummary(selectors: PolicySelector[]): string {
  return selectors
    .map((selector: PolicySelector) => {
      const values =
        selector.values.length > 1
          ? `(${selector.values.join(', ')})`
          : selector.values[0];
      return `${selector.path} ${
        selector.values.length > 1 ? 'in' : '='
      } ${values}`;
    })
    .join(' AND ');
}

interface Props {
  policy: Policy;
}
export const GovernanceTestOverviewPolicyInfo: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <>
      <WbWidget title="Info">
        <WbCardContent>
          <Box className={classes.container}>
            <GenericField label="ID" value={props.policy.policyId} />
            <GenericField
              label="Description"
              value={props.policy.description}
              type="text"
            />
            <GenericField label="Version" value={props.policy.version} />
            <GenericField
              label="Resource Preprocessing"
              value={snakeCaseToTitleCase(props.policy.strategy)}
            />
            {props.policy.severity && (
              <GenericField
                label="Severity"
                value={
                  <GovernanceRegistryPoliciesSeverity
                    severity={props.policy.severity}
                    style={{ flexDirection: 'row', gap: 2 }}
                  />
                }
              />
            )}
            <GenericField
              label="Scope"
              value={snakeCaseToTitleCase(props.policy.resourceType)}
            />
            <GenericField
              label="Status"
              value={<StatusField status={props.policy.status} />}
            />
            <GenericField
              label="Context"
              value={snakeCaseToTitleCase(props.policy.context)}
            />
            <GenericField
              label="Timing"
              value={snakeCaseToTitleCase(props.policy.timing)}
            />
            <GenericField
              label="Trigger"
              value={snakeCaseToTitleCase(props.policy.trigger)}
            />
            {!!props.policy.cronExpression && (
              <GenericField
                label="Cron expression"
                value={snakeCaseToTitleCase(props.policy.cronExpression)}
              />
            )}
            <GenericField
              label="Interaction Type"
              value={toHumanReadableInteractionType(
                props.policy.interactionType,
              )}
            />
            {props.policy.externalSpecific?.externalUrl && (
              <GenericField
                href={props.policy.externalSpecific.externalUrl}
                label="Target Url"
                value={props.policy.externalSpecific.externalUrl}
              />
            )}
            {props.policy.selectors && props.policy.selectors.length > 0 && (
              <GenericField
                label="Selectors"
                value={extractSelectorSummary(props.policy.selectors)}
              />
            )}
            {props.policy.tags && props.policy.tags.length > 0 && (
              <GenericField
                label="Tags"
                value={
                  <WbTagsTableCell
                    tags={props.policy.tags.map(t => {
                      return { tagFQN: t };
                    })}
                  />
                }
              />
            )}
            {props.policy.environments &&
              props.policy.environments.length > 0 && (
                <GenericField
                  label="Environment"
                  value={
                    props.policy.environments.map((env: string) =>
                      capitalize(env),
                    )[0]
                  }
                />
              )}
          </Box>
        </WbCardContent>
      </WbWidget>
    </>
  );
};
