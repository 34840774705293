import React from 'react';
import {
  Button,
  CircularProgress,
  Box,
  SvgIconProps,
  ButtonProps,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

type Props = ButtonProps & {
  icon?: React.ReactElement<SvgIconProps>;
  iconColor?: string;
  label: string;
  loading?: boolean;
  showDownArrow?: boolean;
};

export const WbCardActionButtonComponent = (
  {
    loading = false,
    showDownArrow = false,
    icon,
    label,
    onClick,
    color = 'primary',
    iconColor,
    ...props
  }: Props,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <div ref={ref}>
      <Button
        onClick={onClick}
        variant="contained"
        color={color}
        size="small"
        {...props}
      >
        {loading ? (
          <CircularProgress
            size={20}
            thickness={8}
            color="secondary"
            style={{ color: 'white' }}
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {icon &&
              React.cloneElement(icon, {
                htmlColor: iconColor ?? 'inherit',
                fontSize: 'small',
              })}
            <Box style={{ marginLeft: icon ? '4px' : '0px' }}>{label}</Box>
            {showDownArrow && (
              <>
                <div style={{ marginLeft: '4px', fontSize: 'small' }}>|</div>
                <ArrowDropDownIcon style={{ fontSize: 'medium' }} />
              </>
            )}
          </div>
        )}
      </Button>
    </div>
  );
};

export const WbCardActionButton = React.memo(
  React.forwardRef(WbCardActionButtonComponent),
) as typeof WbCardActionButtonComponent;
