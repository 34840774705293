// TODO (manuel): use graphql fragment to avoid redefining the structure of a data product response
export const GET_DATA_PRODUCT_INSTANCE_BY_ID = `
  query GetDataProductById($id: Int!) {
    dataProductsInstances: marketplace_DataProductInstance(
      where: { id: { _eq: $id } }
    ) {
      id
      version
      descriptor
      private_descriptor
      published_at
      DataProduct {
        id
        name
        display_name
        Domain {
          name
          sub_domain_of
        }
        description
        data_product_owner
        data_product_owner_display_name
        external_id
      }
      Environment {
        id
        name
      }
    }
  }
`;

// TODO (manuel): use graphql fragment to avoid redefining the structure of a data product response
export const GET_DATA_PRODUCTS_INSTANCES = `
  query GetDataProductsList(
    $where: marketplace_DataProductInstance_bool_exp
    $offset: Int
    $limit: Int
    $order_by_published_at: marketplace_DataProductInstance_order_by!
    $order_by: marketplace_DataProduct_order_by
    $order_by_env: marketplace_Environment_order_by
  ) {
    count: marketplace_DataProductInstance_aggregate(
      where: $where
    ) {
      aggregate {
        count
      }
    }
    dataProductsInstances: marketplace_DataProductInstance(
      limit: $limit
      offset: $offset
      order_by: [ $order_by_published_at, {DataProduct: $order_by}, {Environment: $order_by_env}]
      where: $where
    ) {
      id
      version
      descriptor
      private_descriptor
      published_at
      DataProduct {
        name
        display_name
        Domain {
          name
          sub_domain_of
        }
        description
        data_product_owner
        data_product_owner_display_name
        external_id
      }
      Environment {
        id
        name
      }
    }
  }
`;
