import { Box, Chip, useTheme } from '@material-ui/core';
import React from 'react';
import { TableContentModal } from '../modals/TableContentModal';

interface MultipleValueCellProps<T> {
  values: Array<T>;
  modalTitle: string;
  onSearchValueChange: (value: string) => void;
}

export function MultipleValueCell<T>({
  values,
  modalTitle,
  onSearchValueChange,
}: MultipleValueCellProps<T>) {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    onSearchValueChange(value);
  };

  if (values.length > 3) {
    return (
      <Box style={{ display: 'flex', gap: theme.spacing(1), flexWrap: 'wrap' }}>
        {values.slice(0, 3)}
        <Chip
          label={`...other ${values.length - 3}`}
          size="small"
          color="primary"
          onClick={handleClick}
        />
        <TableContentModal
          maxWidth="md"
          title={modalTitle}
          open={open}
          onClose={handleClose}
          searchValue={searchValue}
          onSearchValueChange={handleSearch}
          values={values}
        />
      </Box>
    );
  }
  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}>
      {values}
    </Box>
  );
}
