import { Entity, EntityMeta } from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/AccessRequestTemplate.v1alpha1.schema.json';

export const AccessRequestTemplateFileName = 'access-request-template.yaml';

declare interface AccessRequestTemplateEntity extends Entity {
  apiVersion: 'witboost.com/v1';
  kind: 'AccessRequestTemplate';
  metadata: EntityMeta;
  spec: {
    owner?: string;
    /**
     * JSONSchemas which is used to render a form in the frontend
     * to collect user input and validate it against that schema.
     */
    parameters?: JsonObject[];
  };
}

export type { AccessRequestTemplateEntity as AccessRequestTemplate };

export const accessRequestTemplateEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
