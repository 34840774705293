import {
  TextField,
  InputAdornment,
  TextFieldProps,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';

export const Search = ({
  onChange,
  value,
  debounced,
  ...props
}: Omit<TextFieldProps, 'onChange'> & {
  debounced?: boolean;
  onChange?: (value: string) => void;
}) => {
  const [search, setSearch] = useState(value);

  // Workaround to clear input when user clear all filters
  useEffect(() => {
    if (value === '' || value === undefined) setSearch('');
  }, [value]);

  const handleDebounceFn = React.useCallback(
    (v: string) => {
      onChange?.(v);
    },
    [onChange],
  );

  const debounceFn = React.useMemo(
    () => debounce(handleDebounceFn, 500),
    [handleDebounceFn],
  );

  const handleChangeSearch = (e: any) => {
    const newValue = e.currentTarget.value;
    setSearch(newValue);
    if (debounced) debounceFn(newValue);
    else handleDebounceFn(newValue);
  };

  const handleClearSearch = () => {
    onChange?.('');
    setSearch('');
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label=""
      value={search}
      onChange={handleChangeSearch}
      placeholder="Search"
      InputProps={{
        style: { fontSize: '14px', height: '45px' },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              onClick={handleClearSearch}
              disabled={!search}
              size="small"
              style={{
                visibility: search ? 'visible' : 'hidden',
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>

            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
