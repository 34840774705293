/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import {
  EntityAboutCard,
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  hasCatalogProcessingErrors,
  isOrphan,
  ReservedFieldsError,
  ControlPanelPage,
  hasReservedFieldsError,
  ComponentTypeSelectButton,
} from '@agilelab/plugin-wb-builder-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  ComponentEntity,
  CompoundEntityRef,
  Entity,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';
import {
  InfoCard,
  Progress,
  TableColumn,
  ResponseErrorPanel,
  Link,
  Table,
} from '@backstage/core-components';
import {
  useEntity,
  useRelatedEntities,
  EntityRefLink,
  getEntityRelations,
  EntityRefLinks,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';

import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  WitboostSystem,
  applyWitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import pluralize from 'pluralize';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasReservedFieldsError}>
        <Grid item xs={12}>
          <ReservedFieldsError />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const createNameColumn = (options?: {
  defaultKind?: string;
}): TableColumn<WitboostSystem> => {
  return {
    title: 'Name',
    field: 'e.metadata.name',
    highlight: true,
    render: e => {
      return (
        <EntityRefLink
          entityRef={e}
          defaultKind={options?.defaultKind || 'component'}
          title={e.spec?.mesh?.name || e.metadata.title}
        />
      );
    },
    width: '30%',
  };
};

const createOwnerColumn = (): TableColumn<WitboostSystem> => {
  function getRelations(entity: Entity | undefined): CompoundEntityRef[] {
    return getEntityRelations(entity, RELATION_OWNED_BY);
  }

  function formatContent(entity: Entity | undefined): string {
    return getRelations(entity)
      .map(r => humanizeEntityRef(r))
      .join(', ');
  }

  return {
    title: 'Owner',
    customFilterAndSearch(filter, entity) {
      return formatContent(entity).includes(filter);
    },
    customSort(entity1, entity2) {
      return formatContent(entity1).localeCompare(formatContent(entity2));
    },
    render: e => (
      <EntityRefLinks
        entityRefs={getEntityRelations(e, RELATION_OWNED_BY)}
        defaultKind="group"
        children={
          // We wrapped the link inside a paragraph
          // only to be able to enable the ellipsis behaviour
          <Tooltip title={e.spec.owner || ''}>
            <p
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {e.spec.owner}
            </p>
          </Tooltip>
        }
      />
    ),
    width: '20%',
  };
};

const createDescColumn = (): TableColumn<WitboostSystem> => {
  return {
    title: 'Description',
    field: 'e.metadata.description',
    render: e => (
      <Tooltip title={e.metadata.description || ''}>
        <Typography
          variant="body2"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {e.metadata.description}
        </Typography>
      </Tooltip>
    ),
    width: '50%',
  };
};

export const componentEntityColumns: TableColumn[] = [
  createNameColumn({
    defaultKind: 'component',
  }) as unknown as TableColumn,
  createOwnerColumn() as unknown as TableColumn,
  createDescColumn() as unknown as TableColumn,
];

const componentTitle = (componentType: string) => {
  switch (componentType.toLowerCase()) {
    case 'outputport': {
      return 'Output Ports';
    }
    case 'workload': {
      return 'Workloads';
    }
    case 'storage': {
      return 'Storages';
    }
    case 'observability': {
      return 'Observability';
    }
    case 'schema': {
      return 'Schema';
    }
    default: {
      return pluralize(componentType);
    }
  }
};

type Props = {
  variant?: 'gridItem';
  title: string;
};

export function DataProductPage({ variant = 'gridItem', title }: Props) {
  const { entity } = useEntity();
  const theme = useTheme();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_HAS_PART,
    kind: 'Component',
  });

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title={title}>
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  const components = entities as ComponentEntity[];
  const componentsByType = components.reduce((acc, component) => {
    if (!acc[component.spec.type]) {
      acc[component.spec.type] = [];
    }
    acc[component.spec.type].push(component);
    return acc;
  }, {} as Record<string, ComponentEntity[]>);

  // For each component type we crate an independent table.
  // The table code is a replica of plugin-catalog/RelatedEntitiesCard.
  // We don't use RelatedEntitiesCard directly because here we load all entities just once
  // instead of doing it once for each component type.
  const componentChildren = Object.keys(componentsByType).map(componentType => {
    return (
      // TODO: try to use WbWidget here
      <Table
        style={{
          marginTop: 20,
          width: '100%',
        }}
        options={{
          search: false,
          paging: false,
          padding: 'dense',
        }}
        title={componentTitle(componentType)}
        emptyContent={
          <div style={{ textAlign: 'center' }}>
            <Typography variant="body1">{`No ${componentType} is part of this system`}</Typography>
            <Typography variant="body2">
              <Link to="https://backstage.io/docs/features/software-catalog/descriptor-format#kind-component">
                Learn how to change this.
              </Link>
            </Typography>
          </div>
        }
        columns={componentEntityColumns}
        data={componentsByType[componentType] || []}
      />
    );
  });

  return (
    <EntityLayoutWrapper isWrappingDataProduct>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {entityWarningContent}
          <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={12} xs={12}>
            {/* If no components to display in the table */}
            {componentChildren.length !== 0 ? (
              <WbCard
                title="Components"
                actions={
                  <ComponentTypeSelectButton
                    dataproduct={entity.metadata.name}
                    domain={entity.spec?.domain as string}
                  />
                }
              >
                <WbCardContent>{componentChildren}</WbCardContent>
              </WbCard>
            ) : (
              <WbCard
                title="Components"
                actions={
                  <ComponentTypeSelectButton
                    dataproduct={entity.metadata.name}
                    domain={entity.spec?.domain as string}
                  />
                }
              >
                <div style={{ textAlign: 'center' }}>
                  <Typography
                    variant="body1"
                    style={{
                      padding: theme.spacing(3),
                    }}
                  >
                    There are no components available. You can create one by
                    clicking on the "Add" button.
                  </Typography>
                </div>
              </WbCard>
            )}
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs">
        <EntityTechdocsContent />
      </EntityLayout.Route>
      <EntityLayout.Route path="/deploy" title="Control Panel">
        <ControlPanelPage entity={applyWitboostVersionedEntity(entity)} />
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
}
