import { Box, TextFieldProps } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { WbTextField } from './WbTextField';
import { ClearButton } from './ClearButton';
import { SelectIcon } from './SelectIcon';
import { WbChip } from './WbChip';
import ClearIcon from '@material-ui/icons/Clear';
import { WbCheckboxMenuItem } from './WbCheckboxMenuItem';

type MultiSelectConditionalProps =
  | {
      options: string[];
      getOptionLabel?: (value: string) => string;
      children?: never;
    }
  | {
      options?: never;
      getOptionLabel?: never;
      children: React.ReactNode;
    };

type MultiSelectSharedProps = {
  value: string[];
  onChange: (event: React.ChangeEvent<{ value: string[] }>) => void;
};

type Props = Omit<TextFieldProps, 'select' | 'onChange' | 'value'> &
  MultiSelectSharedProps &
  MultiSelectConditionalProps & { clearable?: boolean };

export const WbMultiSelect = React.forwardRef(
  ({ clearable, getOptionLabel, ...props }: Props, ref?: React.Ref<any>) => {
    const [internalValue, setInternalValue] = useState(props.value || []);
    const inputRef = useRef<HTMLInputElement | null>(null);

    return (
      <WbTextField
        {...props}
        ref={ref}
        value={internalValue}
        onChange={e => {
          const event = e as unknown as React.ChangeEvent<{ value: string[] }>;
          const value = event.target.value;
          setInternalValue(value);
          props.onChange(event);
        }}
        select
        SelectProps={{
          inputRef: inputRef,
          IconComponent: SelectIcon,
          multiple: true,
          renderValue: selected => (
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              {(selected as string[]).map(value => (
                <WbChip
                  key={value}
                  label={getOptionLabel?.(value) || value}
                  onDelete={e => {
                    const event = e as unknown as React.ChangeEvent<{
                      value: string[];
                    }>;
                    const newValue = internalValue.filter(v => v !== value);
                    setInternalValue(newValue);
                    event.target.value = newValue;
                    props.onChange(event);
                    inputRef?.current?.focus();
                  }}
                  deleteIcon={
                    <ClearIcon onMouseDown={event => event.stopPropagation()} />
                  }
                />
              ))}
            </Box>
          ),
          ...props.SelectProps,
        }}
        InputProps={{
          endAdornment: clearable && !props.disabled && (
            <ClearButton
              style={{
                marginRight: '25px',
              }}
              onClear={e => {
                const event = e as unknown as React.ChangeEvent<{
                  value: string[];
                }>;
                setInternalValue([]);
                event.target.value = [];
                if (props.onChange) props.onChange(event);
              }}
            />
          ),
        }}
      >
        {props?.options?.map((option, i) => (
          <WbCheckboxMenuItem
            key={i}
            value={option}
            checked={props.value.includes(option)}
            label={getOptionLabel ? getOptionLabel(option) : option}
          />
        ))}
        {props?.children}
      </WbTextField>
    );
  },
);
