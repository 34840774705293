export type Question = {
  asked_by: string;
  question: string;
  dataproduct: {
    id: number;
    name: string;
    display_name?: string;
    domain: string;
    version: string;
    environment?: string;
  };
  asked_by_display_name: string;
  id_dataproduct_instance: number;
};

export type Informative = {
  json: string;
  html: string;
  text: string;
  timestamp?: string;
};

export type Answer = {
  answered_by: string;
  answered_by_display_name: string;
  answer: string;
  timestamp?: string;
};

export type StatusData = {
  timestamp: string;
  value: number;
};

export type AccessRequest = {
  identities: string[];
  requester: string;
  motivation: string;
  outputport: {
    name: string;
    output_port_type: string;
  };
  dataproduct: {
    id: number;
    name: string;
    display_name?: string;
    domain: string;
    version: string;
    data_product_owner: string;
    data_product_display_name: string;
  };
  environment: string;
  id_output_port: number;
  requester_display_name: string;
  id_dataproduct_instance: number;
};

export type AccessResponse = AccessRequest & {
  is_requester: boolean;
  status: 'SUCCESS' | 'FAILURE';
};

export enum AccessAuthorizationStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export type AccessAuthorization = {
  status: AccessAuthorizationStatus;
  timestamp: string;
  authorized_by: string;
  authorized_by_display_name: string;
};

export enum NotificationType {
  QUESTION = 'Question',
  ACCESS_REQUEST = 'AccessRequest',
  INFORMATIVE = 'Informative',
  ACCESS_RESPONSE = 'AccessResponse',
}

export type Notification = {
  id: number;
  kind: NotificationType;
  notification_request: AccessRequest | Question | Informative;
  notification_response: Answer | AccessAuthorization | null;
  recipient: string;
  created_at: string;
  updated_at: string;
  read_at: string | null;
  hook_id?: string | null;
  sender: string | null;
};

export type AccessRequestNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.ACCESS_REQUEST;
  notification_request: AccessRequest;
  notification_response: AccessAuthorization | null;
};

export type AccessResponseNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.ACCESS_RESPONSE;
  notification_request: AccessResponse;
  notification_response: AccessAuthorization | null;
};

export type QuestionNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.QUESTION;
  notification_request: Question;
  notification_response: Answer | null;
};

export type InformativeNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.INFORMATIVE;
  notification_request: Informative;
  notification_response: null;
};

interface NotificationEventObject {
  [key: string]: { eventName: string; subject: string; text: string };
}

export const NotificationEvent: NotificationEventObject = {
  NewVersion: {
    eventName: 'NewVersion',
    subject: 'New data product version available',
    text: 'A new version of the data product {{displayName}} is available.',
  },
  NewSystem: {
    eventName: 'NewSystem',
    subject: 'New data product registered',
    text: 'A new data product {{displayName}} has been registered.',
  },
  NewTemplate: {
    eventName: 'NewTemplate',
    subject: 'New template registered',
    text: 'A new template {{displayName}} has been registered.',
  },
  NewDomain: {
    eventName: 'NewDomain',
    subject: 'New domain registered',
    text: 'A new domain {{displayName}} has been registered.',
  },
  NewPolicy: {
    eventName: 'NewPolicy',
    subject: 'New policy registered',
    text: 'A new policy {{displayName}} has been registered.',
  },
  NewMetric: {
    eventName: 'NewMetric',
    subject: 'New metric registered',
    text: 'A new metric {{displayName}} has been registered.',
  },
  ScheduledPolicyFail: {
    eventName: 'ScheduledPolicyFail',
    subject: 'Scheduled policy failed',
    text: 'Scheduled policy {{displayName}} has failed.',
  },
  ScheduledMetricFail: {
    eventName: 'ScheduledMetricFail',
    subject: 'Scheduled metric failed',
    text: 'Scheduled metric {{displayName}} has failed.',
  },
};
