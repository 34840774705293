import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import './styles/styles.css';

import {
  AutoLogoutProvider,
  ldapAuthApiRef,
  SignInPage,
} from '@agilelab/plugin-wb-auth';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  MyDataProductsPage,
} from '@agilelab/plugin-wb-builder-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@agilelab/plugin-wb-catalog-import';
import { PackageListPage } from '@agilelab/plugin-wb-external-resources';
import { GovernancePage } from '@agilelab/plugin-wb-governance';
import { MarketplacePage } from '@agilelab/plugin-wb-marketplace';
import {
  NotificationPage,
  NotificationProvider,
} from '@agilelab/plugin-wb-notification';
import {
  CustomAlertDisplay,
  MeshApolloProvider,
  SelectorsContextProvider,
} from '@agilelab/plugin-wb-platform';
import { PlatformSettingsPage } from '@agilelab/plugin-wb-platform-settings';
import {
  HorizontalTemplateLayout,
  ScaffolderLayouts,
  ScaffolderPage,
  scaffolderPlugin,
} from '@agilelab/plugin-wb-scaffolder';
import {
  UserSettingsPage,
  UserSettingsTab,
} from '@agilelab/plugin-wb-user-settings';
import { createApp } from '@backstage/app-defaults';
import {
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { FlatRoutes } from '@backstage/core-app-api';
import { OAuthRequestDialog } from '@backstage/core-components';
import {
  discoveryApiRef,
  IdentityApi,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Navigate, Route } from 'react-router';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import { UserConfigSettings } from './components/settings/UserConfigSettings';
import { UserHeadersSettings } from './components/settings/UserHeadersSettings/UserHeadersSettings';
import { LightAgileTheme } from './components/themes/LightAgileTheme';
import useCustomTheme from './components/themes/useCustomTheme';
import { setTokenCookie } from './cookieAuth';
import * as plugins from './plugins';
import { TableRowTemplateLayout } from '@agilelab/plugin-wb-scaffolder';
import { ArrayTableTemplateLayout } from '@agilelab/plugin-wb-scaffolder';

const queryClient = new QueryClient();

const SignInComponent: any = (props: any) => {
  const providers = [
    {
      id: 'microsoft-auth-provider',
      title: 'Microsoft',
      message: 'Sign In using Microsoft Azure AD',
      apiRef: microsoftAuthApiRef,
    },
    {
      id: 'simple_ldap',
      title: 'LDAP',
      message: 'Sign In using LDAP',
      apiRef: ldapAuthApiRef,
    },
  ];
  const discoveryApi = useApi(discoveryApiRef);
  return (
    <SignInPage
      {...props}
      providers={[...providers]}
      title="Select a sign-in method"
      align="center"
      onSignInSuccess={async (identityApi: IdentityApi) => {
        setTokenCookie(await discoveryApi.getBaseUrl('cookie'), identityApi);

        props.onSignInSuccess(identityApi);
      }}
    />
  );
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog/my-data-products" />} />
    <Route
      path="/catalog"
      element={<CatalogIndexPage hiddenKinds={['release', 'location']} />}
    />
    <Route path="/catalog/my-data-products" element={<MyDataProductsPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'group'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      <UserSettingsTab path="/advanced" title="Advanced">
        <UserConfigSettings />
        <UserHeadersSettings />
      </UserSettingsTab>
    </Route>
    <Route path="/marketplace" element={<MarketplacePage />} />
    <Route path="/wb-scaffolder" element={<ScaffolderPage />}>
      <ScaffolderLayouts>
        <TableRowTemplateLayout />
        <ArrayTableTemplateLayout />
        <HorizontalTemplateLayout />
      </ScaffolderLayouts>
    </Route>
    <Route path="/governance" element={<GovernancePage />} />
    <Route path="/external-resources" element={<PackageListPage />} />
    <Route path="/notifications" element={<NotificationPage />} />
    <Route path="/platform-settings" element={<PlatformSettingsPage />} />
  </FlatRoutes>
);

const App = () => {
  const { error, loading, theme } = useCustomTheme();
  const themes = useMemo(() => {
    if (theme && !loading && !error) return [theme];
    return [LightAgileTheme];
  }, [error, loading, theme]);

  if (loading) return null;

  const app = createApp({
    apis,
    plugins: Object.values(plugins),
    themes: themes,
    components: {
      SignInPage: SignInComponent,
    },
    bindRoutes({ bind }) {
      bind(catalogPlugin.externalRoutes, {
        createComponent: scaffolderPlugin.routes.root,
        viewTechDoc: techdocsPlugin.routes.docRoot,
      });
      bind(catalogGraphPlugin.externalRoutes, {
        catalogEntity: catalogPlugin.routes.catalogEntity,
      });
      bind(apiDocsPlugin.externalRoutes, {
        registerApi: catalogImportPlugin.routes.importPage,
      });
      bind(scaffolderPlugin.externalRoutes, {
        registerComponent: catalogImportPlugin.routes.importPage,
      });
      bind(orgPlugin.externalRoutes, {
        catalogIndex: catalogPlugin.routes.catalogIndex,
      });
    },
  });

  const AppProvider = app.getProvider();
  const AppRouter = app.getRouter();

  return (
    <AppProvider>
      <CustomAlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <MeshApolloProvider>
          <QueryClientProvider client={queryClient}>
            <NotificationProvider>
              <AutoLogoutProvider>
                <SelectorsContextProvider>
                  <Root>{routes}</Root>
                </SelectorsContextProvider>
              </AutoLogoutProvider>
            </NotificationProvider>
          </QueryClientProvider>
        </MeshApolloProvider>
      </AppRouter>
    </AppProvider>
  );
};

export default App;
