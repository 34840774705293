import React from 'react';
import {
  makeStyles,
  createStyles,
  Chip,
  Tooltip,
  Typography,
  Theme,
} from '@material-ui/core';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';
import { Task } from '../../types';
import { ThresholdResult } from '@agilelab/plugin-wb-platform';

interface Props {
  metric: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
  task: Task;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailName: {
      width: '150px',
      minWidth: '150px',
      marginLeft: theme.spacing(2),
    },
    policyDesc: {
      flex: 1,
    },
    version: {
      padding: theme.spacing(0, 2),
    },
    chip: {
      margin: 0,
    },
    truncated: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      display: '-webkit-box',
      marginRight: theme.spacing(1),
      wordBreak: 'break-all',
    },
  }),
);

export const MetricComponentItem: React.FC<Props> = ({ metric, task }) => {
  const classes = useStyles();

  return (
    <>
      <ThresholdResult
        value={task.metricSpecificResult?.value || 0}
        result={task.metricSpecificResult?.threshold}
        hasError={task.errors.length > 0}
        dimension={31.2}
      />
      <div className={classes.detailName}>
        <Tooltip title={metric.name}>
          <Typography variant="body2" className={classes.truncated}>
            <strong>{metric.name}</strong>
          </Typography>
        </Tooltip>
      </div>
      <div className={classes.version}>
        <Tooltip title={`version: ${metric.version}`}>
          <Typography variant="body2">{`v:${metric.version}`}</Typography>
        </Tooltip>
      </div>
      <div className={classes.policyDesc}>
        <Tooltip title={metric.description ?? ''}>
          <Typography variant="body2" className={classes.truncated}>
            {metric?.description}
          </Typography>
        </Tooltip>
      </div>
      <Chip label="Metric" className={classes.chip} color="primary" />
    </>
  );
};
