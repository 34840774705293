import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { KoFilledIcon, OkFilledIcon } from './Icons';

export const DetailIcon: React.FC<{ hasError: boolean }> = ({ hasError }) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      detailIcon: {
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(2),
      },
      icon: {
        width: '1.3em',
        height: '1.3em',
      },
    }),
  );

  const classes = useStyles();
  return (
    <div className={classes.detailIcon}>
      {hasError ? (
        <KoFilledIcon className={classes.icon} />
      ) : (
        <OkFilledIcon className={classes.icon} />
      )}
    </div>
  );
};
