/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Content,
  ContentHeader,
  Header,
  Page,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid, StepLabel } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ImportInfoCard } from '../ImportInfoCard';
import {
  ImportStepper,
  StepperProvider,
  defaultStepper,
} from '../ImportStepper';
import { StepInitAnalyzeUrl } from '../StepInitAnalyzeUrl';

/**
 * The default catalog import page.
 *
 * @public
 */
export const DefaultImportPage = () => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptional('app.title') || 'Backstage';
  const ontologyKinds = configApi.getOptionalStringArray('ontology.kinds');
  const defaultCatalogRules = configApi.get('catalog.rules') as
    | [{ allow: string[] }];

  const allowedLocationKinds = useMemo(() => {
    const excludedKinds = [
      'blueprint',
      'accessrequesttemplate',
      ...(ontologyKinds ?? []),
    ];
    return defaultCatalogRules
      .flatMap(v => v.allow)
      .filter(k => k && !excludedKinds.includes(k.toLowerCase()));
  }, [defaultCatalogRules, ontologyKinds]);

  const stepper: StepperProvider = useMemo(
    () => ({
      ...defaultStepper,
      analyze: (state, { apis }) => ({
        stepLabel: <StepLabel>Select URL</StepLabel>,
        content: (
          <StepInitAnalyzeUrl
            key="analyze"
            analysisUrl={state.analysisUrl}
            onAnalysis={state.onAnalysis}
            disablePullRequest={!apis.catalogImportApi.preparePullRequest}
            locationKindsToImport={allowedLocationKinds}
          />
        ),
      }),
    }),
    [allowedLocationKinds],
  );

  return (
    <Page themeId="home">
      <Header title="Register an existing component" />
      <Content>
        <ContentHeader title={`Start tracking your component in ${appTitle}`} />

        <Grid container spacing={2} direction="row-reverse">
          <Grid item xs={12} md={4} lg={6} xl={8}>
            <ImportInfoCard />
          </Grid>

          <Grid item xs={12} md={8} lg={6} xl={4}>
            <ImportStepper defaultStepper={stepper} />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
