import React from 'react';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import { WbTextField } from '@agilelab/plugin-wb-platform';

export const RegexPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: { title = 'RegExPicker', description = 'RegEx picker component' },
    required,
    rawErrors,
    formData,
    idSchema,
    placeholder,
  } = props;

  return (
    <WbTextField
      id={idSchema?.$id}
      label={title}
      placeholder={placeholder}
      helperText={description}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      error={rawErrors?.length > 0 && !formData}
    />
  );
};
