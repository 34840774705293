import { useRouteRef } from '@backstage/core-plugin-api';
import {
  useTheme,
  Box,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { specificResultRouteRef } from '../../../routes';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { ThresholdResult } from '@agilelab/plugin-wb-platform';
import { ThresholdResult as Result } from '@agilelab/plugin-wb-governance-common';

const useStyles = makeStyles(() => ({
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export interface MetricItemProps {
  id: number;
  name: string;
  result: Result;
  value: number;
  description?: string;
  version: number;
  error?: boolean;
  resource?: string;
}

export const MetricItem: React.FC<MetricItemProps> = ({
  id,
  name,
  version,
  value,
  description,
  error,
  result,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const specificResultRoute = useRouteRef(specificResultRouteRef);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      <ThresholdResult value={value} result={result} hasError={error} />
      <Link to={specificResultRoute({ resultId: id.toString() })}>
        <Typography
          component="p"
          variant="body2"
          className={classes.truncated}
          style={{ maxWidth: '150px' }}
        >
          {`${name} v:${version}`}
        </Typography>
      </Link>
      {!!description && (
        <Tooltip title={description}>
          <InfoIcon />
        </Tooltip>
      )}
    </Box>
  );
};
