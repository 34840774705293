import React from 'react';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import {
  PanelResizeHandle,
  PanelResizeHandleProps,
} from 'react-resizable-panels';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  handleOuter: {
    background: theme.palette.background.paper,
    '&[data-resize-handle-active]': {
      background: theme.palette.primary.main,
    },
    transition: 'all 0.2s linear',
  },
  handleHorizontal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 16,
    flexDirection: 'column',
  },
  handleVertical: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    height: 16,
  },
}));

type WbDockableHandleDirection = 'horizontal' | 'vertical';

const WbDockableResizeHandleVertical: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.handleVertical}>
      <ArrowUp color="primary" style={{ marginTop: 2 }} />
      <ArrowDown color="primary" style={{ marginLeft: -15 }} />
    </Box>
  );
};

const WbDockableResizeHandleHorizontal: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.handleHorizontal}>
      <ArrowLeft color="primary" />
      <ArrowRight color="primary" style={{ marginTop: -15 }} />
    </Box>
  );
};

const handleByDirection: Record<WbDockableHandleDirection, JSX.Element> = {
  vertical: <WbDockableResizeHandleVertical />,
  horizontal: <WbDockableResizeHandleHorizontal />,
};

export const WbDockableResizeHandle: React.FC<
  PanelResizeHandleProps & {
    direction: WbDockableHandleDirection;
    widget?: boolean;
  }
> = props => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <PanelResizeHandle
      style={{
        background: props.widget ? theme.palette.background.default : '',
      }}
      {...props}
      className={classes.handleOuter}
    >
      {handleByDirection[props.direction]}
    </PanelResizeHandle>
  );
};
