import React from 'react';
import { CustomUserListPicker } from './CustomUserListPicker';
import { CustomEntityTagPicker } from './CustomEntityTagPicker';
import { CustomEntityTypePicker } from './CustomEntityTypePicker';
import { CustomEntityProcessingStatusPicker } from './CustomEntityProcessingStatusPicker';
import { CustomEntityOwnerPicker } from './CustomEntityOwnerPicker';
import { WbTableFilters } from '@agilelab/plugin-wb-platform';
import {
  EntityTextFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';

interface Props {
  isSoftwareCatalog: boolean;
}

export class EntitySearchTextFilter extends EntityTextFilter {
  filterEntity(entity: Entity): boolean {
    const words = this.toUpperCaseArray(this.value.split(/\s/));
    const exactMatch = this.toUpperCaseArray([
      entity.metadata.tags ?? [],
      this.extractTags(entity as WitboostEntity),
    ]);
    const partialMatch = this.toUpperCaseArray([
      entity.metadata.name,
      entity.metadata.description,
    ]);

    for (const word of words) {
      if (
        exactMatch.every(m => m !== word) &&
        partialMatch.every(m => !m.includes(word))
      ) {
        return false;
      }
    }

    return true;
  }

  private extractTags(entity: WitboostEntity): Array<string> {
    const tags = entity.spec?.mesh?.tags ?? [];
    if (Array.isArray(tags)) {
      return tags.flatMap((t: any) => {
        if (t.hasOwnProperty('tagFQN')) {
          return [t.tagFQN];
        }
        return [];
      });
    }
    return [];
  }

  private toUpperCaseArray(
    value: Array<string | string[] | undefined>,
  ): Array<string> {
    return value.flat().flatMap(m => {
      if (m && typeof m === 'string') {
        return [m.toLocaleUpperCase('en-US')];
      }
      return [];
    });
  }
}

export const CatalogTableFilters: React.FC<Props> = ({ isSoftwareCatalog }) => {
  const { filters, updateFilters } = useEntityList();
  return (
    <WbTableFilters
      searchValue={filters.text?.value}
      onSearch={value => {
        updateFilters({ text: new EntitySearchTextFilter(value) });
      }}
    >
      {isSoftwareCatalog ? (
        <>
          <CustomUserListPicker />
          <CustomEntityTagPicker />
          <CustomEntityTypePicker />
          <CustomEntityProcessingStatusPicker />
        </>
      ) : (
        <>
          <CustomUserListPicker />
          <CustomEntityTypePicker />
          <CustomEntityOwnerPicker />
        </>
      )}
    </WbTableFilters>
  );
};
