import React from 'react';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { Box } from '@material-ui/core';

interface WarningMessageProps {
  message: string;
}

export const WarningMessage: React.FC<WarningMessageProps> = ({ message }) => {
  return (
    <Box
      style={{
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f8f8',
      }}
    >
      <WarningIcon style={{ color: '#ffbf00', marginRight: '10px' }} />
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
        {message}
      </Typography>
    </Box>
  );
};
