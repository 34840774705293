import React from 'react';
import { Box, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { WbTabProps } from './WbTab';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '12px 16px',
  },
  container: {
    display: 'flex',
    borderRadius: 24,
    background: theme.palette.background3,
  },
  tab: {
    padding: '5px 12.5px',
    position: 'relative',
    fontWeight: 500,
    cursor: 'pointer',
  },
  divider: {
    '&::after': {
      content: '""',
      height: '50%',
      width: 2,
      background: '#C2D5D8', // TODO - Aggiungere nel tema
      display: 'inline-block',
      marginLeft: '11.5px',
      position: 'absolute',
      top: '25%',
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

interface WbTabsProps extends WbTabProps {
  selected: boolean;
  action: () => void;
}

interface Props {
  tabs: Array<WbTabsProps>;
  containerClassName?: string;
  selectedClassName?: string;
}

export const WbTabs: React.FC<Props> = ({
  tabs,
  containerClassName,
  selectedClassName,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Box className={clsx(classes.container, containerClassName)}>
        {tabs.map(({ name, action, selected }, index) => {
          const hasDivider = index >= 0 && index < tabs.length - 1;
          return (
            <Box
              className={clsx(
                classes.tab,
                hasDivider && classes.divider,
                selected && classes.selected,
                selected && selectedClassName,
              )}
              onClick={action}
              key={index}
            >
              {name}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
