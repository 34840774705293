import {
  WbAutocomplete,
  customAlertApiRef,
  useFetchUserInfo,
} from '@agilelab/plugin-wb-platform';
import { Entity, EntityRelation } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { FormControl } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { deserializeEntityRef } from '../../../api';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import { usePrevious } from '../../hooks/useEventStream';
import {
  generateURNByKind,
  getEntityDisplayName,
  transformUrnToWitboostId,
} from '@agilelab/plugin-wb-builder-common';

export async function fetchEntityComponents(
  formContext: any,
  fieldName: string,
  catalogApi: CatalogApi,
  componentsFilter: string | undefined,
  token: string,
): Promise<Entity[] | undefined> {
  const fieldValue = formContext[fieldName];
  const entityRef = fieldValue ? deserializeEntityRef(fieldValue) : undefined;

  if (entityRef) {
    const entity = await catalogApi.getEntityByRef(entityRef, { token });

    if (entity) {
      const relationsRefs =
        entity.relations
          ?.filter((relation: EntityRelation) => relation.type === 'hasPart')
          .map((relation: EntityRelation) => relation.targetRef) ?? [];

      const componentsPromises: Promise<Entity | undefined>[] =
        relationsRefs?.map(
          async targetRef =>
            await catalogApi.getEntityByRef(targetRef, { token }),
        );
      const relatedComponents: (Entity | undefined)[] = await Promise.all(
        componentsPromises,
      );

      const components: Entity[] = componentsFilter
        ? relatedComponents.filter(
            (ent): ent is Entity =>
              !!ent && ent.spec?.type === componentsFilter,
          )
        : relatedComponents.filter((ent): ent is Entity => !!ent);

      return components;
    }
  }

  return undefined;
}

export const EntityComponentsPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: {
      title = 'Component',
      description = 'A component from a specific data product',
    },
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
    formContext,
  } = props;
  const fieldName = uiSchema['ui:fieldName'] as string | undefined;
  const componentsFilter = uiSchema['ui:componentsFilter'] as
    | string
    | undefined;
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const { token } = useFetchUserInfo();
  const prevFormContext = usePrevious(formContext);
  const [value, setValue] = useState<Entity | null>(null);

  useEffect(() => {
    async function setInitialValue() {
      const formDataEntityRef = formData
        ? transformUrnToWitboostId(formData)
        : null;
      const formDataEntity: Entity | null = formDataEntityRef
        ? (await catalogApi.getEntityByRef(formDataEntityRef)) ?? null
        : null;
      setValue(formDataEntity);
    }

    setInitialValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { value: components, loading } = useAsync(async () => {
    if (!fieldName) {
      alertApi.post({
        message: `No 'ui:field' parameter found`,
        severity: 'error',
      });

      return undefined;
    }

    // check if the value of the dependent field name has changed
    const fieldChanged =
      prevFormContext && formContext[fieldName] !== prevFormContext[fieldName];

    if (fieldChanged) {
      setValue(null);
    }

    const fetchedComponents = await fetchEntityComponents(
      formContext,
      fieldName,
      catalogApi,
      componentsFilter,
      token,
    );

    return fetchedComponents;
  }, [formContext]);

  const onSelect = useCallback(
    (_: any, val: Entity | string | null) => {
      if (typeof val !== 'string') {
        const ref = val ? generateURNByKind(val.metadata.name, val.kind) : null;
        setValue(val);
        onChange(ref);
      }
    },
    [onChange],
  );

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <WbAutocomplete
        id={idSchema?.$id}
        value={value || null}
        loading={loading}
        onChange={onSelect}
        autoSelect
        options={components || []}
        freeSolo={false}
        getOptionLabel={(option: Entity) => getEntityDisplayName(option)}
        getOptionSelected={(option: Entity, val: Entity) =>
          generateURNByKind(option.metadata.name, option.kind) ===
          generateURNByKind(val.metadata.name, val.kind)
        }
        label={title}
        helperText={description}
        required={required}
      />
    </FormControl>
  );
};
