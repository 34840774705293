import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { ActionHandlerClient, actionHandlerApiRef } from './ActionHandlerApi';

import { AccessControlListClient } from '@agilelab/plugin-wb-marketplace-common';
import { accessControlListApiRef } from './AccessControlListApiRef';
import { rootRouteRef } from './routes';

export const marketplacePlugin = createPlugin({
  id: 'wb-marketplace',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: actionHandlerApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi }) => new ActionHandlerClient({ discoveryApi }),
    }),
    createApiFactory({
      api: accessControlListApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi }) =>
        new AccessControlListClient({ discoveryApi }),
    }),
  ],
});

export const MarketplacePage = marketplacePlugin.provide(
  createRoutableExtension({
    name: 'MarketplacePage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
