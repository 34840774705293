import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React from 'react';
import { MetricItem, MetricItemProps } from './MetricItem';

const useStyles = makeStyles(theme => ({
  accordionHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  bgColorDefault: {
    backgroundColor: theme.palette.background.default,
  },
}));
export interface MetricsAccordionPops {
  list: Array<MetricItemProps>;
}

export const MetricsAccordion: React.FC<MetricsAccordionPops> = ({ list }) => {
  const classes = useStyles();

  if (list.length <= 3) {
    return (
      <Box
        className={classes.accordionHeader}
        style={{ paddingLeft: '16px', paddingRight: '16px' }}
      >
        <Grid container spacing={2}>
          {list.map((metric, index) => (
            <Grid item xs={4} key={`${metric.name}${index}`}>
              <MetricItem {...metric} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Accordion style={{ width: '100%', boxShadow: 'none' }}>
      <AccordionSummary
        className={classes.bgColorDefault}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box className={classes.accordionHeader}>
          <Grid container spacing={2}>
            {list.slice(0, 3).map((metric, index) => (
              <Grid item xs={4} key={`${metric.name}${index}`}>
                <MetricItem {...metric} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        className={classes.bgColorDefault}
        style={{
          paddingRight: '52px',
        }}
      >
        <Grid container spacing={2}>
          {list.slice(3).map((metric, index) => (
            <Grid item xs={4} key={`${metric.name}${index}`}>
              <MetricItem {...metric} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
