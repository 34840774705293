import { MenuItem, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { WbTextField } from './WbTextField';
import { ClearButton } from './ClearButton';
import { SelectIcon } from './SelectIcon';

type ConditionalSelectProps =
  | {
      options: string[];
      getOptionLabel?: (value: string) => string;
      children?: never;
    }
  | {
      options?: never;
      getOptionLabel?: never;
      children: React.ReactNode;
    };

type Props = Omit<TextFieldProps, 'select' | 'SelectProps'> &
  ConditionalSelectProps & { clearable?: boolean };

export const WbSelect = React.forwardRef(
  (
    { clearable = false, getOptionLabel, InputProps, ...props }: Props,
    ref?: React.Ref<any>,
  ) => {
    const [internalValue, setInternalValue] = useState(props.value || '');

    useEffect(() => {
      setInternalValue(props.value || '');
    }, [props.value]);

    return (
      <WbTextField
        {...props}
        ref={ref}
        value={internalValue}
        onChange={e => {
          if (props.onChange) props.onChange(e);
          setInternalValue(e.target.value);
        }}
        select
        SelectProps={{
          IconComponent: SelectIcon,
        }}
        InputProps={{
          endAdornment: clearable && !props.disabled && (
            <ClearButton
              style={{
                marginRight: '25px',
              }}
              onClear={e => {
                const event = e as unknown as React.ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >;
                if (props.onChange) props.onChange(event);
              }}
            />
          ),
          ...InputProps,
        }}
      >
        {props?.options?.map((option, i) => (
          <MenuItem key={i} value={option}>
            {getOptionLabel?.(option) || option}
          </MenuItem>
        ))}

        {props?.children}
      </WbTextField>
    );
  },
);
