import React, { useMemo } from 'react';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import {
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import _ from 'lodash';
import { deserializeEntityRef } from '../../../api';
import {
  transformUrnToWitboostId,
  getEntityDisplayName,
} from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';
import useAsync from 'react-use/lib/useAsync';
import { URN } from '@agilelab/plugin-wb-platform-common';
import { WbTextField } from '@agilelab/plugin-wb-platform';

async function getEntityPropertyValue(
  fieldValue: string | undefined,
  property: string | undefined,
  catalogApi: CatalogApi,
  identityApi: IdentityApi,
): Promise<{ propertyRef: string; propertyLabel: string } | null> {
  if (!fieldValue || !property) return null;

  if (fieldValue) {
    const entityId = fieldValue.toLowerCase().startsWith(URN)
      ? transformUrnToWitboostId(fieldValue)
      : fieldValue;

    const entityRef = deserializeEntityRef(entityId!);

    if (entityRef) {
      const token = (await identityApi.getCredentials()).token;
      const entity = await catalogApi.getEntityByRef(entityRef, { token });
      if (entity) {
        const propertyValue = _.get(entity, property);
        let displayedLabel = propertyValue;

        if (propertyValue && typeof propertyValue === 'string') {
          let selectedEntity = null;
          try {
            selectedEntity =
              (await catalogApi.getEntityByRef(
                deserializeEntityRef(propertyValue),
                { token },
              )) ?? null;
          } catch (_error) {
            selectedEntity = null;
          }

          displayedLabel = displayLabel(selectedEntity ?? propertyValue);
        }

        if (
          propertyValue &&
          Array.isArray(propertyValue) &&
          propertyValue.every(element => typeof element === 'object')
        ) {
          displayedLabel = `${propertyValue.length} items selected`;
        }

        if (
          propertyValue &&
          Array.isArray(propertyValue) &&
          propertyValue.every(element => typeof element === 'string')
        ) {
          displayedLabel = propertyValue.join(',');
        }

        return {
          propertyRef: propertyValue,
          propertyLabel: displayedLabel,
        };
      }
    }
  }

  return null;
}

function displayLabel(property: Entity | string): string {
  if (typeof property === 'string') return property;
  return getEntityDisplayName(property);
}

export const EntitySelectionPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: { title = 'Entity', description = 'An entity from the catalog' },
    required,
    uiSchema,
    rawErrors,
    formData,
    formContext,
  } = props;
  const fieldName = uiSchema['ui:fieldName'] as string | undefined;
  const property = uiSchema['ui:property'] as string | undefined;
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const fieldValue = useMemo(
    () => (fieldName ? (formContext[fieldName] as string) : undefined),
    [fieldName, formContext],
  );

  const hiddenField = props.uiSchema['ui:options']
    ? props.uiSchema['ui:options']['ui:widget'] === 'hidden'
    : false;

  const { value: loadedProperty } = useAsync(async () => {
    const propertyFetched = await getEntityPropertyValue(
      fieldValue,
      property,
      catalogApi,
      identityApi,
    );

    const selectedProperty = fieldValue ? propertyFetched : null;
    onChange(selectedProperty?.propertyRef);
    return selectedProperty;
  }, [catalogApi, fieldValue, identityApi, property]);

  const onSelect = (value: string | null) => {
    const ref = value ? loadedProperty?.propertyRef : null;
    onChange(ref);
  };

  return (
    <WbTextField
      label={title}
      style={{ display: hiddenField ? 'none' : 'flex', width: '100%' }}
      inputProps={{ style: { width: '100%' } }}
      error={rawErrors?.length > 0 && !formData}
      disabled
      placeholder={title}
      helperText={description}
      required={required}
      value={loadedProperty?.propertyLabel || ''}
      onChange={event => onSelect(event.target.value)}
    />
  );
};
