import React, { useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core';
import useAsync from 'react-use/lib/useAsync';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
// eslint-disable-next-line monorepo/no-internal-import
import { panelCatalogApiRef } from '@agilelab/plugin-wb-builder-catalog/src/api'; // TODO FIXME WHEN WE DECIDE HOW TO FETCH ENVS FROM A SINGLE SOURCE
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { WbMultiSelect, WbSelect } from '../WbTextField';

const cache = new Map<string, { envs: string[]; lastFetched: number }>();

interface Props {
  onChange: (env: any) => void;
  multi?: boolean;
  value: string | string[];
  ref: React.Ref<unknown>;
  envs?: string[];
}

export const SelectEnvironment = React.forwardRef(
  (
    props: Props & Omit<TextFieldProps, 'value' | 'onChange' | 'select'>,
    ref: React.Ref<any>,
  ) => {
    const { onChange, multi, value, envs, children, ...textFieldProps } = props;
    const handleChange = (event: React.ChangeEvent<any>) => {
      onChange(event.target.value);
    };
    const panelCatalog = useApi(panelCatalogApiRef);
    const identifyApi = useApi(identityApiRef);

    const {
      loading,
      error,
      value: fetchedEnvs,
    } = useAsync(async (): Promise<string[]> => {
      // Cache the result for 5 minute
      if (
        cache.has('env-cache') &&
        cache.get('env-cache')!.lastFetched - Date.now() < 300000
      )
        return cache.get('env-cache')!.envs;
      const envsFromApi = await panelCatalog.fetchEnvironments(
        await identifyApi.getCredentials(),
      );
      cache.set('env-cache', { envs: envsFromApi, lastFetched: Date.now() });
      return envsFromApi;
    }, []);

    const displayedEnvs = useMemo(
      () => envs || fetchedEnvs,
      [envs, fetchedEnvs],
    );

    return multi && Array.isArray(value) ? (
      <WbMultiSelect
        {...textFieldProps}
        ref={ref}
        value={value}
        id="select-env"
        label="Environment"
        options={displayedEnvs || []}
        getOptionLabel={env => snakeCaseToTitleCase(env)}
        onChange={handleChange}
        disabled={loading || !!error || !displayedEnvs}
      />
    ) : (
      <WbSelect
        {...textFieldProps}
        ref={ref}
        value={value}
        id="select-env"
        label="Environment"
        options={displayedEnvs || []}
        getOptionLabel={env => snakeCaseToTitleCase(env)}
        onChange={handleChange}
        disabled={loading || !!error || !displayedEnvs}
      />
    );
  },
);
