import {
  Box,
  Popover,
  useTheme,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { AsyncEnumTableFilter } from './types';
import { FiltersButton } from './FilterButtons';
import { FilterInput } from './FilterInput';
import { Search } from './Search';
import { EnumButtons } from './EnumButtons';
import { isEqual } from 'lodash';

const useStyles = makeStyles(() => ({
  optionLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const AsyncEnumFilterValue = <T,>({
  field,
  value,
  renderValue,
}: AsyncEnumTableFilter<T>) => {
  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {field}
      {value && value.length > 0 && (
        <>
          <Typography component="span" variant="body2">
            :
          </Typography>
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            variant="body2"
            component="span"
          >
            {value.length > 1 ? (
              <Box
                component="span"
                style={{ display: 'flex', gap: 5, alignItems: 'center' }}
              >
                {renderValue(value[0])}
                <Typography
                  variant="caption"
                  style={{
                    width: 18,
                    height: 18,
                    background: theme.palette.secondary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '100%',
                    color: 'white',
                    fontSize: 9,
                    fontWeight: 600,
                  }}
                >
                  +{value.length - 1}
                </Typography>
              </Box>
            ) : (
              value.map(v => renderValue(v))
            )}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const AsyncEnumFilter = <T,>(filter: AsyncEnumTableFilter<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadedOptions, setLoadedOptions] = React.useState<T[]>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState<T[]>([]);
  const [value, setValue] = useState(filter.value || []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  async function loadData() {
    try {
      setLoading(true);
      const data = await filter.getOptions();
      setLoadedOptions(data);
      setOptions(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    loadData();
  };
  const onSearch = (search: string) => {
    setSearchValue(search);
    if (filter.onSearch) setOptions(filter.onSearch(search, loadedOptions));
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(filter.value || []);
      onSearch('');
    }, 500);
  };
  const onInputClear = () => {
    filter.onChange(undefined);
    setValue([]);
  };
  const onClear = () => {
    setValue([]);
  };
  const onSelectAll = () => {
    setValue(loadedOptions);
  };
  const onApply = () => {
    filter.onChange(value);
    setTimeout(() => {
      onSearch('');
    }, 500);
    setAnchorEl(null);
  };

  const onSelect = (checked: boolean, option: T) => {
    setValue(values =>
      checked ? values.concat(option) : values.filter(v => !isEqual(v, option)),
    );
  };

  React.useEffect(() => {
    setValue(filter.value || []);
  }, [filter.value]);

  return (
    <Box>
      <FilterInput
        onClick={openMenu}
        onClear={onInputClear}
        isOpened={open}
        selected={Boolean(filter.value?.length)}
        hideClear={filter.hideClear}
      >
        <AsyncEnumFilterValue {...filter} />
      </FilterInput>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          style={{ width: '280px', background: 'white', overflow: 'hidden' }}
        >
          {filter.onSearch && (
            <Box
              style={{
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                padding: '8px 16px',
              }}
            >
              <Search value={searchValue} onChange={onSearch} />
            </Box>
          )}

          {loading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <CircularProgress size="2rem" />
            </Box>
          ) : (
            <>
              <EnumButtons
                clear={{
                  onClick: onClear,
                }}
                selectAll={{
                  onClick: onSelectAll,
                }}
              />

              <Box
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px 16px',
                }}
              >
                {options.length <= 0 && (
                  <Typography style={{ padding: '16px 0px' }} variant="body2">
                    No options
                  </Typography>
                )}
                {options.map((option, index) => (
                  <FormControlLabel
                    classes={{
                      label: classes.optionLabel,
                    }}
                    key={index}
                    label={filter.renderOption(option)}
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(value.find(v => isEqual(v, option)))}
                        onChange={(_, checked) => onSelect(checked, option)}
                      />
                    }
                  />
                ))}
              </Box>
            </>
          )}
          <FiltersButton
            cancel={{
              onClick: closeMenu,
            }}
            apply={{
              onClick: onApply,
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
