import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { CollapseDockablePanelIcon, ExpandDockablePanelIcon } from './icons';

interface Props {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const CollapseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Tooltip title="Collapse">
      <Box
        display="flex"
        padding="0px 4px"
        height="100%"
        alignItems="center"
        style={{ cursor: 'pointer', background: '#C9F0F5' }}
        onClick={onClick}
      >
        <CollapseDockablePanelIcon color="primary" style={{ fontSize: 16 }} />
      </Box>
    </Tooltip>
  );
};

export const ExpandButton: React.FC = () => {
  return (
    <Tooltip title="Expand">
      <Box
        display="flex"
        padding="4px"
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: 'pointer', background: '#C9F0F5' }}
      >
        <ExpandDockablePanelIcon color="primary" style={{ fontSize: 16 }} />
      </Box>
    </Tooltip>
  );
};
