import { userConfigApiRef } from '@agilelab/plugin-wb-auth';
import {
  ConfigApi,
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { ConfigForm, ConfigInput } from './ConfigForm';
import { z } from 'zod';
import {
  WbCard,
  WbCardContent,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';

type UserConfigInputForm = ConfigInput & {
  title: string;
  description?: string;
  enabledOnConfig?: (config: ConfigApi) => boolean;
  valuePlaceholder?: string;
  version: number;
  validator?: z.ZodObject<{
    key: z.ZodString;
    value: z.ZodString;
    isSensitive: z.ZodBoolean;
  }>;
};

const defaultValidator = z.object({
  key: z.string(),
  value: z.string().min(1, 'The configuration value cannot be empty'),
  isSensitive: z.boolean(),
});

// Add here additional properties until we have this object from back end
const UserConfigs: UserConfigInputForm[] = [
  {
    key: 'gitlab-token',
    title: 'Personal Gitlab access token',
    description: 'The personal Gitlab access token for the logged user',
    isSensitive: true,
    value: '',
    valuePlaceholder: 'Gitlab access token',
    enabledOnConfig: (config: ConfigApi) =>
      // TODO: could not enforce the usePersonalToken condition since its visibility is limited to backend. restore when moving this to backend
      // config.has('integrations.usePersonalToken') &&
      // config.getBoolean('integrations.usePersonalToken') &&
      config.has('integrations.gitlab'),
    version: 1,
  },
  {
    key: 'bitbucket-server-token',
    title: 'Personal Bitbucket server token',
    description: 'Personal Bitbucket server access token for the logged user',
    isSensitive: true,
    value: '',
    valuePlaceholder: 'Bitbucket server token',
    enabledOnConfig: (config: ConfigApi) =>
      // TODO: could not enforce the usePersonalToken condition since its visibility is limited to backend. restore when moving this to backend
      // config.has('integrations.usePersonalToken') &&
      // config.getBoolean('integrations.usePersonalToken') &&
      config.has('integrations.bitbucketServer'),
    version: 1,
  },
];

export function UserConfigSettings() {
  const userConfigApi = useApi(userConfigApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const configApi = useApi(configApiRef);
  const [userConfigForms, setUserConfigForms] = useState<UserConfigInputForm[]>(
    [],
  );

  const { loading, error: fetchError } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    const configForms: UserConfigInputForm[] = [];
    for (const conf of UserConfigs) {
      if (conf.enabledOnConfig?.(configApi) ?? true) {
        const persistedConfig = await userConfigApi.getUserConfig({
          token: token!,
          key: conf.key,
          redactWith: '[REDACTED]',
        });
        configForms.push({
          ...conf,
          value: persistedConfig?.value ?? conf.value,
          version: 1,
        });
      }
    }
    setUserConfigForms(configForms);
    return;
  }, []);

  useEffect(() => {
    if (fetchError)
      alertApi.post({
        error: fetchError,
        severity: 'error',
      });
  }, [alertApi, fetchError]);

  const [editMode, setEditMode] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);

  const saveConfig = async (updatedConfig: ConfigInput): Promise<boolean> => {
    let success = false;
    try {
      const { token } = await identityApi.getCredentials();
      await userConfigApi.storeUserConfig({
        key: updatedConfig.key,
        value: updatedConfig.value,
        isSensitive: updatedConfig.isSensitive,
        token: token!,
      });
      success = true;
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsSaving(false);
    }
    return success;
  };

  const deleteConfigValue = async (configKey: string): Promise<boolean> => {
    let success = false;
    try {
      const { token } = await identityApi.getCredentials();
      await userConfigApi.deleteUserConfig({
        token: token!,
        key: configKey,
      });
      success = true;
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsSaving(false);
    }
    return success;
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12}>
        <WbCard title="User configurations">
          <WbCardContent>
            {loading ? (
              <CircularProgress />
            ) : (
              userConfigForms.map((config, index) => (
                <ConfigForm
                  key={config.key + config.version}
                  active={editMode === config.key}
                  editableKey={false}
                  forceSensitiveValue={config.isSensitive}
                  configName={config.title}
                  keyHelperText={config.description}
                  valuePlaceholder={config.valuePlaceholder ?? ''}
                  deleteDialogTitle="Clear user configuration"
                  deleteDialogContent={
                    <span>
                      Are you sure you want to clear the value of{' '}
                      <strong>{config.title}</strong>?
                    </span>
                  }
                  deleteDialogConfirmBtnText="Clear"
                  actionsEnabled={editMode === undefined}
                  isSaving={isSaving}
                  initialValue={{
                    key: config.key,
                    value: config.value,
                    isSensitive: config.isSensitive,
                  }}
                  validator={config.validator ?? defaultValidator}
                  onStartEditMode={() => setEditMode(config.key)}
                  onCancelEditMode={() => setEditMode(undefined)}
                  onSaveAction={async updatedValue => {
                    const success = await saveConfig(updatedValue);
                    if (success) {
                      setUserConfigForms(oldForms => {
                        const newForms = [...oldForms];
                        newForms[index] = {
                          ...oldForms[index],
                          ...updatedValue,
                          version: oldForms[index].version + 1,
                        };
                        return newForms;
                      });
                      setEditMode(undefined);
                    }
                  }}
                  onDeleteAction={async () => {
                    const success = await deleteConfigValue(config.key);
                    if (success) {
                      setUserConfigForms(oldForms => {
                        const newForms = [...oldForms];
                        newForms[index].value = '';
                        newForms[index].version = oldForms[index].version + 1;
                        return newForms;
                      });
                    }
                  }}
                />
              ))
            )}
          </WbCardContent>
        </WbCard>
      </Grid>
    </Grid>
  );
}
