import { IconButton, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import { ArrayFieldTemplateProps } from '@rjsf/core';
import { createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';
import React, { Fragment } from 'react';
import {
  WbCardActionButton,
  WbCardContent,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import ArrowUpwardIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Delete';

const RowButtons = (props: ArrayFieldTemplateProps['items'][0]) => {
  return (
    <td
      style={{
        position: 'sticky',
        right: -16,
        background: 'white',
        border: '1px solid #cbcbcb',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {(props.hasMoveUp || props.hasMoveDown) && (
          <IconButton
            color="secondary"
            size="small"
            tabIndex={-1}
            disabled={props.disabled || props.readonly || !props.hasMoveUp}
            onClick={props.onReorderClick(props.index, props.index - 1)}
            style={{ padding: 0 }}
          >
            <ArrowUpwardIcon fontSize="medium" />
          </IconButton>
        )}

        {(props.hasMoveUp || props.hasMoveDown) && (
          <IconButton
            color="secondary"
            size="small"
            tabIndex={-1}
            disabled={props.disabled || props.readonly || !props.hasMoveDown}
            onClick={props.onReorderClick(props.index, props.index + 1)}
            style={{ padding: 0 }}
          >
            <ArrowDownwardIcon fontSize="medium" />
          </IconButton>
        )}

        {(props.hasMoveUp || props.hasMoveDown) && (
          <div
            style={{
              height: '20px',
              borderRight: '1px solid #cbcbcb',
              margin: '0px 5px',
            }}
          />
        )}

        {props.hasRemove && (
          <IconButton
            size="small"
            tabIndex={-1}
            color="secondary"
            disabled={props.disabled || props.readonly}
            onClick={props.onDropIndexClick(props.index)}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </td>
  );
};

export const ArrayTableTemplate = (props: ArrayFieldTemplateProps) => {
  const theme = useTheme();

  const tableRowItems: any | undefined = (props.schema.items as any)
    ?.properties;
  const required = (props.schema.items as any).required;
  const columnHeaders: Array<{ title: string; required: boolean }> =
    tableRowItems
      ? Object.keys(tableRowItems).map((itemName: any) => ({
          title: tableRowItems[itemName].title,
          required: required.includes(itemName),
        }))
      : [];

  return (
    <WbWidget
      title={props.uiSchema['ui:title'] || props.title}
      actions={
        props.canAdd ? (
          <WbCardActionButton
            label="Add"
            disabled={!props.canAdd}
            icon={<AddIcon />}
            onClick={props.onAddClick}
            color="secondary"
          />
        ) : undefined
      }
    >
      <WbCardContent
        style={{ overflow: 'auto', background: 'rgb(238 238 238 / 30%)' }}
      >
        {props.items.length > 0 && (
          <table
            style={{
              border: '1px solid #cbcbcb',
              height: '100%',
              borderSpacing: 0,
            }}
          >
            <thead>
              <tr>
                {columnHeaders.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      padding: '12px 16px',
                      textAlign: 'left',
                      background: theme.palette.cardHeader,
                      border: '1px solid #cbcbcb',
                    }}
                  >
                    <Typography
                      style={{ fontWeight: 500 }}
                      variant="caption"
                      color="primary"
                    >
                      {header.title} {header.required ? '*' : ''}
                    </Typography>
                  </th>
                ))}

                <th
                  style={{
                    position: 'sticky',
                    right: -16,
                    background: 'white',
                    border: '1px solid #cbcbcb',
                    padding: '12px 16px',
                  }}
                >
                  <Typography
                    style={{ fontWeight: 500 }}
                    variant="caption"
                    color="secondary"
                  >
                    Actions
                  </Typography>
                </th>
              </tr>
            </thead>

            <tbody>
              {props.items.map(item => (
                <Fragment key={item.key}>
                  {React.Children.map(item.children, child =>
                    React.cloneElement(child, {
                      schema: {
                        ...child.props.schema,
                        RowButtons: <RowButtons {...item} />,
                      },
                    }),
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </WbCardContent>
    </WbWidget>
  );
};

export const ArrayTableTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'ArrayTableTemplate',
    component: ArrayTableTemplate as any,
  }),
);
