export const thresholdValueToString = (value: number) => {
  const metricLog = Math.log10(value);

  if (value < 1) {
    return value.toString().substring(0, 3);
  }
  if (metricLog >= 3 && metricLog < 4) {
    return `${value.toString().at(0)}K`;
  }
  if (metricLog >= 4 && metricLog < 6) {
    return `${value.toString().at(0)}K+`;
  }
  if (metricLog >= 6 && metricLog < 7) {
    return `${value.toString().at(0)}M`;
  }
  if (metricLog >= 7) {
    return `${value.toString().at(0)}M+`;
  }
  return value.toString().substring(0, 3);
};
