import { Box, Chip, makeStyles } from '@material-ui/core';
import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import { NotificationType } from '@agilelab/plugin-wb-notification-common';
import { useNotification } from './useNotificationProvider';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import DraftsIcon from '@material-ui/icons/Drafts';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
  filterWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  secondLevel: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
}));

export const NotificationsFilters = () => {
  const classes = useStyles();

  const {
    kind,
    setKind,
    readStatus,
    setReadStatus,
    requestStatus,
    setRequestStatus,
    notificationData,
    setAllAsRead,
    unreadNotificationCount,
  } = useNotification();

  return (
    <Box className={classes.filterWrapper}>
      <Box className={classes.chipContainer}>
        <Chip
          label="All"
          onClick={() => setKind(undefined)}
          variant={kind === undefined ? 'default' : 'outlined'}
          color="primary"
          deleteIcon={kind === undefined ? <DoneIcon /> : <></>}
          onDelete={() => () => setKind(undefined)}
        />
        <Chip
          label="Access Request"
          icon={<ErrorOutlineOutlinedIcon />}
          onClick={() => setKind(NotificationType.ACCESS_REQUEST)}
          variant={
            kind === NotificationType.ACCESS_REQUEST ? 'default' : 'outlined'
          }
          color="primary"
          deleteIcon={
            kind === NotificationType.ACCESS_REQUEST ? <DoneIcon /> : <></>
          }
          onDelete={() => setKind(NotificationType.ACCESS_REQUEST)}
        />
        <Chip
          label="Question"
          icon={<QuestionAnswerOutlinedIcon />}
          onClick={() => setKind(NotificationType.QUESTION)}
          variant={kind === NotificationType.QUESTION ? 'default' : 'outlined'}
          color="primary"
          deleteIcon={kind === NotificationType.QUESTION ? <DoneIcon /> : <></>}
          onDelete={() => setKind(NotificationType.QUESTION)}
        />
        <Chip
          label="Informative"
          icon={<NotificationsIcon />}
          onClick={() => setKind(NotificationType.INFORMATIVE)}
          variant={
            kind === NotificationType.INFORMATIVE ? 'default' : 'outlined'
          }
          color="primary"
          deleteIcon={
            kind === NotificationType.INFORMATIVE ? <DoneIcon /> : <></>
          }
          onDelete={() => setKind(NotificationType.INFORMATIVE)}
        />
      </Box>
      <Box className={classes.chipContainer}>
        <Chip
          label="Unread"
          icon={<MarkunreadIcon />}
          onClick={() =>
            setReadStatus(readStatus === 'unread' ? undefined : 'unread')
          }
          variant={readStatus === 'unread' ? 'default' : 'outlined'}
          color="secondary"
          deleteIcon={readStatus === 'unread' ? <DoneIcon /> : <></>}
          onDelete={() =>
            setReadStatus(readStatus === 'unread' ? undefined : 'unread')
          }
        />
        <Chip
          label="Read"
          icon={<DraftsIcon />}
          onClick={() =>
            setReadStatus(readStatus === 'read' ? undefined : 'read')
          }
          variant={readStatus === 'read' ? 'default' : 'outlined'}
          color="secondary"
          deleteIcon={readStatus === 'read' ? <DoneIcon /> : <></>}
          onDelete={() =>
            setReadStatus(readStatus === 'read' ? undefined : 'read')
          }
        />
        {!!notificationData?.total &&
          readStatus !== 'read' &&
          !kind &&
          unreadNotificationCount > 0 && (
            <Box className={classes.secondLevel}>
              <Chip
                label="Mark all as read"
                icon={<CheckCircleOutlineOutlinedIcon />}
                onClick={() => setAllAsRead()}
                variant="outlined"
                color="secondary"
              />
            </Box>
          )}
        {kind === NotificationType.ACCESS_REQUEST && (
          <Box className={classes.secondLevel}>
            <Chip
              label="Accepted"
              icon={<CheckCircleOutlineOutlinedIcon />}
              onClick={() =>
                setRequestStatus(
                  requestStatus === 'accept' ? undefined : 'accept',
                )
              }
              variant={requestStatus === 'accept' ? 'default' : 'outlined'}
              color="secondary"
              deleteIcon={requestStatus === 'accept' ? <DoneIcon /> : <></>}
              onDelete={() =>
                setRequestStatus(
                  requestStatus === 'accept' ? undefined : 'accept',
                )
              }
            />
            <Chip
              label="Rejected"
              icon={<NotInterestedIcon />}
              onClick={() =>
                setRequestStatus(
                  requestStatus === 'reject' ? undefined : 'reject',
                )
              }
              variant={requestStatus === 'reject' ? 'default' : 'outlined'}
              color="secondary"
              deleteIcon={requestStatus === 'reject' ? <DoneIcon /> : <></>}
              onDelete={() =>
                setRequestStatus(
                  requestStatus === 'reject' ? undefined : 'reject',
                )
              }
            />
          </Box>
        )}
        {kind === NotificationType.QUESTION && (
          <Box className={classes.secondLevel}>
            <Chip
              label="To Answer"
              icon={<HelpIcon />}
              onClick={() =>
                setRequestStatus(
                  requestStatus === 'toAnswer' ? undefined : 'toAnswer',
                )
              }
              variant={requestStatus === 'toAnswer' ? 'default' : 'outlined'}
              color="secondary"
              deleteIcon={requestStatus === 'toAnswer' ? <DoneIcon /> : <></>}
              onDelete={() =>
                setRequestStatus(
                  requestStatus === 'toAnswer' ? undefined : 'toAnswer',
                )
              }
            />
            <Chip
              label="Answered"
              icon={<CommentIcon />}
              onClick={() =>
                setRequestStatus(
                  requestStatus === 'answered' ? undefined : 'answered',
                )
              }
              variant={requestStatus === 'answered' ? 'default' : 'outlined'}
              color="secondary"
              deleteIcon={requestStatus === 'answered' ? <DoneIcon /> : <></>}
              onDelete={() =>
                setRequestStatus(
                  requestStatus === 'answered' ? undefined : 'answered',
                )
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
