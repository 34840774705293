import { gql } from '@apollo/client/core';
import {
  ExternalOutputPort,
  OutputPort,
  OutputPortWithWarnings,
} from '../components/types';

export const GET_OUTPUT_PORT_BY_ID = gql`
  query getOutputPortById($id: Int) {
    marketplace_OutputPort(where: { id: { _eq: $id } }) {
      DataProductInstance {
        DataProduct {
          id
          name
          display_name
          Domain {
            name
            sub_domain_of
          }
          data_product_owner_display_name
        }
        Environment {
          name
        }
        id
        version
        descriptor
      }
      descriptor
      display_name
      fully_qualified_name
      id
      name
    }
  }
`;

export const GET_OUTPUT_PORT_AND_WARNINGS_BY_ID = gql`
  query getOutputPortById($id: Int) {
    marketplace_OutputPort(where: { id: { _eq: $id } }) {
      DataProductInstance {
        DataProduct {
          id
          name
          display_name
          Domain {
            name
            sub_domain_of
          }
          data_product_owner_display_name
        }
        Environment {
          name
        }
        id
      }
      descriptor
      display_name
      fully_qualified_name
      id
      name
      mode
      outputporttype
      OutputPortWarnings {
        id
        message
        content
        kind
      }
    }
  }
`;

export const GET_OUTPUT_PORT_BY_EXTERNAL_ID = gql`
  query getOutputPortByExternalId($externalIds: [String]) {
    marketplace_OutputPort(where: { external_id: { _in: $externalIds } }) {
      DataProductInstance {
        id
        DataProduct {
          id
          display_name
          name
          Domain {
            name
          }
        }
      }
      id
      external_id
      display_name
    }
  }
`;

export interface IGetOutputPortByIdData {
  marketplace_OutputPort: OutputPort[];
}

export interface IGetOutputPortAndWarningsByIdData {
  marketplace_OutputPort: OutputPortWithWarnings[];
}

export interface IGetOutputPortByIdVars {
  id: number;
}

export interface IGetOutputPortByExternalIdData {
  marketplace_OutputPort: ExternalOutputPort[];
}

export interface IGetOutputPortByExternalIdVars {
  externalIds: string[];
}
