import { PolicyGql } from '@agilelab/plugin-wb-governance-common';
import { gql } from '@apollo/client/core';

export const GOVERNANCE_ENTITIES = gql`
  query GOVERNANCE_ENTITIES($ids: [String!] = "") {
    cgp_governance_entity(where: { governance_entity_id: { _in: $ids } }) {
      governance_entity_id
      name
      description
      content
      status
      version
    }
  }
`;

export type GovernanceEntitiesQueryType = {
  cgp_governance_entity: Pick<
    PolicyGql,
    | 'governance_entity_id'
    | 'name'
    | 'description'
    | 'content'
    | 'status'
    | 'version'
  >[];
};
