import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const DeprecatedIcon = createSvgIcon(
  <>
    <g id="Layer_1-2">
      <g id="Group_535">
        <g id="Ellipse_19">
          <path
            fill="#4e6980"
            d="m12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.38,12-12S18.62,0,12,0Zm.84,18.19c0,.56-.45,1-1,1s-1-.44-1-1v-1.13c0-.55.44-1,1-1s1,.45,1,1v1.13Zm0-5.27c0,.55-.45,1-1,1s-1-.45-1-1V4.77c0-.56.44-1,1-1s1,.44,1,1v8.15Z"
          />
        </g>
      </g>
    </g>
  </>,
  'DeprecatedIcon',
);
