import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionActions,
  AccordionActionsProps,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@material-ui/core';

export const NotificationAccordion = withStyles((_theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0,0,0,.12)',
      '&:before': {
        display: 'none',
      },
    },
    expanded: {
      margin: 'auto !important',
    },
  }),
)((props: AccordionProps) => <Accordion {...props} />);

export const NotificationAccordionSummary = withStyles((_theme: Theme) =>
  createStyles({
    root: {},
    content: {
      flexDirection: 'column',
    },
  }),
)((props: AccordionSummaryProps) => <AccordionSummary {...props} />);

export const NotificationAccordionDetails = withStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingTop: theme.spacing(1.5),
    },
  }),
)((props: AccordionDetailsProps) => <AccordionDetails {...props} />);

export const NotificationAccordionAction = withStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(4),
    },
  }),
)((props: AccordionActionsProps) => <AccordionActions {...props} />);
