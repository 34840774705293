import React from 'react';
import { Editor } from '../common/Editor/Editor';

interface PolicyCueScriptEditorProps {
  onUpdate?: (code?: string) => void;
  editable?: boolean;
  cueScript: string;
  saving?: boolean;
  handleSave?: () => void;
  showSaveButton?: boolean;
}

export const PolicyCueScriptEditor: React.FC<
  PolicyCueScriptEditorProps
> = props => {
  return (
    <Editor
      id="policy-editor"
      name="Cue Script"
      content={props.cueScript}
      onUpdate={props.onUpdate}
      editable={props.editable}
      saving={props.saving}
      handleSave={props.handleSave}
      showSaveButton={props.showSaveButton}
      parser="go"
    />
  );
};
