import React, { useCallback } from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import { ProgressButton } from '../ProgressButton';

export type DialogOnClose = (
  event: React.UIEvent,
  reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClick' | 'submitClick',
) => void;

export interface ConfirmDialogProps
  extends Omit<DialogProps, 'onClose' | 'title'> {
  title: React.ReactNode | React.ReactNode[];
  description?: React.ReactNode | React.ReactNode[];
  confirmText?: string;
  confirmButtonText?: string;
  confirmColor?: ButtonProps['color'];
  inProgress?: boolean;
  onConfirm: () => void;
  onClose: DialogOnClose;
}

function ConfirmDialogComponent(props: ConfirmDialogProps): JSX.Element {
  const {
    title,
    description,
    confirmText,
    inProgress,
    onClose,
    onConfirm,
    open,
    confirmButtonText,
    confirmColor,
    ...dialogProps
  } = props;

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      onConfirm();
    },
    [onConfirm],
  );

  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{description}</DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={e => onClose(e, 'cancelClick')}>
            Cancel
          </Button>

          <ProgressButton
            type="submit"
            variant="contained"
            inProgress={!!inProgress}
            color="primary"
          >
            {confirmButtonText || 'Ok'}
          </ProgressButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const ConfirmDialog = React.memo(ConfirmDialogComponent);
