import React, { useMemo } from 'react';
import { stringifyLogs } from './utils';
import { WbCard } from '../WbCard';
import { CopyButton } from '../CopyButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Log } from '../../types';
import { CodeSnippet } from '../CodeSnippet';
import { LogViewer } from '@backstage/core-components';
import { Box } from '@material-ui/core';

const styles = {
  button: {
    minWidth: 0,
  },
  icon: {
    width: '20px',
    height: '20px',
  },
};

interface Props {
  title: string;
  logs?: Log[];
  text?: string;
  style?: React.CSSProperties;
  useDefaultLogViewer?: boolean;
}

export const LogsCard: React.FC<Props> = ({
  logs,
  text,
  title,
  style,
  useDefaultLogViewer,
}) => {
  const logText = useMemo(
    () =>
      logs && logs.length > 0
        ? stringifyLogs(logs)
        : text || 'No data available',
    [logs, text],
  );

  return (
    <WbCard
      cardStyle={{ ...style }}
      title={title}
      actions={
        (logs && logs.length > 0) || text ? (
          <CopyButton
            text={logText}
            hideIcon
            size="small"
            color="secondary"
            variant="text"
            style={styles.button}
          >
            <FileCopyIcon style={styles.icon} />
          </CopyButton>
        ) : undefined
      }
    >
      {useDefaultLogViewer ? (
        <Box
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <LogViewer text={logText} />
        </Box>
      ) : (
        <CodeSnippet
          text={logText}
          language="text"
          customStyle={{
            padding: '16px',
            margin: '0',
            height: '100%',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
          }}
        />
      )}
    </WbCard>
  );
};
