import {
  LdapAuth,
  ldapAuthApiRef,
  userConfigApiRef,
} from '@agilelab/plugin-wb-auth';
import {
  auditApiRef,
  CustomAlertApiForwarder,
  customAlertApiRef,
  MeshAuditClient,
} from '@agilelab/plugin-wb-platform';
import { rbacApiRef, RbacClient } from '@agilelab/plugin-wb-rbac';
import { UserConfigClient } from '@agilelab/plugin-wb-user-config-client';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { UserHeadersClient } from '@agilelab/plugin-wb-user-headers-common';
import { userHeadersApiRef } from '@agilelab/plugin-wb-user-headers';
import { OntologyClient } from '@agilelab/plugin-wb-ontology-client';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ontologyApiRef } from '@agilelab/plugin-wb-ontology';
import {
  CustomFrontendClient,
  customFrontendApiRef,
} from '@agilelab/plugin-wb-platform';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: rbacApiRef,
    deps: { discoveryApi: discoveryApiRef },
    factory: ({ discoveryApi }) => new RbacClient(discoveryApi),
  }),
  createApiFactory({
    api: ontologyApiRef,
    deps: { catalogApi: catalogApiRef, discoveryApi: discoveryApiRef },
    factory: ({ catalogApi, discoveryApi }) =>
      new OntologyClient(catalogApi, discoveryApi),
  }),
  createApiFactory({
    api: customAlertApiRef,
    deps: {},
    factory: () => new CustomAlertApiForwarder(),
  }),
  createApiFactory({
    api: ldapAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, configApi }) =>
      LdapAuth.create({
        discoveryApi,
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: userConfigApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new UserConfigClient({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: auditApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new MeshAuditClient(discoveryApi, identityApi),
  }),
  createApiFactory({
    api: userHeadersApiRef,
    deps: {
      userConfigApi: userConfigApiRef,
      configApi: configApiRef,
    },
    factory: ({ userConfigApi, configApi }) =>
      new UserHeadersClient(userConfigApi, {
        enabled: configApi.getBoolean('mesh.userHeaders.enabled'),
        prefix: configApi.getString('mesh.userHeaders.prefix'),
      }),
  }),
  createApiFactory({
    api: customFrontendApiRef,
    deps: {
      configApi: configApiRef,
      customAlertApi: customAlertApiRef,
    },
    factory: ({ configApi, customAlertApi }) =>
      new CustomFrontendClient(configApi, customAlertApi),
  }),
  ScmAuth.createDefaultApiFactory(),
];
