import { loader, Monaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';

window.MonacoEnvironment = {
  getWorker: _ => {
    return new Worker(
      new URL(
        'monaco-editor/esm/vs/editor/editor.worker?worker',
        import.meta.url,
      ),
    );
  },
  createTrustedTypesPolicy(_: string) {
    return undefined;
  },
};

const LIGHT_GREEN: string = '#D9FFD9';
const GREEN: string = '#66FF66';
const LIGHT_RED: string = '#FFCCCC';
const RED: string = '#FF9999';
const BACKGROUND_COLOR: string = '#ffffff';

interface MonacoThemeData {
  monaco: Monaco;
  themeName: string;
  baseTheme?: monaco.editor.BuiltinTheme;
  revertColors?: boolean;
  /**
   * you can find theme color properties here
   * https://code.visualstudio.com/api/references/theme-color
   */
  colors?: monaco.editor.IColors;
  rules?: monaco.editor.ITokenThemeRule[];
}

/**
 * Asynchronously initializes the Monaco Editor loader and returns a Promise that resolves to the Monaco instance.
 * This is used to prevent witboost to fetch monaco data using CDN.
 *
 * @returns {Promise<Monaco>} - A Promise that resolves to the initialized Monaco instance.
 */
const initMonacoLoader = async (): Promise<Monaco> => {
  loader.config({ monaco });
  return loader.init();
};

/**
 * Initializes the Monaco Editor theme based on the provided theme data.
 *
 * @param {MonacoThemeData} themeData - The data containing information about the Monaco Editor theme.
 * @returns {void}
 */
export const initTheme = (themeData: MonacoThemeData): void => {
  if (themeData.baseTheme) {
    themeData.monaco.editor.defineTheme(themeData.themeName, {
      colors: {
        ...(themeData.colors ?? {}),
      },
      rules: themeData.rules ?? [],
      base: themeData.baseTheme,
      inherit: true,
    });
    return;
  }
  themeData.monaco.editor.defineTheme(themeData.themeName, {
    base: 'vs',
    inherit: true,
    rules: themeData.rules ?? [],
    colors: {
      'editor.background': BACKGROUND_COLOR,
      'diffEditor.insertedTextBackground': getDefaultColor({
        revertColors: themeData.revertColors,
      }).text,
      'diffEditor.insertedLineBackground': getDefaultColor({
        revertColors: themeData.revertColors,
      }).line,
      'diffEditor.removedTextBackground': getDefaultColor({
        revertColors: themeData.revertColors,
        isRemoveColor: true,
      }).text,
      'diffEditor.removedLineBackground': getDefaultColor({
        revertColors: themeData.revertColors,
        isRemoveColor: true,
      }).line,
      ...(themeData.colors ?? {}),
    },
  });
};

/**
 * Gets the default color configuration based on the provided options.
 *
 * @param {object} [options] - Options for configuring the default colors.
 * @param {boolean} [options.isRemoveColor] - Indicates if the color is for removed elements.
 * @param {boolean} [options.revertColors] - Indicates if colors should be reverted.
 * @returns {{ line: string; text: string }} - The default color configuration for line and text.
 */
function getDefaultColor(options?: {
  isRemoveColor?: boolean;
  revertColors?: boolean;
}): {
  line: string;
  text: string;
} {
  if (options?.revertColors) {
    return options.isRemoveColor
      ? { line: LIGHT_GREEN, text: GREEN }
      : { line: LIGHT_RED, text: RED };
  }
  return options?.isRemoveColor
    ? { line: LIGHT_RED, text: RED }
    : { line: LIGHT_GREEN, text: GREEN };
}

initMonacoLoader().then((monacoInstance: Monaco) => {
  initTheme({
    monaco: monacoInstance,
    themeName: 'default',
    colors: {
      'editor.background': '#f8f8f8',
    },
  });
  initTheme({
    monaco: monacoInstance,
    themeName: 'dark',
    baseTheme: 'vs-dark',
  });
  initTheme({
    monaco: monacoInstance,
    themeName: 'diffReverseProvisioning',
    revertColors: true,
  });
  initTheme({
    monaco: monacoInstance,
    themeName: 'diffEditorWizard',
    revertColors: false,
  });
  initTheme({
    monaco: monacoInstance,
    themeName: 'cueFileEditor',
    colors: {
      'editorGutter.background': '#f8f8f8',
    },
  });
});
