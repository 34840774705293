import { InformativeNotification } from '@agilelab/plugin-wb-notification-common';
import { Typography, useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useNotificationStyle } from './NotificationsPageStyle';
import DOMPurify from 'dompurify';
import {
  NotificationAccordion,
  NotificationAccordionDetails,
  NotificationAccordionSummary,
} from './NotificationAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MarkAsReadButton } from './MarkAsReadButton';
import { Avatar, CodeSnippet } from '@backstage/core-components';
import logo from './witboost_logo.svg';
import { APP_NAME } from '@agilelab/plugin-wb-platform-common';

const RenderHTML = (props: { HTML: string }) => {
  if (props.HTML)
    return (
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.HTML) }}
      />
    );
  return <></>;
};

const RenderJSON = (props: { JSON: string }) => {
  if (props.JSON)
    try {
      return (
        <CodeSnippet
          text={JSON.stringify(props.JSON, null, 2)}
          language="json"
          customStyle={{
            padding: '16px',
            margin: '0',
            maxHeight: '200px',
            backgroundColor: '#FFFF',
          }}
        />
      );
    } catch (error) {
      return <Typography color="error">Cannot Parse JSON Response</Typography>;
    }
  return <></>;
};

export const InformativeNotificationAccordion = (props: {
  notification: InformativeNotification;
  onAccordionExpansionsChange: (value: string | boolean) => void;
  expandedAccordion: string | boolean;
}) => {
  const { notification, onAccordionExpansionsChange, expandedAccordion } =
    props;
  const classes = useNotificationStyle();
  const theme = useTheme();

  const expandable =
    !!notification.notification_request.html ||
    !!notification.notification_request.json;

  const onToggle = useCallback(
    (_event: object, expanded: boolean) => {
      if (expandable && expanded) {
        onAccordionExpansionsChange(notification.id.toString());
      } else {
        onAccordionExpansionsChange(false);
      }
    },
    [expandable, notification.id, onAccordionExpansionsChange],
  );

  return (
    <NotificationAccordion
      onChange={onToggle}
      expanded={expandedAccordion === notification.id.toString()}
      style={{
        backgroundColor: !!notification.read_at
          ? 'transparent'
          : theme.palette.common.white,
      }}
    >
      <NotificationAccordionSummary
        expandIcon={expandable ? <ExpandMoreIcon /> : null}
        style={{ cursor: expandable ? 'pointer' : 'default' }}
      >
        <div className={classes.summaryHeader}>
          <Typography variant="body2" className={classes.notificationTime}>
            {DateTime.fromISO(notification.created_at).toFormat(
              'dd/MM/yyyy HH:mm',
            )}
          </Typography>
          <MarkAsReadButton notification={notification} />
        </div>
        <div className={classes.summaryDescription}>
          <NotificationsIcon color="primary" />
          <Avatar
            displayName={notification.sender || APP_NAME}
            picture={logo}
            customStyles={{
              width: theme.spacing(7),
              height: theme.spacing(7),
            }}
          />
          <div className={classes.summaryDescriptionText}>
            <span>
              <b>{notification.notification_request.text}</b>
            </span>
          </div>
        </div>
      </NotificationAccordionSummary>
      <NotificationAccordionDetails style={{ flexDirection: 'column' }}>
        <RenderHTML HTML={notification.notification_request.html} />
        <div style={{ maxWidth: '100%' }}>
          <RenderJSON JSON={notification.notification_request.json} />
        </div>
      </NotificationAccordionDetails>
    </NotificationAccordion>
  );
};
