import { BackstageTheme } from '@backstage/theme';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';

export const useOveflowingElement = (ref: React.MutableRefObject<any>) => {
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const isSmallDevice = useMediaQuery<BackstageTheme>(theme =>
    theme.breakpoints.down('lg'),
  );

  React.useEffect(() => {
    const e = ref.current;
    if (e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth) {
      setIsOverflowing(true);
    }
  }, [ref, isSmallDevice]);

  return isOverflowing;
};
