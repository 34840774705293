import React from 'react';
import { LayoutTemplate, createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';
import { Box, Divider, Typography, useTheme } from '@material-ui/core';

const DEFAULT_ELEMENTS_PER_ROW = 2;

export const HorizontalTemplate: LayoutTemplate = props => {
  const theme = useTheme();
  const elementsPerRowBySchema = props?.uiSchema['ui:options']?.elementsPerRow;

  const elementsPerRow =
    typeof elementsPerRowBySchema === 'number'
      ? elementsPerRowBySchema
      : DEFAULT_ELEMENTS_PER_ROW;

  const columnGap = 10;
  const gapCount = elementsPerRow - 1;
  const totalGapWidth = gapCount * columnGap;

  const columnMaxWidth = `calc((100% - ${totalGapWidth}px) / ${elementsPerRow})`;

  return (
    <>
      <Box margin={`${theme.spacing(1)}px 0px`}>
        <Typography variant="h6">{props.title}</Typography>
        <Divider />
      </Box>

      <div
        style={{
          marginTop: 20,
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(max(200px, ${columnMaxWidth}), 1fr))`,
          gridGap: columnGap,
          rowGap: 20,
        }}
      >
        {props.properties.map(p => p.content)}
      </div>
    </>
  );
};

export const HorizontalTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'HorizontalTemplate',
    component: HorizontalTemplate,
  }),
);
