/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useApi } from '@backstage/core-plugin-api';
import {
  CatalogApi,
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { useTheme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { useCallback, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  WmCompleteUiSchema,
  WmFieldExtensionComponentProps,
} from '../../../extensions/types';
import { usePrevious } from '../../hooks/useEventStream';
import { Entity } from '@backstage/catalog-model';
import { getEntityDisplayName } from '@agilelab/plugin-wb-builder-common';
import {
  CustomAlertApi,
  WbAutocomplete,
  customAlertApiRef,
  useEntitiesOwnershipFilters,
} from '@agilelab/plugin-wb-platform';

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityPicker` field extension.
 *
 * @public
 */
export interface EntityPickerUiOptions {
  allowedKinds?: string[];
  defaultKind?: string;
  defaultNamespace?: string | false;
  excludeValues?: string[];
  showOnlyUserOwnGroups?: boolean;
  'ui:widget'?: string;
}

export const addKindFilter = (
  filter: any,
  uiSchema: WmCompleteUiSchema<EntityPickerUiOptions>,
  _formContext?: any,
  _alertApi?: CustomAlertApi,
) => {
  if (uiSchema['ui:options']) {
    const allowedKinds = uiSchema['ui:options'].allowedKinds as string[];
    if (allowedKinds) {
      filter.kind = allowedKinds;
    }
  }

  return filter;
};

export const addFieldFilter = (
  filter: any,
  uiSchema: WmCompleteUiSchema<EntityPickerUiOptions>,
  formContext?: any,
  alertApi?: CustomAlertApi,
) => {
  if (uiSchema['ui:filter']) {
    uiSchema['ui:filter'].forEach(uiFilter => {
      const field = uiFilter.fieldName;

      if (!field) {
        alertApi?.post({
          message: `Parameter 'fieldName' is not provided under 'ui:filter'! Please check the template!`,
          severity: 'error',
        });
      }

      const entityPath = uiFilter.entityPath;

      if (!entityPath) {
        alertApi?.post({
          message: `Parameter 'entityPath' is not provided under 'ui:filter'! Please check the template!`,
          severity: 'error',
        });
      }

      if (field) {
        const entityPathValue = formContext[field];

        if (entityPathValue) {
          filter[entityPath] = entityPathValue;
        }
      }
    });
  }

  return filter;
};

async function fetchEntities(
  uiSchema: WmCompleteUiSchema<EntityPickerUiOptions>,
  formContext: any,
  alertApi: CustomAlertApi,
  catalogApi: CatalogApi,
  getOwnershipFilters: (
    kinds: string[],
  ) => Promise<Record<string, string | symbol | (string | symbol)[]>[]>,
  defaultKind?: string,
  defaultNamespace?: string | false,
  excludeValues?: string[],
  showOnlyUserOwnGroups?: boolean,
): Promise<Entity[]> {
  const allowedKinds = uiSchema['ui:options']?.allowedKinds || [];

  let filter = undefined;

  if (showOnlyUserOwnGroups) {
    const ownershipFilters = await getOwnershipFilters(allowedKinds);
    const fieldFilters = addFieldFilter({}, uiSchema, formContext, alertApi);
    filter = ownershipFilters.map(o => ({ ...o, ...fieldFilters }));
  } else {
    filter = [addKindFilter, addFieldFilter].reduce(
      (filterResult, filterFunction) =>
        filterFunction(filterResult, uiSchema, formContext, alertApi),
      {},
    );
  }

  const entities = await catalogApi.getEntities(
    Object.keys(filter).length ? { filter } : undefined,
  );
  return entities?.items.filter(e => {
    const entityName = humanizeEntityRef(e, { defaultKind, defaultNamespace });
    return excludeValues ? !excludeValues.includes(entityName) : entityName;
  });
}

/**
 * The underlying component that is rendered in the form for the `EntityPicker`
 * field extension.
 *
 * @public
 */
export const EntityPicker = (
  props: WmFieldExtensionComponentProps<string, EntityPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = 'Entity', description = 'An entity from the catalog' },
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
    formContext,
  } = props;
  const defaultKind = uiSchema['ui:options']?.defaultKind as string | undefined;
  const defaultNamespace = uiSchema['ui:options']?.defaultNamespace;
  const alertApi = useApi(customAlertApiRef);
  const catalogApi = useApi(catalogApiRef);
  const { getOwnershipFilters } = useEntitiesOwnershipFilters();
  const excludeValues = uiSchema['ui:options']?.excludeValues as
    | Array<string>
    | undefined;
  const prevFormContext = usePrevious(formContext);
  const [stateEntities, setEntities] = useState<Entity[]>();
  const [value, setValue] = useState<Entity | null | undefined>(undefined);
  const showOnlyUserOwnGroups = uiSchema['ui:options']?.showOnlyUserOwnGroups;

  const { value: entities, loading } = useAsync(async () => {
    // fetch the Entity from the passed value
    const formDataEntity: Entity | null = formData
      ? (await catalogApi.getEntityByRef(formData)) ?? null
      : null;

    // check if this field depends on the value of another one using the ui:filter flag
    // and if at least one value of the dependent ui:filter has changed
    const filtersChanged =
      uiSchema['ui:filter'] &&
      prevFormContext &&
      !uiSchema['ui:filter'].every(
        uiFilter =>
          formContext[uiFilter.fieldName] ===
          prevFormContext[uiFilter.fieldName],
      );

    // if a dependent field changed or if there are no cached entities
    if (filtersChanged || !stateEntities) {
      // load all the entities using the dependent filters
      const fetchedEntities = await fetchEntities(
        uiSchema,
        formContext,
        alertApi,
        catalogApi,
        getOwnershipFilters,
        defaultKind,
        defaultNamespace,
        excludeValues,
        showOnlyUserOwnGroups,
      );

      // cache the loaded entities
      setEntities(fetchedEntities);

      // if the provided value is inside the fetched ones set it
      if (
        formDataEntity &&
        fetchedEntities.find(
          e =>
            humanizeEntityRef(e, {
              defaultKind,
              defaultNamespace,
            }) ===
            humanizeEntityRef(formDataEntity, {
              defaultKind,
              defaultNamespace,
            }),
        )
      ) {
        setValue(formDataEntity);
        return fetchedEntities;
      }

      // if there is only one available entity, set it as default selected one
      const defaultValue =
        fetchedEntities?.length === 1 ? fetchedEntities[0] : null;
      if (defaultValue) {
        const defaultRef = humanizeEntityRef(defaultValue, {
          defaultKind,
          defaultNamespace,
        });
        setValue(defaultValue);
        onChange(defaultRef);
        // If no valid values, clear the selection
      } else {
        setValue(undefined);
        onChange(undefined);
      }

      return fetchedEntities;
    }

    // otherwise return the cached entities
    return stateEntities;
  }, [formContext]);

  const onSelect = useCallback(
    (_: any, val: Entity | string | null) => {
      if (typeof val !== 'string') {
        const ref = val
          ? humanizeEntityRef(val, { defaultKind, defaultNamespace })
          : undefined;
        setValue(val);
        onChange(ref);
      }
    },
    [onChange, defaultKind, defaultNamespace],
  );
  const hiddenFieldValue = uiSchema['ui:options']?.['ui:widget'];
  const isHiddenField = hiddenFieldValue === 'hidden';
  const disabled = uiSchema['ui:disabled'];
  const noPermissionWarning = entities?.length === 0;
  const warningMessage =
    'There are no results for this picker. Please make sure you have the required permissions';
  const theme = useTheme();
  const color = noPermissionWarning
    ? {
        color: required
          ? theme.palette.error.main
          : theme.palette.warning.light,
      }
    : {};
  // the Autocomplete's "value" property needs to be "null" to create it in controlled mode (the status is kept outside)
  return (
    <FormControl required={required} error={rawErrors?.length > 0 && !value}>
      <WbAutocomplete
        style={{ display: isHiddenField ? 'none' : 'flex', width: '100%' }}
        disabled={(entities && entities.length <= 1) || disabled}
        id={idSchema?.$id}
        value={value || null}
        loading={loading}
        onChange={onSelect}
        options={entities || []}
        autoSelect
        freeSolo={false}
        getOptionLabel={(option: Entity) => getEntityDisplayName(option)}
        getOptionSelected={(option: Entity, val: Entity) =>
          humanizeEntityRef(option, { defaultKind, defaultNamespace }) ===
          humanizeEntityRef(val, { defaultKind, defaultNamespace })
        }
        label={title}
        helperText={noPermissionWarning ? warningMessage : description}
        required={required}
        FormHelperTextProps={{
          style: { ...color },
        }}
      />
    </FormControl>
  );
};
