import { questionNotificationRequestDataZod } from './QuestionNotificationRequestData';
import { z } from 'zod';
import { NotificationType } from '../utils/types';
import { informativeNotificationRequestDataZod } from './InformativeNotificationRequestData';
import { accessRequestNotificationRequestDataZod } from './AccessRequestNotificationRequestData';
import { compactStringRef } from '@agilelab/plugin-wb-builder-common';

/**
 * Used by other backend modules of Witboost to inform users through notifications.
 * The sender will appear to be the platform itself, but since the nickname of the platform is configurable, it starts with a prefix to make some validations beforehand.
 */
export const PLATFORM_NICKNAME_PREFIX: string = 'platform';

// Common properties of notification request
const notificationRequestZod = z.object({
  recipient: z.any(), // Can be a recipient or an array of recipients
  sender: z.preprocess(
    value => compactStringRef(value as string),
    z
      .string()
      .regex(new RegExp('^user:.+$'))
      .or(z.string().regex(new RegExp(`^${PLATFORM_NICKNAME_PREFIX}`))),
  ),
  kind: z.nativeEnum(NotificationType),
});

// Extra parameters for access request
const accessRequestBodyZod = z.object({
  notification_request: z.object(accessRequestNotificationRequestDataZod.shape),
  hook_id: z.string(),
});

const accessResponseBodyZod = z.object({
  notification_request: z.object(
    accessRequestNotificationRequestDataZod.extend(
      z.object({ is_requester: z.boolean(), status: z.string() }).shape,
    ).shape,
  ),
  is_requester: z.boolean(),
});

export const accessResponseNotificationRequestZod =
  notificationRequestZod.extend(accessResponseBodyZod.shape);

// Extend common notification request with parameters of different kinds of notification
export const accessRequestNotificationRequestZod =
  notificationRequestZod.extend(accessRequestBodyZod.shape);

export const questionNotificationRequestZod = notificationRequestZod.extend({
  notification_request: questionNotificationRequestDataZod,
});

export const informativeNotificationRequestZod = notificationRequestZod.extend({
  notification_request: informativeNotificationRequestDataZod,
});
