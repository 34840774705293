import {
  Checkbox,
  FormControl,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  WmCompleteUiSchema,
  WmFieldExtensionComponentProps,
} from '../../../extensions/types';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  WbAutocomplete,
  WbChip,
  customAlertApiRef,
  renderMessage,
} from '@agilelab/plugin-wb-platform';
import {
  MicroserviceConfig,
  CustomUrlOperations,
  parseConfiguration,
  parseDynamicFields,
  fetchMicroserviceValues,
  validateUiSchema,
} from './CustomUrlUtilities';
import { usePrevious } from '../../hooks/useEventStream';
import { CustomError } from '@agilelab/plugin-wb-platform-common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AutocompleteRenderOptionState } from '@material-ui/lab';

interface CustomUrlPickerUiOptions {
  maxNumberToSelect?: number;
  allowArbitraryValues?: boolean;
  selectedField: string;
}

export const maxNumberToSelect = (
  uiSchema: WmCompleteUiSchema<CustomUrlPickerUiOptions>,
): number | undefined => uiSchema['ui:options']?.maxNumberToSelect;

export const allowArbitraryValues = (
  uiSchema: WmCompleteUiSchema<CustomUrlPickerUiOptions>,
): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? false;

const useStyles = makeStyles(theme => ({
  ul: {
    maxHeight: '300px',
  },
  headerText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  truncated: {
    width: '100%',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    display: '-webkit-box',
    wordBreak: 'break-all',
  },
}));

const generateColumns = (fields: string[], options: Record<string, any>[]) => {
  const columns = fields.map(field => {
    const optionLengths = options.map(option => `${option[field]}`.length);
    const maxOptionLength = Math.max(...optionLengths);
    return fields.length === 1
      ? `minmax(${maxOptionLength * 10}px, 1fr)`
      : `${maxOptionLength * 10}px`;
  });
  const stringColumns = columns.join(' ');
  const gridColumns = `48px ${stringColumns}`;
  return gridColumns;
};

const TableHeader: React.FC<{
  fields?: string[];
  options?: Record<string, any>[];
}> = ({ children, fields = [], options = [] }) => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: generateColumns(fields, options),
          padding: '6px 16px',
          width: '100%',
        }}
      >
        <div />
        {fields.map(field => (
          <Typography style={{ padding: 0 }} className={classes.headerText}>
            {field}
          </Typography>
        ))}
      </div>

      {children}
    </>
  );
};

const TableRowOption: React.FC<{
  option: any;
  state: AutocompleteRenderOptionState;
  uiSchema: WmCompleteUiSchema<any>;
  fields?: string[];
  options?: Record<string, any>[];
}> = ({ option, state, fields = [], options = [] }) => {
  const classes = useStyles();
  const { selected } = state;

  return (
    <div
      style={{
        height: '6vh',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: generateColumns(fields, options),
        width: '100%',
      }}
    >
      <Checkbox checked={selected} />
      {fields
        .filter(key => Object.keys(option).includes(key))
        .map(key => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.truncated}>{option[key]}</div>
          </div>
        ))}
    </div>
  );
};

/**
 * This Picker enables users to select multiple values fetched from a microservice.
 * @param props
 * @returns
 */
export const CustomUrlPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: {
      title = 'Custom URL',
      description = 'A list of values fetched from a Microservice',
    },
    required,
    uiSchema,
    rawErrors,
    idSchema,
    formContext,
    name,
  } = props;

  const alertApi = useApi(customAlertApiRef);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<{}[]>([]);
  const [autocompleteValues, setAutocompleteValues] = useState<any>(
    formContext[name] || [],
  );
  const prevFormContext = usePrevious(formContext);
  const [validatedUiSchema, setUiSchema] = useState<
    WmCompleteUiSchema<CustomUrlPickerUiOptions> | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [enableSearch, setEnableSearch] = useState<boolean>(true);
  const [errors, setErrors] = useState<Map<string, string>>(new Map([]));
  const [templateParams, setTemplateParams] = useState<any>();
  const [microserviceConfig, setMicroserviceConfig] =
    useState<MicroserviceConfig>();
  const config = useApi(configApiRef);
  const [limitReached, setLimitReached] = useState<boolean>(
    !!(
      maxNumberToSelect(uiSchema) &&
      autocompleteValues.length >= maxNumberToSelect(uiSchema)!
    ),
  );
  const [offset, setOffset] = useState<number>(0);
  const theme = useTheme();
  const classes = useStyles();
  const color = errors.size
    ? {
        color: theme.palette.error.main,
      }
    : {};

  const [position, setPosition] = useState(0);
  const listElem = useRef<HTMLDivElement>();
  const mounted = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!mounted.current) return;
    else if (position && listElem.current) {
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    }
  }, [position]);

  useEffect(() => {
    try {
      const validatedSchema = validateUiSchema(uiSchema);
      setUiSchema(validatedSchema);
      setMicroserviceConfig(
        parseConfiguration(
          config,
          validatedSchema,
          CustomUrlOperations.RETRIEVAL,
        ),
      );
    } catch (err) {
      alertApi.post({
        error: new CustomError(
          'Error: CustomUrlPicker Validation',
          err.message,
        ),
        severity: 'error',
      });
    }
  }, [uiSchema, alertApi, config]);

  /**
   * This useEffect method is used to parse dynamic parameters and to set the errors list in case of missing resolution.
   * This is used separately from the others to make the CustomUrlPicker changes on the basis of the value of dependent fields.
   */
  useEffect(() => {
    if (validatedUiSchema)
      setTemplateParams(
        parseDynamicFields(
          formContext,
          prevFormContext,
          val => {
            setAutocompleteValues(val);
            onChange(undefined);
            setLimitReached(false);
            setErrors(prevErrors => {
              prevErrors.delete('limit_error');
              return prevErrors;
            });
          },
          setEnableSearch,
          setOffset,
          setErrors,
          validatedUiSchema['ui:apiSpec']!.retrieval.params,
        ),
      );
  }, [
    onChange,
    formContext,
    prevFormContext,
    setAutocompleteValues,
    setErrors,
    validatedUiSchema,
  ]);

  /**
   * This useEffect hook implements the search mechanism by using debounce. When a user types some string
   * in the picker, useEffect is triggered and after a delay it performs a request to the microservice.
   */
  useEffect(() => {
    if (validatedUiSchema && enableSearch && microserviceConfig) {
      setLoading(true);
      const microserviceValues = setTimeout(
        () =>
          fetchMicroserviceValues(
            microserviceConfig,
            setErrors,
            offset,
            templateParams,
            searchInput,
          )
            .then(options => {
              setLoading(false);
              setEnableSearch(false);
              setFilteredOptions(options);
            })
            .catch(err => {
              setLoading(false);
              setEnableSearch(false);
              alertApi.post({
                error: new CustomError(
                  'Error: CustomUrlPicker HTTP request',
                  err.message,
                ),
                severity: 'error',
              });
            }),
        1500,
      );
      return () => clearTimeout(microserviceValues);
    }
    return undefined;
  }, [
    enableSearch,
    validatedUiSchema,
    templateParams,
    searchInput,
    alertApi,
    microserviceConfig,
    offset,
  ]);

  const checkLimit = useCallback(
    option =>
      limitReached &&
      !autocompleteValues.map((val: any) => val.id).includes(option.id),
    [limitReached, autocompleteValues],
  );

  const selectFieldsToSave = useCallback(
    (val: Record<string, any> | string) =>
      typeof val === 'object'
        ? Object.keys(val)
            .filter(key =>
              (validatedUiSchema?.['ui:fieldsToSave'] ??
                validatedUiSchema?.['ui:displayFields'])!.includes(key),
            )
            .reduce((acc, curr) => ({ ...acc, [curr]: val[curr] }), {})
        : val,
    [validatedUiSchema],
  );

  const onSelect = useCallback(
    (_: any, val: any) => {
      if (
        required &&
        Array.isArray(val) &&
        !val.length &&
        prevFormContext &&
        prevFormContext[name] !== val
      ) {
        setAutocompleteValues([]);
        onChange(undefined);
        setLimitReached(false);
        setErrors(prevErrors => {
          prevErrors.delete('limit_error');
          return prevErrors;
        });
      } else {
        setAutocompleteValues(val);
        onChange(val.map(selectFieldsToSave));
        const limit = maxNumberToSelect(uiSchema);
        if (limit && val.length >= limit) {
          /**
           * This is a workaround adopted for arbitrary values, we don't have so much control on those,
           * so we decided to remove the last value if the user reach the maximum number of values.
           */
          if (val.length > limit) {
            const shiftedVal = val.slice(0, val.length - 1);
            setAutocompleteValues(shiftedVal);
            onChange(shiftedVal.map(selectFieldsToSave));
          }
          setLimitReached(true);
          setErrors(prevErrors =>
            prevErrors.set(
              'limit_error',
              'You have reached the maximum number of items to select. Just delete and reselect one or more values, if you want to replace them.',
            ),
          );
        } else {
          setErrors(prevErrors => {
            prevErrors.delete('limit_error');
            return prevErrors;
          });
          setLimitReached(false);
        }
      }
    },
    [
      uiSchema,
      required,
      prevFormContext,
      name,
      onChange,
      setAutocompleteValues,
      selectFieldsToSave,
    ],
  );

  return (
    <FormControl required={required} error={rawErrors?.length > 0}>
      <WbAutocomplete
        size="small"
        style={{ display: 'flex', width: '100%' }}
        id={idSchema?.$id}
        options={filteredOptions || []}
        multiple
        getOptionDisabled={checkLimit}
        loading={loading}
        value={autocompleteValues}
        onChange={onSelect}
        ref={mounted}
        classes={{
          listbox: classes.ul,
        }}
        popupIcon={<ExpandMoreIcon />}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            const scrollPosition =
              listboxNode.scrollTop + listboxNode.clientHeight;

            const currentScrollPosition = Math.round(
              listboxNode.scrollTop + listboxNode.clientHeight,
            );
            const endPosition = Math.round(listboxNode.scrollHeight);
            /**
             * This part here takes in consideration that the infinite scrolling mechanism can work differently
             * depending on the screen resolution. This means that the scrollbar position can be considered at "the end"
             * of the list box also if there are 2 pixels away from the end.
             */
            if (
              currentScrollPosition - endPosition <= 2 &&
              currentScrollPosition - endPosition >= 0 &&
              validatedUiSchema &&
              microserviceConfig &&
              !fetching
            ) {
              setFetching(true);
              fetchMicroserviceValues(
                microserviceConfig,
                setErrors,
                offset + 1,
                templateParams,
                searchInput,
              )
                .then(results => {
                  if (results.length) {
                    setFilteredOptions([...filteredOptions, ...results]);
                    setOffset(prevOffset => prevOffset + 1);
                    setPosition(scrollPosition);
                  }
                })
                .catch(err =>
                  alertApi.post({
                    error: new CustomError(
                      'Error: CustomUrlPicker HTTP request',
                      err.message,
                    ),
                    severity: 'error',
                  }),
                )
                .finally(() => setFetching(false));
            }
          },
          ref: listElem,
          style: {
            overflowX: 'auto',
          },
        }}
        inputValue={searchInput}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') {
            setFilteredOptions([]);
            setOffset(0);
            setEnableSearch(true);
            setSearchInput(newInputValue);
          }
        }}
        disableCloseOnSelect
        freeSolo={allowArbitraryValues(uiSchema)}
        getOptionLabel={(option: any) =>
          Object.keys(option)
            .map(key => `${key}: ${option[key]}`)
            .join(' ')
        }
        getOptionSelected={(option: any, val: any) => {
          if (typeof option === 'object' && typeof val === 'object') {
            return option.id === val.id;
          }

          return option === val;
        }}
        groupBy={() => ''}
        renderGroup={params => (
          <TableHeader
            fields={validatedUiSchema?.['ui:displayFields']}
            options={filteredOptions}
          >
            {params.children}
          </TableHeader>
        )}
        renderOption={(option: any, state) => (
          <TableRowOption
            fields={validatedUiSchema?.['ui:displayFields']}
            state={state}
            uiSchema={uiSchema}
            option={option}
            options={filteredOptions}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option: any, index) => (
            <WbChip
              style={{ maxWidth: '250px', textOverflow: 'ellipses' }}
              label={
                typeof option === 'object'
                  ? `${option[uiSchema['ui:options'].selectedField] ?? option}`
                  : option
              }
              {...getTagProps({ index })}
            />
          ))
        }
        label={title}
        helperText={
          errors.size
            ? renderMessage(
                Array.from(errors.values())
                  .map(val => val)
                  .join('\n'),
              )
            : description
        }
        FormHelperTextProps={{
          style: { ...color },
        }}
        required={required}
        {...uiSchema.customProps}
      />
    </FormControl>
  );
};
