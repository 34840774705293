import React from 'react';
import { processSelectValue } from '@rjsf/utils';
import { WbMultiSelect, WbSelect } from '@agilelab/plugin-wb-platform';
import { WidgetProps } from '@rjsf/core';

export const SelectWidget = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  onChange,
  onBlur,
  onFocus,
  uiSchema,
  rawErrors = [],
}: WidgetProps) => {
  const { enumOptions } = options;

  const emptyValue = multiple ? [] : '';

  const _onChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => onChange(processSelectValue(schema, event.target.value, options));
  const _onBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, processSelectValue(schema, event.target.value, options));
  const _onFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, processSelectValue(schema, event.target.value, options));

  if (multiple) {
    return (
      <WbMultiSelect
        id={id}
        name={id}
        label={label || schema.title}
        value={typeof value === 'undefined' ? emptyValue : value}
        required={required}
        disabled={disabled || readonly}
        error={rawErrors.length > 0}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        options={
          Array.isArray(enumOptions)
            ? enumOptions.map(option => {
                return option.label;
              })
            : []
        }
        {...uiSchema?.customProps}
      />
    );
  }

  return (
    <WbSelect
      id={id}
      name={id}
      label={label || schema.title}
      value={typeof value === 'undefined' ? emptyValue : value}
      required={required}
      disabled={disabled || readonly}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      options={
        Array.isArray(enumOptions)
          ? enumOptions.map(option => {
              return option.label;
            })
          : []
      }
      {...uiSchema?.customProps}
    />
  );
};
