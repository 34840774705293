import { DiscoveryApi } from '@backstage/core-plugin-api';
import fetch from 'cross-fetch';

/**
 * Wrapper that calls exposed ACL POST and GET routes from marketplace.
 */

export interface AccessControlListApi {
  readonly discoveryApi: DiscoveryApi;

  getACLs(id: string, token: string): Promise<any>;

  addACLs(objects: any, token: string): Promise<any>;

  getDataProductAccess(
    idDataProduct: number,
    requester: string,
    token: string,
  ): Promise<any>;
}

export class AccessControlListClient implements AccessControlListApi {
  readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  async getDataProductAccess(
    idDataProduct: number,
    requester: string,
    token: string,
  ): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await fetch(
      `${baseUrl}/dataProductAccess/${idDataProduct}?${new URLSearchParams({
        requester: requester,
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.json();
  }

  async addACLs(objects: any, token: string): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await fetch(`${baseUrl}/ACL`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ objects: objects }),
    });

    return response.json();
  }

  async getACLs(id: string, token: string): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await fetch(`${baseUrl}/ACL/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.json();
  }
}
