import {
  Entity,
  entityEnvelopeSchemaValidator,
} from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/WitboostComponent.v1alpha1.schema.json';

export interface WitboostComponent extends Entity {
  apiVersion:
    | 'backstage.io/v1alpha1'
    | 'backstage.io/v1beta1'
    | 'witboost.com/v1';
  kind: 'Component';
  spec: {
    type: string;
    lifecycle: string;
    owner: string;
    subcomponentOf?: string;
    providesApis?: string[];
    consumesApis?: string[];
    dependsOn?: string[];
    system?: string;
    mesh: {
      name: string;
      version: string;
      useCaseTemplateId: string;
      infrastructureTemplateId: string;
    } & Record<string, any>;
  } & Record<string, any>;
  witboost?: {
    parameters?: Record<string, any>;
  };
}

export const applyWitboostComponent =
  entityEnvelopeSchemaValidator<WitboostComponent>(schema);

export const witboostComponentV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
