import {
  AccessAuthorizationStatus,
  AccessResponseNotification,
} from '@agilelab/plugin-wb-notification-common';
import { Chip, Typography, useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useNotificationStyle } from './NotificationsPageStyle';
import logo from './witboost_logo.svg';
import {
  NotificationAccordion,
  NotificationAccordionDetails,
  NotificationAccordionSummary,
} from './NotificationAccordion';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { Avatar, Link } from '@backstage/core-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { green, red } from '@material-ui/core/colors';
import { MarkAsReadButton } from './MarkAsReadButton';
import { parseEntityRef } from '@backstage/catalog-model';

const buildNotificationTitle = (
  status: 'SUCCESS' | 'FAILURE',
  isRequester: boolean,
) => {
  if (status === 'FAILURE') {
    return isRequester
      ? 'The access grant that you requested could not be completed due to an error. Please try again or contact the data product owner.'
      : 'The access that you granted could not be completed due to an error. Please try again or contact the platform team.';
  }

  return isRequester
    ? 'The access grant that you requested is now effective!'
    : 'The access that you granted is now effective!';
};

const buildLinkToUser = (userOrGroupRef: string): string => {
  const parsedRef = parseEntityRef(userOrGroupRef);

  return `/catalog/default/${parsedRef.kind}/${parsedRef.name}`;
};

const parseName = (userOrGroupRef: string): string => {
  return parseEntityRef(userOrGroupRef).name;
};

export const AccessResponseNotificationAccordion = (props: {
  notification: AccessResponseNotification;
  onAccordionExpansionsChange: (value: string | boolean) => void;
  expandedAccordion: string | boolean;
}) => {
  const { notification, onAccordionExpansionsChange, expandedAccordion } =
    props;
  const classes = useNotificationStyle();
  const theme = useTheme();

  const onToggle = useCallback(
    (_event: object, expanded: boolean) => {
      onAccordionExpansionsChange(
        expanded ? notification.id.toString() : false,
      );
    },
    [notification.id, onAccordionExpansionsChange],
  );

  return (
    <NotificationAccordion
      onChange={onToggle}
      expanded={expandedAccordion === notification.id.toString()}
      style={{
        backgroundColor: !!notification.read_at
          ? 'transparent'
          : theme.palette.common.white,
      }}
    >
      <NotificationAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summaryHeader}>
          <Typography variant="body2" className={classes.notificationTime}>
            {DateTime.fromISO(notification.created_at).toFormat(
              'dd/MM/yyyy HH:mm',
            )}
          </Typography>
          <MarkAsReadButton notification={notification} />
        </div>
        <div className={classes.summaryDescription}>
          {!notification.notification_request.status && (
            <ErrorOutlineOutlinedIcon color="primary" />
          )}
          {notification.notification_request.status === 'SUCCESS' && (
            <CheckCircleOutlineOutlinedIcon style={{ color: green[500] }} />
          )}
          {notification.notification_request.status === 'FAILURE' && (
            <NotInterestedIcon style={{ color: red[500] }} />
          )}
          <Avatar
            displayName={notification.sender || 'witboost'}
            picture={logo}
            customStyles={{
              width: theme.spacing(7),
              height: theme.spacing(7),
            }}
          />
          <div className={classes.summaryDescriptionText}>
            <span>
              {buildNotificationTitle(
                notification.notification_request.status,
                notification.notification_request.is_requester,
              )}
            </span>
            <span>
              <b>data product:</b>{' '}
              <Link
                to={`/marketplace/products-catalog/${notification.notification_request.id_dataproduct_instance}`}
              >
                <Typography variant="body2" color="primary" component="b">
                  {notification.notification_request.dataproduct.display_name}
                </Typography>
              </Link>{' '}
            </span>
            {notification.notification_request.is_requester && (
              <span>
                {' '}
                <b>owner:</b>{' '}
                <Link
                  to={buildLinkToUser(
                    notification.notification_request.dataproduct
                      .data_product_owner,
                  )}
                >
                  <Typography variant="body2" color="primary" component="b">
                    {parseName(
                      notification.notification_request.dataproduct
                        .data_product_owner,
                    )}
                  </Typography>
                </Link>{' '}
              </span>
            )}
            <span>
              <b>domain:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.domain}
              </Typography>
            </span>
            <span>
              <b>environment:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.environment}
              </Typography>
            </span>
            <span>
              <b>version:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.version}
              </Typography>
            </span>
          </div>
        </div>
      </NotificationAccordionSummary>
      <NotificationAccordionDetails>
        <div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Output port
            </Typography>
            <Typography variant="body1">
              <b>Name:</b> {notification.notification_request.outputport.name}
            </Typography>
            <Typography variant="body1">
              <b>Type:</b>{' '}
              {notification.notification_request.outputport.output_port_type}
            </Typography>
          </div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Granted to
            </Typography>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
              }}
            >
              {notification.notification_request.identities &&
                notification.notification_request.identities.map(i => (
                  <Chip title={i} key={i} label={i} size="small" />
                ))}
            </div>
          </div>
          {notification.notification_request.is_requester ? (
            <></>
          ) : (
            <div className={classes.contentElementHeader}>
              <Typography
                variant="h6"
                color="primary"
                style={{ marginBottom: theme.spacing(1) }}
              >
                Requester
              </Typography>
              <Typography variant="body1">
                <b>Name:</b>{' '}
                {notification.notification_request.requester_display_name}{' '}
                <Chip
                  title={notification.notification_request.requester}
                  key={notification.notification_request.requester}
                  label={notification.notification_request.requester}
                  size="small"
                />
              </Typography>
              <Typography variant="body1">
                <b>Motivation:</b>{' '}
                {notification.notification_request.motivation}
              </Typography>
            </div>
          )}
          {notification.notification_response?.status ===
            AccessAuthorizationStatus.ACCEPT && (
            <Typography style={{ color: green[500] }}>
              Request accepted on:{' '}
              {DateTime.fromISO(
                notification.notification_response?.timestamp,
              ).toFormat('dd/MM/yyyy HH:mm')}
            </Typography>
          )}
          {notification.notification_response?.status ===
            AccessAuthorizationStatus.REJECT && (
            <Typography style={{ color: red[500] }}>
              Access rejected :{' '}
              {DateTime.fromISO(
                notification.notification_response?.timestamp,
              ).toFormat('dd/MM/yyyy HH:mm')}
            </Typography>
          )}
        </div>
      </NotificationAccordionDetails>
    </NotificationAccordion>
  );
};
