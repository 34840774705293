import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TablePagination,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { Tag, WbTag } from './WbTag';
import CloseIcon from '@material-ui/icons/Close';

interface WbTagsModalProps {
  open: boolean;
  onClose: () => void;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  tags: Array<Tag>;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
}

export const WbTagsModal: React.FC<WbTagsModalProps> = ({
  maxWidth = 'sm',
  open,
  onClose,
  tags,
}) => {
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const handleChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 1,
          backgroundColor: theme.palette.background.default,
        },
      }}
      open={open}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>
        Tags
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 15 }}>
          {tags.slice(page * 15, (page + 1) * 15).map((tag, index) => (
            <WbTag tag={tag} style={{ maxWidth: '100%' }} key={index} />
          ))}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '8px 16px' }}>
        <TablePagination
          component="div"
          count={tags.length}
          page={page}
          onPageChange={(e, v) => {
            handleChange(e, v);
          }}
          rowsPerPage={15}
          onRowsPerPageChange={() => {}}
          rowsPerPageOptions={[]}
        />
      </DialogActions>
    </Dialog>
  );
};

WbTagsModal.defaultProps = {
  maxWidth: 'lg',
};
