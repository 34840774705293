import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn';
import { panelCatalogApiRef } from '../../api';
import { Deploy } from './types';
import {
  generateURNByKind,
  ProvisioningPlan,
  ReleaseEntity,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';
import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { useSelectorsContext } from '@agilelab/plugin-wb-platform';
import {
  useQuery,
  gql,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';

type LastDeployedVersionQuery = {
  coordinator_execution_plan: Array<{
    version: string;
    status: string;
    operation: string;
  }>;
};

type ControlPanelContextValues = {
  environment: string;
  selectedRelease: ReleaseEntity | null;
  setSelectedRelease: (value: ReleaseEntity | null) => void;
  releases: ReleaseEntity[] | null;
  fetchReleaseState: {
    error?: Error;
    loading: boolean;
    value?: ReleaseEntity[];
  };
  addRelease: (release: ReleaseEntity) => void;
  releaseDeployingId: string;
  setReleaseDeployingId: (flag: string) => void;
  releaseUndeployingId: string;
  setReleaseUndeployingId: (flag: string) => void;
  entity: WitboostVersionedEntity;
  selectedStep: string;
  setSelectedStep: (value: string) => void;
  fetchPreviewDescriptorState: AsyncState<string | null>;
  fetchPreviewDescriptor: () => Promise<string | null>;
  selectedDeploy: Deploy | null;
  setSelectedDeploy: (value: Deploy | null) => void;
  isLogsDrawerOpen: boolean;
  setIsLogsDrawerOpen: (value: boolean) => void;
  fetchReleases: () => Promise<ReleaseEntity[]>;

  data: LastDeployedVersionQuery | undefined;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<LastDeployedVersionQuery>>;
};

export const ControlPanelContext = createContext<ControlPanelContextValues>(
  {} as unknown as ControlPanelContextValues,
);

type ControlPanelProps = {
  children: JSX.Element[] | JSX.Element;
  entity: WitboostVersionedEntity;
};

export function useControlPanel() {
  return useContext(ControlPanelContext);
}

export const mapToDeploys = (
  provisioningPlans: ProvisioningPlan[],
  entity: Entity,
): Deploy[] => {
  if (!provisioningPlans) return [];

  return provisioningPlans.map(pp => {
    return {
      id: pp.dag.id,
      idRelease: `${entity.metadata.name}.${
        pp.dag.version.split('-')[0].split('.')[1]
      }`,
      version: pp.dag.version,
      status: pp.dag.status,
      action: pp.dag.action,
      deployDate: new Date(pp.dag.startTime).toISOString(),
      deployEndDate: new Date(pp.dag.stopTime).toISOString(),
      steps: pp.dag.dependsOnTasks,
    };
  });
};

export function ControlPanelProvider({
  children,
  entity,
}: ControlPanelProps): JSX.Element {
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const { environment } = useSelectorsContext();
  const [releases, setReleases] = useState<ReleaseEntity[] | null>(null);
  const [selectedRelease, setSelectedRelease] = useState<ReleaseEntity | null>(
    null,
  );
  const [releaseDeployingId, setReleaseDeployingId] = useState<string>('');
  const [releaseUndeployingId, setReleaseUndeployingId] = useState<string>('');
  const [selectedStep, setSelectedStep] = useState<string>('');
  const [selectedDeploy, setSelectedDeploy] = useState<Deploy | null>(null);
  const [isLogsDrawerOpen, setIsLogsDrawerOpen] = useState<boolean>(false);

  const { refetch, data } = useQuery<LastDeployedVersionQuery>(
    gql`
      query GetLastDeployedVersion($urn: String!, $env: String!) {
        coordinator_execution_plan(
          where: {
            environment: { _eq: $env }
            deployment_unit_id: { _eq: $urn }
            _and: { operation: { _in: ["Provision", "Unprovision"] } }
          }
          limit: 1
          order_by: { update_time: desc }
        ) {
          version
          status
          operation
        }
      }
    `,
    {
      variables: {
        urn: generateURNByKind(entity.metadata.name, entity.kind),
        env: environment.name,
      },
      fetchPolicy: 'no-cache',
    },
  );

  const addRelease = useCallback(
    (release: ReleaseEntity) => {
      if (releases) {
        const index = releases.findIndex(
          r => r.metadata.name === release.metadata.name,
        );
        if (index > -1) {
          releases[index] = release;
          setReleases([...releases]);
        } else {
          setReleases([release, ...releases]);
        }
      }
    },
    [releases],
  );

  const [fetchReleaseState, fetchReleases] = useAsyncFn(async () => {
    if (!entity) {
      return [];
    }

    return panelCatalogApi.fetchReleases(entity.metadata.name, {
      token: (await identityApi.getCredentials()).token,
    });
  }, [entity]);

  useEffect(() => {
    if (environment && entity) {
      fetchReleases();
    }
  }, [entity, environment, fetchReleases]);

  useEffect(() => {
    if (fetchReleaseState.value) {
      setReleases(fetchReleaseState.value);
    }
  }, [fetchReleaseState]);

  useEffect(() => {
    if (releases) {
      setSelectedRelease(null); // start from HEAD
    }
  }, [releases]);

  const alertApi = useApi(customAlertApiRef);
  const [fetchPreviewDescriptorState, fetchPreviewDescriptor] =
    useAsyncFn(async () => {
      try {
        if (environment) {
          if (selectedRelease) {
            return await panelCatalogApi.fetchReleasePreviewDescriptor(
              selectedRelease?.metadata.name,
              environment.name,
              await identityApi.getCredentials(),
            );
          }
          if (entity) {
            return await panelCatalogApi.fetchPreviewDescriptor(
              entity.metadata.name,
              environment.name,
              await identityApi.getCredentials(),
            );
          }
        }
      } catch (error) {
        alertApi.post({ error, severity: 'error' });
        return null;
      }
      return null;
    }, [entity, environment, selectedRelease]);

  const values: ControlPanelContextValues = useMemo(
    () => ({
      environment: environment.name,
      releases,
      addRelease,
      fetchReleaseState,
      releaseDeployingId,
      setReleaseDeployingId,
      releaseUndeployingId,
      setReleaseUndeployingId,
      entity,
      setSelectedRelease,
      selectedRelease,
      selectedStep,
      setSelectedStep,
      fetchPreviewDescriptor,
      fetchPreviewDescriptorState,
      selectedDeploy,
      setSelectedDeploy,
      isLogsDrawerOpen,
      setIsLogsDrawerOpen,
      fetchReleases,
      data,
      refetch,
    }),
    [
      environment,
      releases,
      addRelease,
      fetchReleaseState,
      releaseDeployingId,
      releaseUndeployingId,
      entity,
      selectedRelease,
      selectedStep,
      fetchPreviewDescriptor,
      fetchPreviewDescriptorState,
      selectedDeploy,
      setSelectedDeploy,
      isLogsDrawerOpen,
      setIsLogsDrawerOpen,
      fetchReleases,
      data,
      refetch,
    ],
  );

  return (
    <ControlPanelContext.Provider value={values}>
      {children}
    </ControlPanelContext.Provider>
  );
}
