import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { deepPurple, green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: theme.spacing(-1.5),
    marginTop: theme.spacing(-1.5),
  },
  purpleButton: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    '&:hover': {
      backgroundColor: deepPurple[700],
    },
  },
  purpleProgress: {
    color: deepPurple[500],
  },
  redButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  redProgress: {
    color: red[500],
  },
  greenButton: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  greenProgress: {
    color: green[500],
  },
}));

export type ProgressButtonProps = ButtonProps & {
  /**
   * If `true', shows an infinite progress bar and disables the button.
   */
  inProgress: boolean;
  meshColor?: string;
};

function ProgressButtonComponent(
  props: ProgressButtonProps,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  const classes = useStyles();
  const {
    inProgress,
    disabled,
    children,
    variant,
    className,
    meshColor,
    ...otherProps
  } = props;

  return (
    <div className={clsx(classes.wrapper, className)} ref={ref}>
      <Button
        disabled={disabled || inProgress}
        variant={variant}
        {...otherProps}
        fullWidth
        className={clsx({
          [classes.purpleButton]: meshColor === 'purple',
          [classes.redButton]: meshColor === 'red',
          [classes.greenButton]: meshColor === 'green',
        })}
      >
        {children}
      </Button>
      {inProgress && (
        <CircularProgress
          color="primary"
          className={clsx(classes.progress, {
            [classes.purpleProgress]: meshColor === 'purple',
            [classes.redProgress]: meshColor === 'red',
            [classes.greenProgress]: meshColor === 'green',
          })}
          size={24}
        />
      )}
    </div>
  );
}

export const ProgressButton = React.memo(
  React.forwardRef(ProgressButtonComponent),
) as typeof ProgressButtonComponent;
