import { parseEntityRef } from '@backstage/catalog-model';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';

export function useEntitiesOwnershipFilters() {
  const identityApi = useApi(identityApiRef);

  const getOwnershipFilters = async (kinds: string[]) => {
    const identity = await identityApi.getBackstageIdentity();

    const entityNames = identity.ownershipEntityRefs.map(
      ref => parseEntityRef(ref).name,
    );

    const filters = kinds.reduce(
      (
        filtersArray: Record<string, string | symbol | (string | symbol)[]>[],
        currentKind,
      ) => {
        switch (currentKind) {
          case 'User':
            return [
              ...filtersArray,
              {
                'spec.memberOf': identity.ownershipEntityRefs,
                kind: 'User',
              },
            ];
          case 'Group':
            return [
              ...filtersArray,
              { 'metadata.name': entityNames, kind: 'Group' },
            ];
          default:
            return [...filtersArray, { kind: currentKind }];
        }
      },
      [],
    );

    return filters;
  };

  return { getOwnershipFilters };
}
