import { Box, ChipProps, useTheme } from '@material-ui/core';
import React from 'react';
import { Tag, WbTag } from './WbTag';

type WbTagsArrayProps = ChipProps & {
  tags: Array<Tag>;
  tooltip?: boolean;
};

export const WbTagsArray: React.FC<WbTagsArrayProps> = (
  props: WbTagsArrayProps,
) => {
  const { tags, tooltip = false } = props;
  const theme = useTheme();

  return (
    <Box
      style={{
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
        maxWidth: '100%',
      }}
    >
      {tags.map(t => (
        <WbTag key={t.tagFQN} tag={t} tooltip={tooltip} />
      ))}
    </Box>
  );
};
