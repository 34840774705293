import React from 'react';
import { useTheme, Paper, CircularProgress, Box } from '@material-ui/core';
import { WbEditor } from '@agilelab/plugin-wb-platform';

export function PreviewDescriptorBox(props: {
  code: string | null | undefined;
  name: string;
  loading: boolean;
  showLineNumbers?: boolean;
  highlightedNumbers?: number[];
}) {
  const { code, loading } = props;
  const theme = useTheme();

  return (
    <Paper
      style={{
        padding: theme.spacing(2),
        height: '100%',
        overflow: 'hidden',
        flex: '1 1 0',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      {loading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && code && (
        <WbEditor
          value={code}
          options={{ readOnly: true }}
          wrapperProps={{
            padding: '0',
            margin: '0',
            height: '100%',
            flex: '1 1 0',
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      )}
    </Paper>
  );
}
