import './DateRangeFilter.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import React, { useState } from 'react';
import { Box, Popover, Typography, useTheme } from '@material-ui/core';
import { FilterInput } from '../FilterInput';
import { DateRangePicker, Range } from 'react-date-range';
import { FiltersButton } from '../FilterButtons';
import { DateRange, DateRangeTableFilter } from '../types';

const formatRange = (range: DateRange) => {
  if (!range.startDate || !range.endDate) return '';

  const from = range.startDate.toLocaleDateString();
  const to = range.endDate.toLocaleDateString();

  if (from === to) return from;

  return `${from} - ${to}`;
};

const DateRangeFilterValue = ({ field, value }: DateRangeTableFilter) => {
  const ellipsis: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Typography style={ellipsis} variant="body2">
      {field}
      {value && value.startDate && value.endDate && (
        <>
          <Typography component="span" variant="body2">
            :
          </Typography>
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            variant="body2"
            component="span"
          >
            {formatRange(value)}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const DateRangeFilter = (filter: DateRangeTableFilter) => {
  const theme = useTheme();

  // WORKAROUND TO FIX REACT-DATE-RANGE EMPTY SELECTION BUG
  const defaultValue = [
    {
      startDate: filter.value?.startDate || null,
      endDate: filter.value?.endDate || new Date(''),
      key: 'selection',
    },
  ];

  const [value, setValue] = useState(defaultValue);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(defaultValue);
    }, 500);
  };

  React.useEffect(() => {
    setValue([
      {
        startDate: filter.value?.startDate || null,
        endDate: filter.value?.endDate || new Date(''),
        key: 'selection',
      },
    ]);
  }, [filter.value]);

  return (
    <Box>
      <FilterInput
        onClick={handleClick}
        onClear={() => {
          filter.onChange(undefined);
          setValue(defaultValue);
        }}
        isOpened={open}
        selected={Boolean(
          filter.value && filter.value.startDate && filter.value.endDate,
        )}
        hideClear={filter.hideClear}
      >
        <DateRangeFilterValue {...filter} />
      </FilterInput>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box style={{ background: 'white' }}>
          <DateRangePicker
            inputRanges={[]}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            color={theme.palette.primary.main}
            ranges={value as Range[]}
            onChange={rangesByKey => {
              const newValue = {
                startDate: rangesByKey.selection.startDate || null,
                endDate: rangesByKey.selection.endDate || new Date(''),
                key: 'selection',
              };
              setValue([newValue]);
            }}
            rangeColors={[theme.palette.primary.main]}
          />

          <FiltersButton
            containerStyle={{
              justifyContent: 'flex-end',
            }}
            cancel={{
              onClick: handleClose,
              style: { flex: 'none', width: '120px' },
            }}
            apply={{
              onClick: () => {
                filter.onChange({
                  startDate: value[0].startDate || undefined,
                  endDate: value[0].endDate,
                });
                setAnchorEl(null);
              },
              disabled: !value[0].startDate,
              style: { flex: 'none', width: '120px' },
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
