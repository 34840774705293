import { z } from 'zod';
import { compactStringRef } from '@agilelab/plugin-wb-builder-common';

export const ACLObjectZod = z.object({
  requester: z.preprocess(
    value => compactStringRef(value as string),
    z.string().regex(new RegExp('^user:.+$')),
  ),
  requester_display_name: z.string(),
  refs: z.preprocess(
    value => compactStringRef(value as string),
    z.string().regex(new RegExp('^(user|group):.+$')),
  ),
  request_id: z.string(),
  id_output_port: z.preprocess(Number, z.number()),
  resource_name: z.string(),
  result: z.any().optional(),
  status: z.any(),
});

export const ACLPostRequestZod = z.object({
  objects: ACLObjectZod.array(),
});

export type ACLObject = z.infer<typeof ACLObjectZod>;
export type ACLPostRequest = z.infer<typeof ACLPostRequestZod>;
