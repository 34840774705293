import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Status } from '@agilelab/plugin-wb-governance-common';
import { DeprecatedIcon } from './GovernanceRegistryPolicies/Icons/Deprecated';
import { DisabledIcon } from './GovernanceRegistryPolicies/Icons/Disabled';
import { DraftIcon } from './GovernanceRegistryPolicies/Icons/Draft';
import { EnabledIcon } from './GovernanceRegistryPolicies/Icons/Enabled';
import { GraceIcon } from './GovernanceRegistryPolicies/Icons/Grace';

interface Props {
  status: Status;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface StatusProps {
  label: string;
  icon: React.ReactElement;
}

const StatusElement: React.FC<StatusProps> = ({ label, icon }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {icon}
      <Typography style={{ fontSize: 12 }}>{label}</Typography>
    </Box>
  );
};

export const statusInfo: Record<
  Status,
  { icon: React.ReactElement; label: string; color: string }
> = {
  [Status.Draft]: { icon: <DraftIcon />, label: 'Draft', color: '#00C853' },
  [Status.Grace]: { icon: <GraceIcon />, label: 'Grace', color: '#00C853' },
  [Status.Enabled]: {
    icon: <EnabledIcon />,
    label: 'Enabled',
    color: '#00C853',
  },
  [Status.Disabled]: {
    icon: <DisabledIcon />,
    label: 'Disabled',
    color: '#00C853',
  },
  [Status.Deprecated]: {
    icon: <DeprecatedIcon />,
    label: 'Deprecated',
    color: '#00C853',
  },
  [Status.Deleted]: {
    icon: <DeprecatedIcon />,
    label: 'Deleted',
    color: '#00C853',
  },
  [Status.DisabledGrace]: {
    icon: <DisabledIcon />,
    label: 'Disabled',
    color: '#00C853',
  },
};

export const GovernanceRegistryStatus: React.FC<Props> = ({ status }) => {
  return (
    <StatusElement
      icon={statusInfo[status].icon}
      label={statusInfo[status].label}
    />
  );
};
