import { z } from 'zod';

/**
 * Container header for all witboost user permissions
 * It has the form of:
 *
 * {
 *  "catalog.entity.read": [
 *    "urn:dmb:dmn:finance",
 *    "urn:dmb:dmn:organization",
 *    "urn:dmb:dmn:marketing"
 *  ],
 *  "catalog.entity.create": [
 *    "urn:dmb:dmn:finance",
 *    "urn:dmb:dmn:organization",
 *    "urn:dmb:dmn:marketing"
 *  ],
 * }
 *
 *
 */
export type MeshPermissionsHeader = {
  permissions: PermissionsMap;
};

export type PermissionsMap = {
  [permissionId: string]: PermissionScope[];
};

export const PermissionScopeSchema = z.string().startsWith('urn:dmb:');

export type PermissionScope = z.infer<typeof PermissionScopeSchema>;

export class UserPermissionsMap {
  constructor(private readonly map: PermissionsMap) {}

  hasPermission(permissionId: string): boolean {
    return this.map[permissionId] ? true : false;
  }

  getScopes(permissionId: string): string[] {
    return this.map[permissionId] ?? [];
  }

  getEntrySet(): PermissionsMap {
    return this.map;
  }
}
export interface RoleViewFilters {
  text?: string;
  limit?: number;
}

export interface PermissionViewFilters {
  text?: string;
  limit?: number;
}
