import {
  CoordinatorError,
  CustomError,
  CustomErrorName,
  GitRepositoryError,
} from './types';

/** Processes a json response to extract the correct message string, according to the different cases.
 *
 * @param json the original json response that needs to be parsed
 * @returns the parsed error message string
 */
export function getErrorMessage(json: any) {
  if (json.error) {
    if (typeof json.error === 'string') return json.error;

    if (json.error.message) return json.error.message;
  }

  return json.errors?.toString() || JSON.stringify(json);
}

/** Handles a response, throwing an error with the correct message string in case of a failure response.
 *
 * @param response the request response
 */
export async function handleFailedResponse(response: Response): Promise<void> {
  if (!response.ok) {
    let json = null;
    try {
      json = await response.json();
    } catch (error) {
      throw new CustomError(
        'Unable to parse the response received',
        error.message,
      );
    }
    const error = json?.error;

    switch (error?.name) {
      case CustomErrorName.CustomError: {
        throw new CustomError(error.message, error.errorMessage, error.options);
      }
      case CustomErrorName.CoordinatorError: {
        throw new CoordinatorError(
          { ...error, userMessage: error.message },
          error.options,
        );
      }
      case CustomErrorName.GitRepositoryError: {
        throw new GitRepositoryError(
          { ...error, title: error.message, userMessage: error.errorMessage },
          error.options,
        );
      }
      default: {
        throw new Error(getErrorMessage(json));
      }
    }
  }
}
