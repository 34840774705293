/*
 * This class is copied from Backstage 1.8.2.
 * We performed a lot of changes, be careful when upgrading.
 */

// Split the page in two components to export the Content

import { parseEntityRef } from '@backstage/catalog-model';
import {
  Content,
  ErrorPage,
  Header,
  Page,
  LogViewer,
  Progress,
} from '@backstage/core-components';
import { useRouteRef, useRouteRefParams } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import {
  Button,
  CircularProgress,
  Paper,
  StepButton,
  StepIconProps,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';
import qs from 'qs';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useInterval from 'react-use/lib/useInterval';
import {
  editorWizardRouteRef,
  rootRouteRef,
  scaffolderTaskRouteRef,
  selectedTemplateRouteRef,
} from '../../routes';
import { ScaffolderTaskStatus, ScaffolderTaskOutput } from '../../types';
import { useTaskEventStream } from '../hooks/useEventStream';
import { TaskErrors } from './TaskErrors';
import { TaskPageLinks } from './TaskPageLinks';

// typings are wrong for this library, so fallback to not parsing types.
const humanizeDuration = require('humanize-duration');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    labelWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    stepWrapper: {
      width: '100%',
    },
    logViewerWrapper: {
      height: '80vh',
      '& > div > div': {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1, 1, 0, 0),
      },
    },
  }),
);

type TaskStep = {
  id: string;
  name: string;
  status: ScaffolderTaskStatus;
  startedAt?: string;
  endedAt?: string;
};

const StepTimeTicker = ({ step }: { step: TaskStep }) => {
  const [time, setTime] = useState('');

  useInterval(() => {
    if (!step.startedAt) {
      setTime('');
      return;
    }

    const end = step.endedAt
      ? DateTime.fromISO(step.endedAt)
      : DateTime.local();

    const startedAt = DateTime.fromISO(step.startedAt);
    const formatted = Interval.fromDateTimes(startedAt, end)
      .toDuration()
      .valueOf();

    setTime(humanizeDuration(formatted, { round: true }));
  }, 1000);

  return <Typography variant="caption">{time}</Typography>;
};

const useStepIconStyles = makeStyles((theme: BackstageTheme) =>
  createStyles({
    root: {
      color: theme.palette.text.disabled,
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    completed: {
      color: theme.palette.status.ok,
    },
    error: {
      color: theme.palette.status.error,
    },
  }),
);

function TaskStepIconComponent(props: StepIconProps) {
  const classes = useStepIconStyles();
  const { active, completed, error } = props;

  const getMiddle = () => {
    if (active) {
      return <CircularProgress size="24px" />;
    }
    if (completed) {
      return <Check />;
    }
    if (error) {
      return <Cancel />;
    }
    return <FiberManualRecordIcon />;
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.completed]: completed,
        [classes.error]: error,
      })}
    >
      {getMiddle()}
    </div>
  );
}

export const TaskStatusStepper = memo(
  (props: {
    steps: TaskStep[];
    currentStepId: string | undefined;
    onUserStepChange: (id: string) => void;
    classes?: {
      root?: string;
    };
  }) => {
    const { steps, currentStepId, onUserStepChange } = props;
    const classes = useStyles(props);

    return (
      <div className={classes.root}>
        <Stepper
          activeStep={steps.findIndex(s => s.id === currentStepId)}
          orientation="vertical"
          nonLinear
        >
          {steps.map((step, index) => {
            const isCompleted = step.status === 'completed';
            const isFailed = step.status === 'failed';
            const isActive = step.status === 'processing';
            const isSkipped = step.status === 'skipped';

            return (
              <Step key={String(index)} expanded>
                <StepButton onClick={() => onUserStepChange(step.id)}>
                  <StepLabel
                    StepIconProps={{
                      completed: isCompleted,
                      error: isFailed,
                      active: isActive,
                    }}
                    StepIconComponent={TaskStepIconComponent}
                    className={classes.stepWrapper}
                  >
                    <div className={classes.labelWrapper}>
                      <Typography variant="subtitle2">{step.name}</Typography>
                      {isSkipped ? (
                        <Typography variant="caption">Skipped</Typography>
                      ) : (
                        <StepTimeTicker step={step} />
                      )}
                    </div>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  },
);

const hasLinks = ({ links = [] }: ScaffolderTaskOutput): boolean =>
  links.length > 0;

/**
 * TaskPageProps for constructing a TaskPage
 * @param loadingText - Optional loading text shown before a task begins executing.
 *
 * @public
 */
export type TaskPageProps = {
  loadingText?: string;
};

/**
 * TaskPageContentProps for constructing a TaskPage
 * @param loadingText - Optional loading text shown before a task begins executing.
 * @param disableStartOver - Optional disable start over button
 * @param taskId - Id of the task to visualize
 * @param onContinue - Optional handler to execute with the Continue button
 *
 * @public
 */
export type TaskPageContentProps = {
  disableStartOver?: boolean;
  onStartOver?: () => void;
  loadingText?: string;
  taskId: string;
  onContinue?: (output: ScaffolderTaskOutput | undefined) => void;
};

/**
 * TaskPage for showing the status of the taskId provided as a param
 * @param loadingText - Optional loading text shown before a task begins executing.
 * @param disableStartOver
 * @param taskId
 * @param onStartOver
 * @param onContinue
 * @public
 */
export const TaskPageContent = ({
  loadingText,
  disableStartOver,
  taskId,
  onStartOver,
  onContinue,
}: TaskPageContentProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rootPath = useRouteRef(rootRouteRef);
  const editorWizardroute = useRouteRef(editorWizardRouteRef);
  const templateRoute = useRouteRef(selectedTemplateRouteRef);
  const [userSelectedStepId, setUserSelectedStepId] = useState<
    string | undefined
  >(undefined);
  const [lastActiveStepId, setLastActiveStepId] = useState<string | undefined>(
    undefined,
  );
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const taskStream = useTaskEventStream(taskId);
  const completed = taskStream.completed;

  const steps = useMemo(
    () =>
      taskStream.task?.spec.steps.map(step => ({
        ...step,
        ...taskStream?.steps?.[step.id],
      })) ?? [],
    [taskStream.task?.spec.steps, taskStream?.steps],
  );

  useEffect(() => {
    const mostRecentFailedOrActiveStep = steps.find(step =>
      ['failed', 'processing'].includes(step.status),
    );

    setIsFailed(!!steps.find(step => ['failed'].includes(step.status)));

    if (completed && !mostRecentFailedOrActiveStep) {
      setLastActiveStepId(steps[steps.length - 1]?.id);
      return;
    }

    setLastActiveStepId(mostRecentFailedOrActiveStep?.id);
  }, [steps, completed]);

  const currentStepId = userSelectedStepId ?? lastActiveStepId;

  const logAsString = useMemo(() => {
    if (!currentStepId) {
      return loadingText ?? 'Loading...';
    }
    const log = taskStream.stepLogs[currentStepId];

    if (!log?.length) {
      return 'Waiting for logs...';
    }
    return log.join('\n');
  }, [taskStream.stepLogs, currentStepId, loadingText]);

  const taskNotFound =
    taskStream.completed && !taskStream.loading && !taskStream.task;

  const { output } = taskStream;

  const handleStartOver = () => {
    onStartOver?.();
    if (!taskStream.task?.spec.templateInfo?.entityRef) {
      navigate(rootPath());
      return;
    }
    const formData = taskStream.task.spec.parameters;

    if (
      taskStream.task.spec.templateInfo.entityRef.split(':')[0] ===
      'edittemplate'
    ) {
      const { name, namespace, kind } = parseEntityRef(
        taskStream.task.spec.parameters.entityRef as string,
      );
      navigate(
        `${editorWizardroute({ namespace, kind, name })}?${qs.stringify({
          formData: JSON.stringify(formData),
        })}`,
      );
    } else {
      const { name, namespace } = parseEntityRef(
        taskStream.task.spec.templateInfo?.entityRef,
      );
      navigate(
        `${templateRoute({ templateName: name, namespace })}?${qs.stringify({
          formData: JSON.stringify(formData),
        })}`,
      );
    }
  };

  return (
    <>
      {taskNotFound && (
        <ErrorPage
          status="404"
          statusMessage="Task not found"
          additionalInfo="No task found with this ID"
        />
      )}
      {!taskNotFound && (
        <div>
          <Grid container>
            <Grid item xs={3}>
              <Paper elevation={0}>
                <TaskStatusStepper
                  steps={steps}
                  currentStepId={currentStepId}
                  onUserStepChange={setUserSelectedStepId}
                />
                {output && hasLinks(output) && (
                  <TaskPageLinks output={output} />
                )}
                {(!disableStartOver || isFailed) && (
                  <Button
                    className={classes.button}
                    onClick={handleStartOver}
                    disabled={!completed}
                    variant="contained"
                    color="primary"
                  >
                    Start Over
                  </Button>
                )}
                {onContinue && !isFailed && (
                  <Button
                    className={classes.button}
                    onClick={() => onContinue(output)}
                    disabled={!completed}
                    variant="contained"
                    color="primary"
                  >
                    Continue
                  </Button>
                )}
              </Paper>
            </Grid>
            <Grid item xs={9}>
              {!currentStepId && <Progress />}
              <div className={classes.logViewerWrapper}>
                <TaskErrors error={taskStream.error} />
                <LogViewer text={logAsString} />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export const TaskPage = ({ loadingText }: TaskPageProps) => {
  const { taskId } = useRouteRefParams(scaffolderTaskRouteRef);
  return (
    <Page themeId="home">
      <Header
        pageTitleOverride={`Task ${taskId}`}
        title="Task Activity"
        subtitle={`Activity for task: ${taskId}`}
      />
      <Content>
        <TaskPageContent loadingText={loadingText} taskId={taskId} />
      </Content>
    </Page>
  );
};
