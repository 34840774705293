/* eslint-disable react-hooks/rules-of-hooks */
/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { WitboostSystem } from '@agilelab/plugin-wb-builder-common';
import { Entity, RELATION_PART_OF } from '@backstage/catalog-model';
import { OverflowTooltip, TableColumn } from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
  EntityRefLinks,
} from '@backstage/plugin-catalog-react';
import { Box, Chip, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { CatalogTableRow } from './types';
import { JsonArray, JsonObject } from '@backstage/types';
import {
  Severity,
  SeverityTooltip,
  WbTagsTableCell,
} from '@agilelab/plugin-wb-platform';
import { useEntityRefLink } from './hooks/useEntityRefLink';

const useStyles = makeStyles(() => ({
  truncated: {
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    display: '-webkit-box',
  },
}));

const TooltipCell: React.FC<{ value: string }> = ({ value }) => {
  const classes = useStyles();
  return (
    <Tooltip placement="bottom-start" title={value}>
      <Typography className={classes.truncated} variant="body2">
        {value}
      </Typography>
    </Tooltip>
  );
};

// The columnFactories symbol is not directly exported, but through the
// CatalogTable.columns field.
/** @public */
export const columnFactories = Object.freeze({
  createNameColumn(options?: {
    defaultKind?: string;
  }): TableColumn<CatalogTableRow> {
    function formatContent(entity: Entity): string {
      return (
        entity.metadata?.title ||
        humanizeEntityRef(entity, {
          defaultKind: options?.defaultKind,
        })
      );
    }

    return {
      title: 'Name',
      field: 'resolved.name',
      highlight: true,
      customSort({ entity: entity1 }, { entity: entity2 }) {
        // TODO: We could implement this more efficiently by comparing field by field.
        // This has similar issues as above.
        return formatContent(entity1).localeCompare(formatContent(entity2));
      },
      render: ({ entity }) => {
        const e = entity as WitboostSystem;
        const title = e.spec?.mesh?.name || e.metadata?.title;
        const defaultKind = options?.defaultKind || 'Component';
        const { formattedEntityRefTitle } = useEntityRefLink({
          entityRef: entity,
          defaultKind,
        });

        return <TooltipCell value={title || formattedEntityRefTitle} />;
      },
      width: '30%',
    };
  },
  createSystemColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Dataproduct',
      field: 'resolved.partOfSystemRelationTitle',
      render: ({ resolved }) => (
        <EntityRefLinks
          entityRefs={resolved.partOfSystemRelations}
          defaultKind="system"
          title={resolved.partOfSystemRelationTitle}
        />
      ),
      width: '15%',
    };
  },
  createOwnerColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Owner',
      field: 'resolved.ownedByRelationsTitle',
      render: ({ resolved }) => (
        <EntityRefLinks
          entityRefs={resolved.ownedByRelations}
          defaultKind="group"
        />
      ),
      width: '15%',
    };
  },
  createSpecTargetsColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Targets',
      field: 'entity.spec.targets',
      render: ({ entity }) => (
        <>
          {(entity?.spec?.targets || entity?.spec?.target) && (
            <OverflowTooltip
              text={(
                (entity!.spec!.targets as JsonArray) || [entity.spec.target]
              ).join(', ')}
              placement="bottom-start"
            />
          )}
        </>
      ),
    };
  },
  createSpecTypeColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Type',
      field: 'entity.spec.type',
      hidden: true,
      width: '15%',
    };
  },
  createSpecLifecycleColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Lifecycle',
      field: 'entity.spec.lifecycle',
    };
  },
  createMetadataDescriptionColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Description',
      field: 'entity.metadata.description',
      customSort({ entity: entity1 }, { entity: entity2 }) {
        const description1 = (
          entity1 as WitboostSystem
        )?.metadata?.description?.toLowerCase();
        const description2 = (
          entity2 as WitboostSystem
        )?.metadata?.description?.toLowerCase();
        return (description1 ? description1 : '').localeCompare(
          description2 ? description2 : '',
        );
      },
      render: ({ entity }) => (
        <TooltipCell value={entity.metadata.description || ''} />
      ),

      width: '50%',
    };
  },
  createVersionColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Version',
      field: 'entity.spec.mesh.version',
      render: ({ entity }) => {
        const e = entity as WitboostSystem;
        return (
          <OverflowTooltip
            text={e.spec?.mesh?.version}
            placement="bottom-start"
          />
        );
      },
      width: 'auto',
    };
  },
  createQualifiedNameColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Name',
      field: 'entity.spec.mesh.name',
      highlight: true,
      customSort({ entity: entity1 }, { entity: entity2 }) {
        return (entity1 as WitboostSystem).spec?.mesh?.name.localeCompare(
          (entity2 as WitboostSystem).spec?.mesh?.name,
        );
      },
      render: ({ entity, error }) => {
        const e = entity as WitboostSystem;
        const title = e.spec?.mesh?.name || e.metadata?.title;
        const defaultKind = 'Component';
        const { formattedEntityRefTitle } = useEntityRefLink({
          entityRef: entity,
          defaultKind,
        });

        return (
          <Box display="flex" alignItems="center">
            <TooltipCell value={title || formattedEntityRefTitle} />
            {error && (
              <SeverityTooltip
                severity={Severity.Error}
                label={error}
                iconSize="inherit"
              />
            )}
          </Box>
        );
      },
      width: '30%',
    };
  },
  createDomainColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Domain',
      field: 'entity.spec.domain',
      customSort({ entity: entity1 }, { entity: entity2 }) {
        const domain1 = (entity1 as WitboostSystem)?.spec.domain?.toLowerCase();
        const domain2 = (
          entity2 as WitboostSystem
        )?.spec?.domain?.toLowerCase();
        return (domain1 ? domain1 : '').localeCompare(domain2 ? domain2 : '');
      },
      render: ({ entity }) => {
        const e = entity as WitboostSystem;
        return (
          <OverflowTooltip
            text={e.spec?.domain?.replace('domain:', '')}
            placement="bottom-start"
          />
        );
      },
      width: '20%',
    };
  },
  createDomainDataProductColumn(
    domains: Entity[],
  ): TableColumn<CatalogTableRow> {
    return {
      title: 'Domain',
      field: 'entity.spec.domain',
      render: ({ entity }) => {
        const e = entity as WitboostSystem;
        const partOfDomainRelations = getEntityRelations(e, RELATION_PART_OF, {
          kind: 'domain',
        });
        if (!partOfDomainRelations[0]) {
          return null;
        }
        const domain = domains.find(
          d =>
            d.metadata.name === partOfDomainRelations[0].name &&
            d.metadata.namespace === partOfDomainRelations[0].namespace,
        ) as WitboostSystem;
        const title = domain?.spec?.mesh?.name || partOfDomainRelations[0].name;
        return (
          <EntityRefLinks
            entityRefs={partOfDomainRelations}
            title={title}
            defaultKind="domain"
          />
        );
      },
      width: '20%',
    };
  },
  createTagsColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Tags',
      field: 'entity.metadata.tags',
      cellStyle: {
        padding: '0px 16px 0px 20px',
      },
      render: ({ entity }) => (
        <WbTagsTableCell
          tags={
            entity?.metadata?.tags?.map(t => {
              return { tagFQN: t };
            }) || []
          }
        />
      ),
      width: '20%',
    };
  },
  createTitleColumn(options?: {
    hidden?: boolean;
  }): TableColumn<CatalogTableRow> {
    return {
      title: 'Title',
      field: 'entity.metadata.title',
      hidden: options?.hidden,
      searchable: true,
    };
  },
  createLabelColumn(
    key: string,
    options?: { title?: string; defaultValue?: string },
  ): TableColumn<CatalogTableRow> {
    return {
      title: options?.title || 'Label',
      field: 'entity.metadata.labels',
      cellStyle: {
        padding: '0px 16px 0px 20px',
      },
      render: ({ entity }: { entity: Entity }) => {
        const labels: Record<string, string> | undefined =
          entity.metadata?.labels;
        const specifiedLabelValue =
          (labels && labels[key]) || options?.defaultValue;
        return (
          <>
            {specifiedLabelValue && (
              <Chip
                key={specifiedLabelValue}
                label={specifiedLabelValue}
                size="small"
                variant="outlined"
              />
            )}
          </>
        );
      },
      width: 'auto',
    };
  },
  createCreationDateColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Creation Date',
      field: 'entity.metadata.createdAt',
      render: ({ entity }: { entity: Entity }) => {
        const createdAt = entity.metadata.createdAt;
        const date = createdAt ? new Date(String(createdAt)) : null;

        return (
          date && (
            <>
              {date.toISOString().split('T')[0]} - {date.toLocaleTimeString()}
            </>
          )
        );
      },
      width: 'auto',
    };
  },
  createUserNameColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Name',
      field: 'entity.spec.profile.displayName',
      render: ({ entity }: { entity: Entity }) => {
        return (entity?.spec?.profile as JsonObject)?.displayName || '';
      },
      width: 'auto',
    };
  },
  createUserEmailColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Email',
      field: 'entity.spec.profile.email',
      render: ({ entity }: { entity: Entity }) => {
        const e = entity as WitboostSystem;
        const title = e.spec?.mesh?.name || e.metadata?.title;
        const defaultKind = 'user';
        const { formattedEntityRefTitle } = useEntityRefLink({
          entityRef: entity,
          defaultKind,
        });

        return <TooltipCell value={title || formattedEntityRefTitle} />;
      },
      width: 'auto',
    };
  },
  createReleaseSystemColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Dataproduct',
      field: 'metadata.dataProductName',
      render: ({ entity }: { entity: Entity }) => {
        return (entity?.metadata as JsonObject)?.dataProductName || '';
      },
      width: '15%',
    };
  },
  createReleaseVersionColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Version',
      field: 'metadata.version',
      render: ({ entity }: { entity: Entity }) => {
        return (entity?.metadata as JsonObject)?.version || '';
      },
      width: '15%',
    };
  },
});
