import { Metric } from '@agilelab/plugin-wb-governance-common';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Task } from '../types';
import { ThresholdResult } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  metric: Metric;
  task: Task;
}

export const MetricHeader: React.FC<Props> = ({ metric, task }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.header} style={{ marginBottom: theme.spacing(2) }}>
      <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <ThresholdResult
          value={task.metricSpecificResult?.value || 0}
          result={task.metricSpecificResult?.threshold}
          hasError={task.errors.length > 0}
          dimension={31.2}
        />
        <Typography variant="h6">
          <strong style={{ color: theme.palette.primary.main }}>
            {`Metric ${metric.name}`}
          </strong>
        </Typography>
      </Box>
    </Box>
  );
};
