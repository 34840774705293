import { TextFieldProps, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { ClearButton } from './ClearButton';
import { useStyles } from './styles';

type Props = TextFieldProps & { clearable?: boolean };

export const WbTextField = React.forwardRef(
  (
    {
      InputProps,
      SelectProps,
      InputLabelProps,
      clearable = false,
      ...props
    }: Props,
    ref?: React.Ref<any>,
  ) => {
    const classes = useStyles();
    const [internalValue, setInternalValue] = useState(props.value || '');

    return (
      <TextField
        inputRef={ref}
        fullWidth
        variant="outlined"
        value={internalValue}
        onChange={e => {
          if (props.onChange) props.onChange(e);
          setInternalValue(e.target.value);
        }}
        size="small"
        classes={{
          root: classes.root,
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.input,
          },
          endAdornment: clearable && !props.disabled && (
            <ClearButton
              style={{ marginRight: -6 }}
              onClear={e => {
                const event = e as unknown as React.ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >;
                setInternalValue('');
                event.target.value = '';
                if (props.onChange) props.onChange(event);
              }}
            />
          ),
          ...InputProps,
        }}
        SelectProps={{
          classes: {
            root: classes.selectRoot,
          },
          ...SelectProps,
        }}
        InputLabelProps={{
          classes: {
            root: classes.inputLabelRoot,
          },
          shrink: true,
          ...InputLabelProps,
        }}
        {...props}
      />
    );
  },
);
