/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { JsonObject } from '@backstage/types';
import {
  Box,
  Button,
  PropTypes,
  Step as StepUI,
  StepContent,
  StepLabel,
  Stepper,
  ThemeProvider,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  errorApiRef,
  useApi,
  featureFlagsApiRef,
  useAnalytics,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import { FormProps, IChangeEvent, withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import React, {
  Fragment,
  useState,
  useRef,
  MutableRefObject,
  useEffect,
} from 'react';
import { transformSchemaToProps } from './schema';
import { WmUiSchema } from '../../extensions/types';
import { DefaultArrayFieldTemplate } from '../fields';
import { transformErrors } from './errorsOverrides';
import cloneDeep from 'lodash/cloneDeep';
import * as fieldOverrides from './FieldOverrides';
import * as widgetOverrides from './WidgetOverrides';
import { LayoutOptions } from '../../layouts';
import { ErrorSchema } from '@rjsf/utils';
import { selectedTemplateRouteRef } from '../../routes';

const Form = withTheme(MuiTheme);
type Step = {
  schema: JsonObject;
  title: string;
  asyncValidate?: (formData: any) => Promise<ErrorSchema>;
} & Partial<Omit<FormProps<any>, 'schema'>>;

export type AdditionalStep = {
  title: string;
  component: JSX.Element;
  additionalActions?: JSX.Element[];
  backButtonText?: string;
  nextButtonText?: string;
  disableStepButtons: boolean;
  analyticsEvent?: string;
  backAction?: () => void;
  nextAction?: () => Promise<void>;
  resetButtonText?: string;
  resetAction?: () => void;
  resetButtonVariant?: 'text' | 'outlined' | 'contained';
  resetButtonColor?: PropTypes.Color;
};

type Props = {
  /**
   * Steps for the form, each contains title and form schema
   */
  steps: Step[];
  formData: Record<string, any>;
  onChange: (e: IChangeEvent) => void;
  widgets?: FormProps<any>['widgets'];
  fields?: FormProps<any>['fields'];
  layouts: LayoutOptions[];
  additionalSteps?: AdditionalStep[];
};

export function getUiSchemasFromSteps(steps: Step[]): WmUiSchema[] {
  const uiSchemas: Array<WmUiSchema> = [];
  steps.forEach(step => {
    const schemaProps = step.schema.properties as JsonObject;
    for (const key in schemaProps) {
      if (schemaProps.hasOwnProperty(key)) {
        const uiSchema =
          schemaProps[key] && typeof schemaProps[key] === 'object'
            ? (schemaProps[key] as WmUiSchema)
            : {};
        uiSchema.name = key;
        uiSchemas.push(uiSchema);
      }
    }
  });
  return uiSchemas;
}

export function getReviewData(formData: Record<string, any>, steps: Step[]) {
  const uiSchemas = getUiSchemasFromSteps(steps);
  const reviewData: Record<string, any> = {};
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const uiSchema = uiSchemas.find(us => us.name === key);

      if (!uiSchema) {
        reviewData[key] = formData[key];
        continue;
      }

      if (uiSchema['ui:widget'] === 'password') {
        reviewData[key] = '******';
        continue;
      }

      if (!uiSchema['ui:backstage'] || !uiSchema['ui:backstage'].review) {
        reviewData[key] = formData[key];
        continue;
      }

      const review = uiSchema['ui:backstage'].review as JsonObject;
      if (review.mask) {
        reviewData[key] = review.mask;
        continue;
      }

      if (!review.show) {
        continue;
      }
      reviewData[key] = formData[key];
    }
  }

  return reviewData;
}

export const MultistepJsonForm = (props: Props) => {
  const { formData, onChange, fields, widgets, layouts, additionalSteps } =
    props;
  const { templateName } = useRouteRefParams(selectedTemplateRouteRef);
  const theme = useTheme();
  const ref: MutableRefObject<any> = useRef(null);
  const analytics = useAnalytics();
  const [activeStep, setActiveStep] = useState(0);
  const errorApi = useApi(errorApiRef);
  const featureFlagApi = useApi(featureFlagsApiRef);
  const featureFlagKey = 'backstage:featureFlag';
  const toolbarHeight = useRef(64);

  useEffect(() => {
    const witboostToolbar = document.getElementById('witboost-toolbar');
    if (witboostToolbar) {
      toolbarHeight.current = witboostToolbar.clientHeight;
    }
  });

  /**
   * Scrolls to the top of the selected step, making it visible
   */
  const scrollToTheTopOfTheActiveStep = () => {
    // the Stepper component creates two children for every step
    const stepRefIndex = activeStep > 0 ? activeStep * 2 : 0;
    // this is the Y offset calculated as the height of MeshAppBar + a 10 pixel padding
    const yOffset = -(toolbarHeight.current + 10);
    if (
      ref.current &&
      ref.current.children &&
      ref.current.children[stepRefIndex]
    ) {
      const element = ref.current.children[stepRefIndex];
      const yTargetScroll =
        element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({
        top: yTargetScroll,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  };

  const filterOutProperties = (step: Step): Step => {
    const filteredStep = cloneDeep(step);
    const removedPropertyKeys: Array<string> = [];
    if (filteredStep.schema.properties) {
      filteredStep.schema.properties = Object.fromEntries(
        Object.entries(filteredStep.schema.properties).filter(
          ([key, value]) => {
            if (value && value[featureFlagKey]) {
              if (featureFlagApi.isActive(value[featureFlagKey])) {
                return true;
              }
              removedPropertyKeys.push(key);
              return false;
            }
            return true;
          },
        ),
      );

      // remove the feature flag property key from required if they are not active
      filteredStep.schema.required = Array.isArray(filteredStep.schema.required)
        ? filteredStep.schema.required?.filter(
            r => !removedPropertyKeys.includes(r as string),
          )
        : filteredStep.schema.required;
    }
    return filteredStep;
  };

  const steps = props.steps
    .filter(step => {
      const featureFlag = step.schema[featureFlagKey];
      return (
        typeof featureFlag !== 'string' || featureFlagApi.isActive(featureFlag)
      );
    })
    .map(filterOutProperties);

  const handleNext = () => {
    const stepNum = Math.min(
      activeStep + 1,
      steps.length + (additionalSteps?.length ?? 0),
    );
    setActiveStep(stepNum);
    analytics.captureEvent('click', `Next Step (${stepNum})`);
  };
  const handleBack = () => setActiveStep(Math.max(activeStep - 1, 0));
  const [error, setError] = useState<any>(null);
  return (
    <ThemeProvider
      theme={{
        ...theme,
        overrides: {
          ...theme.overrides,
          MuiFormHelperText: {
            contained: {
              marginLeft: 0,
              marginRight: 0,
            },
          },
          MuiFormControl: {
            root: {
              height: '100%',
            },
          },
        },
      }}
    >
      <Stepper
        style={{ background: theme.palette.background.default }}
        activeStep={activeStep}
        orientation="vertical"
        ref={ref}
      >
        {steps.map(({ title, schema, asyncValidate, ...formProps }, index) => {
          const isTitleClickable = activeStep > index;
          return (
            <StepUI key={title}>
              <StepLabel
                style={{ cursor: isTitleClickable ? 'pointer' : 'default' }}
                aria-label={`Step ${index + 1} ${title}`}
                aria-disabled="false"
                tabIndex={0}
                onClick={() => {
                  if (isTitleClickable) setActiveStep(index);
                }}
              >
                <Typography variant="h6" component="h3">
                  {title}
                </Typography>
              </StepLabel>
              <StepContent
                key={title}
                TransitionProps={{
                  // after the transition of the step, we scroll to the top of the active step
                  onExited: scrollToTheTopOfTheActiveStep,
                  // in the onExited we are invoking the scroll method that will have a smooth transition;
                  // given that the default collapse/expanse animation of the step takes a long time, we set it to 0 to reduce the latency for users
                  // so that the overall experience is not a forward-backward scroll of the page
                  timeout: {
                    appear: 0,
                    enter: 0,
                    exit: 0,
                  },
                }}
              >
                <Form
                  extraErrors={error}
                  ArrayFieldTemplate={DefaultArrayFieldTemplate}
                  showErrorList={false}
                  fields={{
                    ...fieldOverrides,
                    ...fields,
                  }}
                  widgets={{ ...widgetOverrides, ...widgets }}
                  noHtml5Validate
                  formData={formData}
                  onChange={onChange}
                  onSubmit={async e => {
                    setError(null);
                    if (asyncValidate) {
                      const validationResult = await asyncValidate(formData);
                      if (validationResult) {
                        const keysWithErrors = Object.keys(
                          validationResult,
                        ).filter(
                          key => validationResult[key]?.__errors?.length,
                        );

                        if (
                          keysWithErrors.length === 0 &&
                          e.errors.length === 0
                        ) {
                          handleNext();
                        } else {
                          setError(validationResult);
                        }
                      } else {
                        if (e.errors.length === 0) {
                          handleNext();
                        }
                      }
                    } else {
                      if (e.errors.length === 0) {
                        handleNext();
                      }
                    }
                  }}
                  {...formProps}
                  {...transformSchemaToProps(schema, layouts)}
                  formContext={formData}
                  transformErrors={transformErrors}
                >
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Next step
                  </Button>
                </Form>
              </StepContent>
            </StepUI>
          );
        })}
        {additionalSteps?.map(
          (
            {
              title,
              component,
              additionalActions,
              backButtonText,
              nextButtonText,
              disableStepButtons,
              resetButtonText,
              analyticsEvent,
              resetButtonColor,
              resetButtonVariant,
              resetAction,
              backAction,
              nextAction,
            },
            index,
          ) => {
            const isTitleClickable = activeStep > index + steps.length;
            return (
              <StepUI key={title}>
                <StepLabel
                  style={{ cursor: isTitleClickable ? 'pointer' : 'default' }}
                  aria-label={`Step ${index + 1} ${title}`}
                  aria-disabled="false"
                  tabIndex={0}
                  onClick={() => {
                    if (isTitleClickable) setActiveStep(steps.length + index);
                  }}
                >
                  <Typography variant="h6" component="h3">
                    {title}
                  </Typography>
                </StepLabel>
                <StepContent
                  key={title}
                  TransitionProps={{
                    // after the transition of the step, we scroll to the top of the active step
                    onExited: scrollToTheTopOfTheActiveStep,
                    // in the onExited we are invoking the scroll method that will have a smooth transition;
                    // given that the default collapse/expanse animation of the step takes a long time, we set it to 0 to reduce the latency for users
                    // so that the overall experience is not a forward-backward scroll of the page
                    timeout: {
                      appear: 0,
                      enter: 0,
                      exit: 0,
                    },
                  }}
                >
                  <Box>
                    {component}
                    {(resetButtonText || nextButtonText || backButtonText) && (
                      <Box mb={3} />
                    )}
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      {backButtonText && (
                        <Button
                          disabled={disableStepButtons}
                          onClick={() => {
                            if (backAction) backAction();
                            handleBack();
                          }}
                        >
                          {backButtonText}
                        </Button>
                      )}
                      {resetButtonText && (
                        <Button
                          variant={resetButtonVariant}
                          color={resetButtonColor}
                          onClick={() => {
                            if (resetAction) resetAction();
                            setActiveStep(0);
                          }}
                          disabled={disableStepButtons}
                        >
                          {resetButtonText}
                        </Button>
                      )}
                      {nextButtonText && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={disableStepButtons}
                          onClick={async () => {
                            try {
                              if (nextAction) await nextAction();
                              if (analyticsEvent) {
                                analytics.captureEvent(
                                  analyticsEvent,
                                  formData.name || `new ${templateName}`,
                                );
                              }
                              handleNext();
                            } catch (err) {
                              errorApi.post(err);
                            }
                          }}
                        >
                          {nextButtonText}
                        </Button>
                      )}
                      {additionalActions?.map(action => (
                        <Fragment key={action.key}>{action}</Fragment>
                      ))}
                    </Box>
                  </Box>
                </StepContent>
              </StepUI>
            );
          },
        )}
      </Stepper>
    </ThemeProvider>
  );
};
