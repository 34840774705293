import {
  Box,
  makeStyles,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { ExpandableItem } from './ExpandableItem';
import { SimpleItem } from './SimpleItem';

const useStyle = makeStyles(theme => ({
  text: {
    overflow: 'hidden',
    fontSize: theme.typography.fontSize,
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '14px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  label?: string;
  value?: string | React.ReactNode;
  type?: 'text' | 'date';
  href?: string;
  limit?: number;
  children?: React.ReactNode;
  contentProps?: TypographyProps;
};

const Content = ({
  value,
  type,
  href,
  limit,
  contentProps,
}: Omit<Props, 'label'>) => {
  const classes = useStyle();
  const defaultLimit = 75;

  if (type === 'text' && typeof value === 'string') {
    return (
      <Typography
        variant="body2"
        className={classes.wordBreak}
        {...contentProps}
      >
        {value.length > (limit || defaultLimit) ? (
          <ExpandableItem
            value={value}
            href={href}
            limit={limit || defaultLimit}
          />
        ) : (
          <SimpleItem value={value} href={href} />
        )}
      </Typography>
    );
  }

  if (type === 'date') {
    return (
      <Typography variant="body2" {...contentProps}>
        {format(parseISO(value as string), 'yyyy/MM/dd HH:mm:ss')}
      </Typography>
    );
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return (
      <Typography variant="body2" {...contentProps}>
        {value}
      </Typography>
    );
  }

  if (typeof value === 'boolean') {
    return (
      <Typography variant="body1" {...contentProps}>
        {value.toString()}
      </Typography>
    );
  }

  return <>{value || '-'}</>;
};

export const GenericField = ({ label, children, ...content }: Props) => {
  const classes = useStyle();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '100%',
      }}
    >
      {label && (
        <Typography
          className={classes.label}
          variant="body2"
          style={{
            paddingBottom: '8px',
          }}
        >
          {label}
        </Typography>
      )}
      <Content {...content} />
    </Box>
  );
};
