import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useOveflowingElement } from '../../hooks/useOverflowingElement';

const useStyles = makeStyles(() =>
  createStyles({
    truncated: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
      overflow: 'hidden',
      display: '-webkit-box',
      wordBreak: 'break-all',
    },
  }),
);

export interface ShowMoreElementProps {}

export const ShowMoreElement: React.FC<ShowMoreElementProps> = ({
  children,
}) => {
  const classes = useStyles();
  const ref = React.useRef(null);
  const isOverflowing = useOveflowingElement(ref);
  const [showMore, setShowMore] = React.useState(false);

  return (
    <Box display="flex" alignItems="baseline">
      <span className={!showMore ? classes.truncated : ''} ref={ref}>
        {children}
      </span>
      {(isOverflowing || showMore) && (
        <span>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => setShowMore(v => !v)}
            style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}
          >
            Show {!showMore ? 'More' : 'Less'}
          </Button>
        </span>
      )}
    </Box>
  );
};
