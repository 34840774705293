import { DiscoveryApi } from '@backstage/core-plugin-api';
import fetch from 'cross-fetch';
import { WitboostDocument } from '../types';

export declare type DocumentApiOptions = {
  token?: string;
};

export interface DocumentsApi {
  retrieveUnacceptedDocuments(
    user_ref: string,
    options: DocumentApiOptions,
  ): Promise<WitboostDocument[]>;

  acceptDocumentFromUser(
    user_ref: string,
    document_id: number,
    options?: DocumentApiOptions,
  ): Promise<{ id: number }>;
}

export class DocumentsClient implements DocumentsApi {
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.baseUrlPromise = options.discoveryApi.getBaseUrl('documents');
  }

  private async getCredentials(options?: DocumentApiOptions): Promise<string> {
    if (options?.token && options.token) {
      return options.token;
    }
    return '';
  }

  async retrieveUnacceptedDocuments(
    user_ref: string,
    options?: DocumentApiOptions,
  ): Promise<WitboostDocument[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}/by-user?${new URLSearchParams({
        user_ref: user_ref,
        not_accepted: 'true',
        exclude_consultancy: 'true',
        enabled: 'true',
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/pdf',
        },
      },
    );

    const documents: WitboostDocument[] = await response.json();

    const documentsUint8 = documents.map(doc => {
      return {
        ...doc,
        content: Buffer.from(doc.content),
      };
    });

    return documentsUint8;
  }

  async acceptDocumentFromUser(
    user_ref: string,
    document_id: number,
    options?: DocumentApiOptions,
  ): Promise<{ id: number }> {
    const baseUrl = await this.baseUrlPromise;

    const body = {
      document_id: document_id,
      user_ref: user_ref,
    };

    const response = await fetch(`${baseUrl}/user-acceptance`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return response.json();
  }
}
