import React, { useMemo } from 'react';
import {
  useTheme,
  Box,
  Tooltip,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/PriorityHigh';
import {
  MetricThresholdColor,
  ThresholdResult as Result,
  defaultThresholdColors,
} from '@agilelab/plugin-wb-governance-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { thresholdValueToString } from './utils/ThresholdValueToString';

const DEFAULT_COLOR = '#bdbdbd';

interface Props {
  value: number;
  result?: Result | null;
  hasError?: boolean;
  dimension?: number;
  style?: React.CSSProperties;
}

const useStyles = makeStyles<Theme, { dimension: number }>(() => ({
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    minWidth: ({ dimension }) => dimension,
    minHeight: ({ dimension }) => dimension,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
}));

export const ThresholdResult: React.FC<Props> = ({
  value,
  result,
  hasError = false,
  dimension = 36,
  style,
}) => {
  const theme = useTheme();
  const classes = useStyles({ dimension });
  const configApi = useApi(configApiRef);

  const resultLabel = result?.label || 'No Label';

  const colors: MetricThresholdColor[] =
    configApi.getOptional('mesh.governance.metrics.thresholds.colors') ||
    defaultThresholdColors;

  const backgroundColor: string = useMemo(() => {
    const relatedColor = colors.find(c => c.label === result?.labelColor);

    return relatedColor?.value || DEFAULT_COLOR;
  }, [colors, result]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        ...style,
      }}
    >
      {hasError ? (
        <Tooltip title="An error occured">
          <Box className={classes.icon} style={{ backgroundColor: '#F55D6F' }}>
            <ErrorIcon htmlColor="white" />
          </Box>
        </Tooltip>
      ) : (
        <Box
          className={classes.icon}
          style={{
            backgroundColor,
          }}
        >
          <Tooltip title={`${resultLabel} - ${value}`}>
            <Typography
              component="p"
              variant="body2"
              style={{ color: 'white' }}
            >
              {thresholdValueToString(value)}
            </Typography>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};
