import {
  Entity,
  entityEnvelopeSchemaValidator,
  EntityMeta,
} from '@backstage/catalog-model';
import schema from '../schema/Release.v1alpha1.schema.json';
import { generateAjvJsonSchemaValidator } from '../utils';

declare interface ReleaseEntityV1alpha1 extends Entity {
  apiVersion: 'agilelab.it/v1alpha1';
  kind: 'Release';
  metadata: EntityMeta & {
    name: string;
    dataProductName: string;
    version: string;
    createdAt: string;
    repoUrl: string;
    releaseLocation: string;
    branch: string;
    isSnapshot: boolean;
    tags?: string[];
    committedAt?: string;
    deployedAt?: string;
    releasedAt?: string;
  };
}

export type { ReleaseEntityV1alpha1 };
export type { ReleaseEntityV1alpha1 as ReleaseEntity };

export const applyReleaseEntityV1alpha1 =
  entityEnvelopeSchemaValidator<ReleaseEntityV1alpha1>(schema);

export const releaseEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
