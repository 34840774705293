import { SelectFilter } from '@agilelab/plugin-wb-platform';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntityTypeFilter } from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';

export const CustomEntityTypePicker: React.FC = () => {
  const alertApi = useApi(alertApiRef);
  const { error, availableTypes, selectedTypes, setSelectedTypes } =
    useEntityTypeFilter();

  useEffect(() => {
    if (error) {
      alertApi.post({
        message: `Failed to load entity types`,
        severity: 'error',
      });
    }
  }, [error, alertApi, setSelectedTypes]);

  // TODO - understand the expected behavior
  // if (availableTypes.length === 0 || error) return null;

  const items = [
    ...availableTypes.map((type: string) => ({
      value: type,
      label: type,
    })),
  ];

  const getLabel = (option: string) => {
    const item = items.find(e => e.value === option);
    return item ? snakeCaseToTitleCase(item.label) : '';
  };

  return (
    <SelectFilter<string>
      field="Type"
      value={items.length ? selectedTypes[0] : undefined}
      options={items.map(item => item.value)}
      renderOption={getLabel}
      renderValue={getLabel}
      onChange={v => {
        setSelectedTypes(!Boolean(v) ? [] : [String(v)]);
      }}
      onSearch={v =>
        items.filter(o => new RegExp(v, 'ig').test(o.label)).map(o => o.value)
      }
    />
  );
};
