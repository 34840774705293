import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { List } from '@material-ui/core';
import { UserSection } from './UserSection';
import logo from './logo.svg';
import { Link } from 'react-router-dom';
import { PlatformSettingsIcon } from './PlatformSettingsIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoWrapper: {
      padding: theme.spacing(1.5),
      height: '64px',
      width: '190px',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    toolbar: {
      display: 'flex',
      backgroundColor: theme.palette.header.background,
      color: theme.palette.header.text,
    },
    list: {
      display: 'flex',
      padding: 0,
      height: '64px',
    },
    icon: {
      '& svg': {
        fill: theme.palette.header.text,
      },
    },
  }),
);
export function MeshAppBar(props: {
  searchButton: ReactNode;
  children: ReactNode[];
  notificationsIcon?: ReactNode;
  showPlatformSettings?: boolean;
}) {
  const { searchButton, children, notificationsIcon, showPlatformSettings } =
    props;
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      color="transparent"
      style={{ boxShadow: '0px 0px 50px #92929226' }}
      id="witboost-toolbar"
    >
      <Toolbar className={classes.toolbar}>
        <Link className={classes.logoWrapper} to="catalog/my-data-products">
          <img src={logo} alt="logo datamesh" />
        </Link>
        <List component="nav" aria-label="menu voices" className={classes.list}>
          {children}
        </List>
        <span style={{ flex: 1 }} />
        {searchButton}
        {showPlatformSettings && <PlatformSettingsIcon />}
        <div className={classes.icon}>{notificationsIcon}</div>
        <UserSection />
      </Toolbar>
    </AppBar>
  );
}
