export interface MetricThreshold {
  label: string;
  color: string;
  value: string;
}

export interface MetricThresholdColor {
  label: string;
  value: string;
}

export const defaultThresholdColors: MetricThresholdColor[] = [
  { label: 'Red', value: '#FF5252' },
  { label: 'Yellow', value: '#F9A825' },
  { label: 'Green', value: '#00C853' },
];
