import { Button } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

type Props = {
  value: string;
  href?: string;
  limit: number;
};

export const ExpandableItem = ({ value, href, limit }: Props) => {
  const [showMore, setShowMore] = useState(false);

  const externalLink = href?.startsWith('http')
    ? href
    : 'https://'.concat(href || '');

  const renderedValue = (
    <Fragment>
      {value.slice(0, limit)}
      {showMore ? value.slice(limit) : '...'}
    </Fragment>
  );

  return (
    <Fragment>
      {href ? (
        <a target="_blank" rel="noreferrer" href={externalLink}>
          {renderedValue}
        </a>
      ) : (
        renderedValue
      )}
      <Button
        variant="text"
        size="small"
        color="primary"
        onClick={() => setShowMore(v => !v)}
      >
        Show {!showMore ? 'More' : 'Less'}
      </Button>
    </Fragment>
  );
};
