import {
  AccessAuthorizationStatus,
  AccessRequestNotification,
} from '@agilelab/plugin-wb-notification-common';
import {
  customAlertApiRef,
  ProgressButton,
  useUsername,
} from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { Chip, Typography, useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import { useNotificationStyle } from './NotificationsPageStyle';
import { notificationApiRef } from '../../notificationApiRef';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { Entity } from '@backstage/catalog-model';
import { userCache } from './NotificationsPage';
import {
  NotificationAccordion,
  NotificationAccordionAction,
  NotificationAccordionDetails,
  NotificationAccordionSummary,
} from './NotificationAccordion';
import { Avatar, Link } from '@backstage/core-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { JsonObject } from 'type-fest';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { green, red } from '@material-ui/core/colors';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { MarkAsReadButton } from './MarkAsReadButton';
import { useQueryClient } from '@tanstack/react-query';
import { CustomError } from '@agilelab/plugin-wb-platform-common';

export const AccessRequestNotificationAccordion = (props: {
  notification: AccessRequestNotification;
  onAccordionExpansionsChange: (value: string | boolean) => void;
  expandedAccordion: string | boolean;
}) => {
  const { notification, onAccordionExpansionsChange, expandedAccordion } =
    props;
  const classes = useNotificationStyle();
  const catalogApi = useApi(catalogApiRef);
  const theme = useTheme();
  const notificationCatalog = useApi(notificationApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const { profile } = useUserProfile();
  const loggedUsername = useUsername();
  const [loading, setLoading] = useState('');
  const queryClient = useQueryClient();

  const { value: askerEntity } = useAsync(async (): Promise<
    Entity | undefined
  > => {
    if (userCache.has(notification.notification_request.requester))
      return userCache.get(notification.notification_request.requester)!;
    const userEntity = await catalogApi.getEntityByRef({
      name: notification.notification_request.requester,
      namespace: 'default',
      kind: 'user',
    });
    if (userEntity) {
      userCache.set(notification.notification_request.requester, userEntity);
    }
    return userEntity;
  }, [notification]);

  const onToggle = useCallback(
    (_event: object, expanded: boolean) => {
      onAccordionExpansionsChange(
        expanded ? notification.id.toString() : false,
      );
    },
    [notification.id, onAccordionExpansionsChange],
  );

  const updateAccessRequestResponse = useCallback(
    async (status: AccessAuthorizationStatus) => {
      setLoading(status);
      try {
        await notificationCatalog
          .updateNotificationResponse(
            notification.id.toString(),
            {
              authorized_by: loggedUsername?.username || profile.email,
              authorized_by_display_name:
                loggedUsername?.displayName || profile.displayName,
              status: status,
              timestamp: new Date().toJSON(),
            },
            await identityApi.getCredentials(),
          )
          .then(
            _ => {
              if (status === AccessAuthorizationStatus.ACCEPT) {
                alertApi.post({
                  message: `Access request accepted! A follow-up message will inform you when the access is effective into the system.`,
                  severity: 'success',
                });
              }
              queryClient.invalidateQueries({
                queryKey: ['paginatedByRecipient'],
              });
              setLoading('');
            },
            error => {
              alertApi.post({
                error,
                severity: 'error',
              });
              setLoading('');
            },
          );
      } catch (error) {
        alertApi.post({
          error: new CustomError('Failed to grant access', error.message),
          severity: 'error',
          timeoutMillis: 5000,
        });
        setLoading('');
      }
    },
    [
      alertApi,
      identityApi,
      loggedUsername?.displayName,
      loggedUsername?.username,
      notification.id,
      notificationCatalog,
      profile.displayName,
      profile.email,
      queryClient,
    ],
  );

  return (
    <NotificationAccordion
      onChange={onToggle}
      expanded={expandedAccordion === notification.id.toString()}
      style={{
        backgroundColor: !!notification.read_at
          ? 'transparent'
          : theme.palette.common.white,
      }}
    >
      <NotificationAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summaryHeader}>
          <Typography variant="body2" className={classes.notificationTime}>
            {DateTime.fromISO(notification.created_at).toFormat(
              'dd/MM/yyyy HH:mm',
            )}
          </Typography>
          <MarkAsReadButton notification={notification} />
        </div>
        <div className={classes.summaryDescription}>
          {!notification.notification_response && (
            <ErrorOutlineOutlinedIcon color="primary" />
          )}
          {notification.notification_response?.status ===
            AccessAuthorizationStatus.ACCEPT && (
            <CheckCircleOutlineOutlinedIcon style={{ color: green[500] }} />
          )}
          {notification.notification_response?.status ===
            AccessAuthorizationStatus.REJECT && (
            <NotInterestedIcon style={{ color: red[500] }} />
          )}
          <Avatar
            displayName={
              notification.notification_request.requester_display_name
            }
            picture={
              (askerEntity?.spec?.profile as JsonObject | undefined)
                ?.picture as string
            }
            customStyles={{
              width: theme.spacing(7),
              height: theme.spacing(7),
            }}
          />
          <div className={classes.summaryDescriptionText}>
            <span>
              <b>{notification.notification_request.requester_display_name}</b>{' '}
              requested access to the data product
            </span>
            <span>
              <b>name:</b>{' '}
              <Link
                to={`/marketplace/products-catalog/${notification.notification_request.id_dataproduct_instance}`}
              >
                <Typography variant="body2" color="primary" component="b">
                  {notification.notification_request.dataproduct.display_name ||
                    notification.notification_request.dataproduct.name}
                </Typography>
              </Link>
            </span>
            <span>
              <b>domain:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.domain}
              </Typography>
            </span>
            <span>
              <b>environment:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.environment}
              </Typography>
            </span>
            <span>
              <b>version:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.version}
              </Typography>
            </span>
          </div>
        </div>
      </NotificationAccordionSummary>
      <NotificationAccordionDetails>
        <div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Output port
            </Typography>
            <Typography variant="body1">
              <b>Name:</b> {notification.notification_request.outputport.name}
            </Typography>
            <Typography variant="body1">
              <b>Type:</b>{' '}
              {notification.notification_request.outputport.output_port_type}
            </Typography>
          </div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Requested for
            </Typography>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
              }}
            >
              {notification.notification_request.identities &&
                notification.notification_request.identities.map(i => (
                  <Chip title={i} key={i} label={i} size="small" />
                ))}
            </div>
          </div>
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              Motivation
            </Typography>
            <Typography variant="body1">
              {' '}
              {notification.notification_request.motivation}
            </Typography>
          </div>
          {notification.notification_response?.status ===
            AccessAuthorizationStatus.ACCEPT && (
            <Typography style={{ color: green[500] }}>
              Request accepted on:{' '}
              {DateTime.fromISO(
                notification.notification_response?.timestamp,
              ).toFormat('dd/MM/yyyy HH:mm')}
            </Typography>
          )}
          {notification.notification_response?.status ===
            AccessAuthorizationStatus.REJECT && (
            <Typography style={{ color: red[500] }}>
              Access rejected :{' '}
              {DateTime.fromISO(
                notification.notification_response?.timestamp,
              ).toFormat('dd/MM/yyyy HH:mm')}
            </Typography>
          )}
        </div>
      </NotificationAccordionDetails>
      <NotificationAccordionAction>
        {!notification.notification_response && (
          <>
            <ProgressButton
              meshColor="red"
              variant="contained"
              inProgress={loading === AccessAuthorizationStatus.REJECT}
              disabled={loading === AccessAuthorizationStatus.ACCEPT}
              onClick={() =>
                updateAccessRequestResponse(AccessAuthorizationStatus.REJECT)
              }
            >
              Reject
            </ProgressButton>
            <ProgressButton
              meshColor="green"
              variant="contained"
              inProgress={loading === AccessAuthorizationStatus.ACCEPT}
              disabled={loading === AccessAuthorizationStatus.REJECT}
              onClick={() =>
                updateAccessRequestResponse(AccessAuthorizationStatus.ACCEPT)
              }
            >
              Accept
            </ProgressButton>
          </>
        )}
      </NotificationAccordionAction>
    </NotificationAccordion>
  );
};
