import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const ExpandDockablePanelIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-883.496 -1624.457)">
      <path
        d="M11.008,11.008V.786L7.076,4.718,2.359,0,0,2.359,4.718,7.076.786,11.008Z"
        transform="translate(896.489 1637.45)"
        fill="#00aec5"
      />
      <path
        d="M11.008,11.008V.786L7.076,4.718,2.359,0,0,2.359,4.718,7.076.786,11.008Z"
        transform="translate(894.504 1635.465) rotate(180)"
        fill="#00aec5"
      />
    </g>
  </svg>,
  'ExpandDockablePanelIcon',
);
