/**
 * list of recurrent constants
 */

export const DOMAIN = 'dmn';
export const COMPONENT = 'cmp';
export const USER = 'user';
export const GROUP = 'group';
export const DATA_PRODUCT = 'dp';
export const RESOURCE = 'rsr';
export const RELEASE = 'release';
export const SYSTEM = 'system';

export const APP_NAME = 'witboost';
export const CATALOG = 'catalog';

export const URN = 'urn';
export const DMB = 'dmb';
