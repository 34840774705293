import React from 'react';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { GovernanceTestOverviewMetricInfo } from './GovernanceTestOverviewMetricInfo';
import { Metric } from '@agilelab/plugin-wb-governance-common';

interface Props {
  metric: Metric;
}

export const GovernanceTestOverviewMetricSimple: React.FC<Props> = props => {
  return (
    <WbCard cardStyle={{ height: '100%' }} title="Overview">
      <WbCardContent>
        <GovernanceTestOverviewMetricInfo {...props} />
      </WbCardContent>
    </WbCard>
  );
};
