import fetch from 'cross-fetch';
import { DiscoveryApi } from '@backstage/core-plugin-api';
import { CatalogClient } from '@backstage/catalog-client';
import { EntityErrorResult } from './types';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';

export class CatalogClientExtension extends CatalogClient {
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    super({ discoveryApi: options.discoveryApi });
    this.baseUrlPromise = options.discoveryApi.getBaseUrl('catalog');
  }

  async getGroupAncestors(
    entityRef: string,
    serverToken: string,
  ): Promise<string[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}/groupsAncestors?entityRef=${entityRef}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${serverToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    await handleFailedResponse(response);

    return response.json();
  }

  async getEntityErrors(
    idsOrRefs: string[],
    token?: string,
  ): Promise<EntityErrorResult[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(`${baseUrl}/errors`, {
      method: 'POST',
      body: JSON.stringify(idsOrRefs),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    await handleFailedResponse(response);

    return response.json();
  }
}
