import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { getInputProps } from '@rjsf/utils';
import { WbTextField } from '@agilelab/plugin-wb-platform';
import { WidgetProps } from '@rjsf/core';

export const TextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  registry,
  ...textFieldProps
}: WidgetProps) => {
  const inputProps = getInputProps(schema, type, options);
  // Now we need to pull out the step, min, max into an inner `inputProps` for material-ui
  const { step, min, max, ...rest } = inputProps;
  const otherProps = {
    inputProps: {
      step,
      min,
      max,
      maxLength: uiSchema['ui:options']?.maxLength,
      ...(schema.examples ? { list: `examples_${id}` } : undefined),
    },
    multiline: uiSchema['ui:options']?.multiline,
    rows: uiSchema['ui:options']?.rows,
    ...rest,
  };
  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(
      event.target.value === '' ? options.emptyValue : event.target.value,
    );
  const _onBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, event.target.value);
  const _onFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, event.target.value);

  return (
    <WbTextField
      id={id}
      name={id}
      placeholder={placeholder}
      label={label || schema.title}
      required={required}
      disabled={disabled || readonly}
      {...otherProps}
      value={value || value === 0 ? value : ''}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      {...(textFieldProps as TextFieldProps)}
      {...uiSchema.customProps}
    />
  );
};
