import { parseEntityRef } from '@backstage/catalog-model';
import { DateTime } from 'luxon';

export const isURL = (value: string): boolean => {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isEntityRef = (value: string): boolean => {
  try {
    return parseEntityRef(value) ? true : false;
  } catch (error) {
    return false;
  }
};

export const isEmail = (email: string): boolean => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
    ? true
    : false;
};

export const isISODate = (dateString: string): boolean => {
  if (typeof dateString !== 'string') {
    return false;
  }

  const isNum = /^\d+$/.test(dateString);
  if (isNum || typeof dateString === 'boolean') {
    return false;
  }
  return DateTime.fromISO(dateString).isValid;
};

/**
 * Transforms a camel case string to snake case (e.g. "helloWorld" to "hello_world").
 * This transformation does not handle spaces in the input string.
 *
 * @param str the input camel case string
 * @returns the resulting snake case string
 */
export const transformCamelToSnakeCase = (str: string): string =>
  str.substring(0, 1).toLowerCase() +
  str.substring(1).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

/**
 * Transforms a camel case string to spaced case (e.g. "helloWorld" to "Hello World").
 * This transformation does not handle spaces in the input string.
 * If the keepUpperCaseWords parameter is set to false, each word will have a lower case starting letter
 * (e.g. "helloWorld" is converted into "hello world").
 *
 * @param str the input camel case string
 * @param keepUpperCaseWords tells if each word should have a capital starting letter
 * @returns the resulting spaced case string
 */
export const transformCamelToSpacedCase = (
  str: string,
  keepUpperCaseWords: boolean = true,
): string => {
  const changeFirstLetter = (letter: string) =>
    keepUpperCaseWords ? letter.toUpperCase() : letter.toLocaleLowerCase();
  return (
    changeFirstLetter(str.substring(0, 1)) +
    str
      .substring(1)
      .replace(/[A-Z]/g, letter => ` ${changeFirstLetter(letter)}`)
  );
};

export const applyConversionToCamelCasedWithSpacesInput = (
  str: string,
  f: (s: string) => string,
  joinString: string,
) => {
  return str.trim().split(/\s+/).map(f).join(joinString);
};

/**
 * Transforms a camel case string to snake case (e.g. "helloWorld" to "hello_world").
 * This transformation handles also strings containing spaces: leading and trailing spaces are removed,
 * and spaces in between camel cased sections are translated into "_" (e.g. " firstOne  secondOne  " is converted into "first_one_second_one").
 *
 * @param str the input camel case string
 * @returns the resulting snake case string
 */
export const camelToSnakeCase = (str: string): string =>
  applyConversionToCamelCasedWithSpacesInput(
    str,
    transformCamelToSnakeCase,
    '_',
  );

/**
 * Transforms a camel case string to spaced case (e.g. "helloWorld" to "Hello World").
 * This transformation handles also strings containing spaces: leading and trailing spaces are removed,
 * and spaces in between camel cased sections are translated into spaces (e.g. " firstOne  secondOne  " is converted into "First One Second One").
 * If the keepUpperCaseWords parameter is set to false, each word will have a lower case starting letter
 * (e.g. " firstOne  secondOne  " is converted into "first one second one").
 *
 * @param str the input camel case string
 * @param keepUpperCaseWords tells if each word should have a capital starting letter
 * @returns the resulting spaced case string
 */
export const camelToSpacedCase = (
  str: string,
  keepUpperCaseWords: boolean = true,
): string =>
  applyConversionToCamelCasedWithSpacesInput(
    str,
    s => transformCamelToSpacedCase(s, keepUpperCaseWords),
    ' ',
  );

/**
 * Transforms a snake case string to spaced case (e.g. "hello_World" to "Hello World").
 *
 * @param value the input snake case string
 * @returns the resulting spaced case string
 */
export function snakeCaseToTitleCase(value: string): string {
  return value
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
}
