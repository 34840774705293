import React, { Fragment } from 'react';

type Props = {
  value: string;
  href?: string;
};

export const SimpleItem = ({ value, href }: Props) => {
  const externalLink = href?.startsWith('http')
    ? href
    : 'https://'.concat(href || '');

  return href ? (
    <a target="_blank" rel="noreferrer" href={externalLink}>
      {value || '-'}
    </a>
  ) : (
    <Fragment>{value}</Fragment>
  );
};
