import React from 'react';
import {
  createStyles,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: '50px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.cardHeader,
        color: theme.palette.getContrastText(theme.palette.cardHeader),
      },
    },
    detailIcon: {
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },
    componentId: {
      flex: 1,
    },
  }),
);

export const TestTableListItemEmpty: React.FC = () => {
  const classes = useStyles();

  return (
    <ListItem key="empty" className={classes.detailWrapper}>
      <div className={classes.detailIcon} />
      <div className={classes.componentId}>
        <Typography variant="body2">
          <i>No tested policies/metrics to show</i>
        </Typography>
      </div>
    </ListItem>
  );
};
