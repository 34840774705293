import {
  defaultThresholdColors,
  Metric,
  MetricSelector,
  MetricThresholdColor,
} from '@agilelab/plugin-wb-governance-common';
import {
  GenericField,
  WbTagsTableCell,
  WbCardContent,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import {
  snakeCaseToTitleCase,
  toHumanReadableInteractionType,
} from '@agilelab/plugin-wb-platform-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { StatusField } from '../../../common/Field/StatusField';
import { fromExpressionToLabel } from '../../../MetricWizard/MetricMetadataForm/inputs/MetricThresholdPicker';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(3),
    flexWrap: 'wrap',
  },
}));

function extractSelectorSummary(selectors: MetricSelector[]): string {
  return selectors
    .map((selector: MetricSelector) => {
      const values =
        selector.values.length > 1
          ? `(${selector.values.join(', ')})`
          : selector.values[0];
      return `${selector.path} ${
        selector.values.length > 1 ? 'in' : '='
      } ${values}`;
    })
    .join(' AND ');
}

interface Props {
  metric: Metric;
}
export const GovernanceTestOverviewMetricInfo: React.FC<Props> = props => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const colors: MetricThresholdColor[] =
    configApi.getOptional('mesh.governance.metrics.thresholds.colors') ||
    defaultThresholdColors;

  return (
    <WbWidget title="Info">
      <WbCardContent>
        <Box className={classes.container}>
          <GenericField label="ID" value={props.metric.metricId} />
          <GenericField
            label="Description"
            value={props.metric.description}
            type="text"
          />
          <GenericField label="Version" value={props.metric.version} />
          <GenericField
            label="Resource Preprocessing"
            value={snakeCaseToTitleCase(props.metric.strategy)}
          />
          <GenericField
            label="Scope"
            value={snakeCaseToTitleCase(props.metric.resourceType)}
          />
          <GenericField
            label="Status"
            value={<StatusField status={props.metric.status} />}
          />
          <GenericField
            label="Context"
            value={snakeCaseToTitleCase(props.metric.context)}
          />
          <GenericField
            label="Timing"
            value={snakeCaseToTitleCase(props.metric.timing)}
          />
          <GenericField
            label="Trigger"
            value={snakeCaseToTitleCase(props.metric.trigger)}
          />
          {!!props.metric.cronExpression && (
            <GenericField
              label="Expression"
              value={snakeCaseToTitleCase(props.metric.cronExpression)}
            />
          )}
          {(!!props.metric.domain.min || !!props.metric.domain.max) && (
            <GenericField
              label="Domain"
              value={
                (props.metric.domain.min
                  ? `Min: ${props.metric.domain.min} `
                  : '') +
                (props.metric.domain.max
                  ? `Max: ${props.metric.domain.max}`
                  : '')
              }
            />
          )}
          {!!props.metric.expression && (
            <GenericField
              label="Expression"
              value={snakeCaseToTitleCase(props.metric.expression)}
            />
          )}
          {!!props.metric.expression && !!props.metric.thresholds.length && (
            <GenericField
              label="Threshold"
              value={props.metric.thresholds
                .sort((a, b) => (a.value > b.value ? 1 : -1))
                .map(t => (
                  <Typography
                    key={t.label}
                    variant="body2"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 4,
                    }}
                  >
                    <Box
                      style={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: colors.find(c => c.label === t.color)
                          ?.value,
                      }}
                    />
                    {`${t.label} ${
                      fromExpressionToLabel[props.metric.expression!]
                    } ${t.value}`}
                  </Typography>
                ))}
            />
          )}
          {!!props.metric.expression && props.metric.thresholds.length === 0 && (
            <GenericField
              label="Threshold"
              value={
                <Typography
                  variant="body2"
                  style={{
                    alignItems: 'center',
                  }}
                >
                  -
                </Typography>
              }
            />
          )}

          <GenericField
            label="Interaction Type"
            value={toHumanReadableInteractionType(props.metric.interactionType)}
          />
          {props.metric.selectors && props.metric.selectors.length > 0 && (
            <GenericField
              label="Selectors"
              value={extractSelectorSummary(props.metric.selectors)}
            />
          )}
          {props.metric.tags && props.metric.tags.length > 0 && (
            <GenericField
              label="Tags"
              value={
                <WbTagsTableCell
                  tags={props.metric.tags.map(t => {
                    return { tagFQN: t };
                  })}
                />
              }
            />
          )}
          {props.metric.environments &&
            props.metric.environments.length > 0 && (
              <GenericField
                label="Environment"
                value={
                  props.metric.environments.map((env: string) =>
                    capitalize(env),
                  )[0]
                }
              />
            )}
        </Box>
      </WbCardContent>
    </WbWidget>
  );
};
