import React from 'react';
import {
  NotStartedIcon,
  ProgressErrorIcon,
  ProgressRunningIcon,
  ProgressSuccessIcon,
  TerminatedIcon,
} from '../Icons';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';

export function getStepIcon(status: DagStatus) {
  switch (status) {
    case DagStatus.PASSED:
      return <ProgressSuccessIcon />;
    case DagStatus.FAILED:
      return <ProgressErrorIcon />;
    case DagStatus.TERMINATED:
      return <TerminatedIcon />;
    case DagStatus.RUNNING:
      return <ProgressRunningIcon />;
    case DagStatus.NOT_STARTED:
      return <NotStartedIcon />;
    default:
      return null;
  }
}

export function getDagStatusColor(status: DagStatus) {
  switch (status) {
    case DagStatus.PASSED:
      return '#21c185';
    case DagStatus.FAILED:
      return '#f55d6f';
    case DagStatus.RUNNING:
      return '#7073d4';
    default:
      return '#FFFFF';
  }
}
