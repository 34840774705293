import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

interface TableContentModalProps<T> {
  title: string;
  open: boolean;
  onClose: () => void;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  values: Array<T>;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
}

export function TableContentModal<T>({
  title,
  maxWidth = 'lg',
  open,
  onClose,
  searchValue,
  onSearchValueChange,
  values,
}: TableContentModalProps<T>) {
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      PaperProps={{
        style: {
          padding: '24px',
          borderRadius: 1,
          backgroundColor: theme.palette.background.default,
        },
      }}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <Box
        style={{
          margin: 0,
          marginBottom: '24px',
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ color: '#00ACC1', textTransform: 'uppercase', margin: 0 }}
          component="p"
          variant="h5"
        >
          {title}
        </Typography>
        <Box width="250px">
          <TextField
            fullWidth
            placeholder="Search"
            inputProps={{ 'aria-label': searchValue }}
            value={searchValue}
            onChange={e => onSearchValueChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear"
                    onClick={() => onSearchValueChange('')}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <DialogContent
        style={{ display: 'flex', flexDirection: 'column', padding: 4 }}
      >
        <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
          {values
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((value, index) => (
              <Grid item xs={3} key={index}>
                {value}
              </Grid>
            ))}
        </Grid>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
          component="div"
          count={values.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ marginTop: '24px', alignSelf: 'flex-end' }}
        />
      </DialogContent>
    </Dialog>
  );
}
