import {
  Box,
  Button,
  CardContent,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useCallback, useState } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { MetricMetadataAddForm } from '../../types';
import { MetricThresholdSlider } from '../MetricThresholdSlider';
import {
  defaultThresholdColors,
  MetricThresholdColor,
  ThresholdExpression,
} from '@agilelab/plugin-wb-governance-common';
import useResizeObserver from '../../../../hooks/useResizeObserver';
import { MetricNumericFormatCustom } from '../MetricNumericFormatCustom';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { WbCard, WbSelect, WbTextField } from '@agilelab/plugin-wb-platform';

export const fromExpressionToLabel: Record<ThresholdExpression, string> = {
  [ThresholdExpression.Above]: '>',
  [ThresholdExpression.AboveOrEqual]: '>=',
  [ThresholdExpression.Below]: '<',
  [ThresholdExpression.BelowOrEqual]: '<=',
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  formRow: {
    display: 'flex',
  },
  description: {
    margin: theme.spacing(0, 0, 2),
  },
}));

export const MetricThresholdPicker: React.FC<
  UseFormReturn<MetricMetadataAddForm>
> = props => {
  const configApi = useApi(configApiRef);

  const colors: MetricThresholdColor[] =
    configApi.getOptional('mesh.governance.metrics.thresholds.colors') ||
    defaultThresholdColors;

  const classes = useStyles();
  const { control } = props;

  const { fields, append, remove } = useFieldArray({
    name: 'thresholds',
    control,
  });

  const [sliderWidth, setSliderWidth] = useState<number>();
  const onResize = useCallback((target: HTMLDivElement) => {
    const parentPadding = 16 * 2;
    const inputWidth = 100 * 2;
    const gap = 60;

    const width =
      target.getBoundingClientRect().width - parentPadding - inputWidth - gap;
    setSliderWidth(width);
  }, []);
  const ref = useResizeObserver(onResize);

  const updateThresholds = useCallback(
    (value: string, index: number) => {
      const thresholds = props.getValues('thresholds');
      const newThresholds = [...thresholds];
      newThresholds[index].value = value;
      props.setValue('thresholds', newThresholds);
    },
    [props],
  );

  return (
    <FormControl className={classes.formControl}>
      <WbCard title="Thresholds">
        <CardContent ref={ref}>
          <Typography variant="body2">
            In this section you can define the domain of the metric by
            specification a lower bound and an upper bound. It is also possible
            define the thresholds and labels to associate them in the metric
            evaluation phase
          </Typography>

          <Box style={{ marginTop: 15 }}>
            <Typography variant="body2">Domain</Typography>
            <MetricThresholdSlider
              {...props}
              width={sliderWidth}
              colors={colors}
            />

            <Box style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              {fields.map((_item, index) => (
                <Box
                  style={{ display: 'flex', alignItems: 'baseline', gap: 30 }}
                >
                  <Controller
                    control={control}
                    name={`thresholds.${index}.label`}
                    render={({ field, fieldState: { error } }) => (
                      <WbTextField
                        label="Label"
                        style={{ width: 163 * 2 }}
                        error={Boolean(error)}
                        helperText={error?.message}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`thresholds.${index}.color`}
                    render={({ field, fieldState: { error } }) => (
                      <WbSelect
                        label="Color"
                        style={{ width: 163 }}
                        error={Boolean(error)}
                        helperText={error?.message}
                        {...field}
                        onChange={e => {
                          field.onChange(e.target.value);
                          const thresholds = props.getValues('thresholds');
                          const newThresholds = [...thresholds];
                          newThresholds[index].color = e.target.value;
                          props.setValue('thresholds', newThresholds);
                        }}
                      >
                        {colors.map(c => (
                          <MenuItem key={c.label} value={c.label}>
                            {c.label}
                          </MenuItem>
                        ))}
                      </WbSelect>
                    )}
                  />

                  <Typography>
                    {fromExpressionToLabel[props.watch('expression')]}
                  </Typography>

                  <Controller
                    control={control}
                    name={`thresholds.${index}.value`}
                    render={({ field, fieldState: { error } }) => (
                      <WbTextField
                        label="Value"
                        style={{ width: 163 }}
                        error={Boolean(error)}
                        helperText={error?.message}
                        {...field}
                        onChange={e => {
                          const newValue = e.target.value;
                          field.onChange(e.target.value);
                          updateThresholds(newValue, index);
                        }}
                        InputProps={{
                          inputComponent: MetricNumericFormatCustom as any,
                        }}
                      />
                    )}
                  />

                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>

            <Button
              style={{ marginTop: 30 }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                append({
                  label: undefined,
                  color: undefined,
                  value: undefined,
                });
              }}
            >
              Add a Threshold
            </Button>
          </Box>
        </CardContent>
      </WbCard>
    </FormControl>
  );
};
