/* eslint-disable no-console */
import { NotificationsIcon } from '@agilelab/plugin-wb-notification';
import {
  CustomMicrofrontendOptions,
  CustomMicrofrontendTarget,
  MeshAppBar,
  MeshAppBarItem,
  customFrontendApiRef,
  usePermissions,
} from '@agilelab/plugin-wb-platform';
import {
  cgpEntityEdit,
  cgpEntityView,
  platformSettingsEditPermission,
} from '@agilelab/plugin-wb-rbac-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import BallotIcon from '@material-ui/icons/Ballot';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import React, { PropsWithChildren } from 'react';
import { SearchModal } from '../search/SearchModal';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import {
  BlueprintIcon,
  BuilderIcon,
  CreateIcon,
  DpCatalogIcon,
  DpGraphIcon,
  GovernanceIcon,
  GovernanceRegistryIcon,
  GovernanceSearchSesultsIcon,
  MarketplaceIcon,
  MeshSupervisionIcon,
  MyDataProductIcon,
  PoliciesOverviewIcon,
  SoftwareCatalogIcon,
} from './Icons/Icons';
import { usePermission } from '@backstage/plugin-permission-react';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    layout: {},
  }),
);

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();
  return <Box className={classes.layout}>{children}</Box>;
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const configApi = useApi(configApiRef);
  const customFrontendApi = useApi(customFrontendApiRef);

  const getCustomFrontends = customFrontendApi.makeCustomMicrofrontends(
    CustomMicrofrontendTarget.MarketplacePage,
  );
  const customPages: CustomMicrofrontendOptions[] = getCustomFrontends();

  const externalResourcesEnabled: boolean =
    configApi.getOptionalBoolean('mesh.externalResources') ?? false;

  const meshSupervisionEnabled: boolean =
    configApi.getOptionalBoolean(
      'mesh.marketplace.meshSupervisionPage.enabled',
    ) ?? true;

  const blueprintsEnabled: boolean =
    configApi.getOptionalBoolean(
      'mesh.builder.scaffolder.blueprints.enabled',
    ) ?? true;

  const platformSettingsEnabled = configApi.getOptionalBoolean(
    'mesh.platformSettings.enabled',
  );

  const dataContractsEnabled: boolean =
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.dataContractsPage.enabled',
    ) ?? false;

  const platformSettingsAllowed = usePermission({
    permission: platformSettingsEditPermission,
  }).allowed;

  const governanceEnabled = usePermissions([
    {
      permission: cgpEntityEdit,
    },
    {
      permission: cgpEntityView,
    },
  ]).anyAllowed;

  return (
    <Layout>
      <MeshAppBar
        searchButton={<SearchModal />}
        notificationsIcon={<NotificationsIcon />}
        showPlatformSettings={
          platformSettingsEnabled && platformSettingsAllowed
        }
      >
        <MeshAppBarItem
          title="Marketplace"
          icon={MarketplaceIcon}
          pathMatch={['marketplace']}
          menuItems={[
            ...(meshSupervisionEnabled
              ? [
                  {
                    icon: MeshSupervisionIcon,
                    title: 'Mesh Supervision',
                    to: 'marketplace/dashboard',
                  },
                ]
              : []),
            {
              icon: DpCatalogIcon,
              title: 'Data Product Catalog',
              to: 'marketplace/products-catalog',
            },
            {
              icon: DpGraphIcon,
              title: 'Data Product Graph',
              to: 'marketplace/products-graph',
            },
            ...(dataContractsEnabled
              ? [
                  {
                    icon: AccountTreeOutlinedIcon,
                    title: 'Data Contracts',
                    to: 'marketplace/data-contracts',
                  },
                ]
              : []),
            ...customPages.map(page => {
              return {
                icon: BallotIcon,
                title: page.title,
                to: `marketplace${page.route}`,
              };
            }),
          ]}
        />
        <MeshAppBarItem
          title="Builder"
          icon={BuilderIcon}
          pathMatch={['builder', 'catalog', 'create', 'wb-scaffolder']}
          menuItems={[
            { icon: CreateIcon, title: 'Templates', to: 'create' },
            ...(blueprintsEnabled
              ? [
                  {
                    icon: BlueprintIcon,
                    title: 'Blueprints',
                    to: '/wb-scaffolder/blueprints',
                  },
                ]
              : []),
            { icon: MyDataProductIcon, title: 'My Data Products', to: '' },
            {
              icon: SoftwareCatalogIcon,
              title: 'Software Catalog',
              to: 'catalog',
            },
          ]}
        />
        {governanceEnabled && (
          <MeshAppBarItem
            title="Governance"
            icon={GovernanceIcon}
            pathMatch={['governance']}
            menuItems={[
              {
                icon: GovernanceRegistryIcon,
                title: 'Registry',
                to: 'governance/registry',
              },
              {
                icon: PoliciesOverviewIcon,
                title: 'Policies Overview',
                to: 'governance/policies-overview',
              },
              {
                icon: GovernanceSearchSesultsIcon,
                title: 'Search Results',
                to: 'governance/search-results',
              },
            ]}
          />
        )}
        {externalResourcesEnabled && (
          <MeshAppBarItem
            title="Data Sharing"
            icon={UnarchiveIcon}
            pathMatch={['external-resources']}
            menuItems={[
              {
                icon: FolderIcon,
                title: 'Dashboard',
                to: '/external-resources/dashboard',
              },
              {
                icon: FolderIcon,
                title: 'Package List',
                to: '/external-resources',
              },
            ]}
          />
        )}
      </MeshAppBar>
      {children}
    </Layout>
  );
};
