import { Config } from '@backstage/config';
import fetch from 'cross-fetch';

export enum CustomUrlOperations {
  VALIDATION = 'validation',
  RETRIEVAL = 'retrieval',
}

/**
 * An interface representing the Microservice configuration
 */
export interface MicroserviceConfig {
  microserviceId: string;
  baseUrl: string;
  retrievalPath?: string;
  validationPath?: string;
  retrievalMethod?: string;
  validationMethod?: string;
  apiKey?: string;
}

/**
 * This function enables to extract dynamic fields, i.e. fields that are identified by some special characters "${{ }}".
 * It extracts the values that are inside the above special characters and resolve them by using the formContext.
 * @param formContext
 * @param params
 * @returns
 */
export function parseDynamicFields(
  formContext: any,
  prevFormContext: any,
  resetValues: (value: string[]) => void,
  setEnableSearch: (value: boolean) => void,
  setOffset: (value: number) => void,
  setErrors?: React.Dispatch<React.SetStateAction<Map<string, string>>>,
  params?: any,
): any | undefined {
  const dynamicFieldChecker = /^\${1}\{{2}\s*[a-zA-Z0-9_\-]+\s*\}{2}$/g;

  const dynamicFieldExtractor = /[\$\{\}\s]/g;

  if (Array.isArray(params)) {
    return params
      .map(value =>
        parseDynamicFields(
          formContext,
          prevFormContext,
          resetValues,
          setEnableSearch,
          setOffset,
          setErrors,
          value,
        ),
      )
      .filter(val => val !== undefined);
  }

  if (typeof params === 'string' && dynamicFieldChecker.test(params)) {
    const fieldName = params.replaceAll(dynamicFieldExtractor, '');
    if (fieldName) {
      if (
        prevFormContext &&
        prevFormContext[fieldName] !== formContext[fieldName]
      ) {
        setOffset(0);
        setEnableSearch(true);
        resetValues([]);
      }

      if (setErrors) {
        if (!formContext[fieldName]) {
          setErrors(prevErrors =>
            prevErrors.set(
              fieldName,
              `There is a dependency to the field "${fieldName}". Remember to fill it before going to the next step.`,
            ),
          );
        } else {
          setErrors(prevErrors => {
            prevErrors.delete(fieldName);
            return prevErrors;
          });
        }
      }

      return formContext[fieldName];
    }

    return params;
  }

  if (typeof params === 'object') {
    return Object.entries(params)
      .map(([field, value]) => [
        field,
        parseDynamicFields(
          formContext,
          prevFormContext,
          resetValues,
          setEnableSearch,
          setOffset,
          setErrors,
          value,
        ),
      ])
      .reduce(
        (accumulator, [field, value]) => ({ ...accumulator, [field]: value }),
        {},
      );
  }

  return params;
}

/**
 * This method validates the input UI schema for the Custom URL Picker
 * @param uiSchema
 * @returns
 */
export function validateUiSchema(uiSchema: any) {
  if (!uiSchema['ui:apiSpec']) {
    throw new Error(
      "'ui:apiSpec' property must be defined. See the docs if you want additional info.",
    );
  }

  if (!uiSchema['ui:apiSpec'].retrieval) {
    throw new Error(
      "'retrieval' property should be configured under 'ui:apiSpec' property. See the docs if you want additional info.",
    );
  }

  if (!uiSchema['ui:options']?.selectedField) {
    throw new Error(
      "'selectedField' should be defined under 'ui:options' property.",
    );
  }

  if (!uiSchema['ui:displayFields']) {
    throw new Error(
      "'api:displayFields' property must be defined for CustomUrlPicker. See the docs if you want additional info.",
    );
  }

  if (!uiSchema['ui:apiSpec'].validation) {
    throw new Error(
      `'ui:apiSpec.validation' property must be defined for CustomUrlPicker. See the docs if you want additional info.`,
    );
  }

  if (!uiSchema['ui:apiSpec'].validation.microserviceId) {
    throw new Error(
      `'ui:apiSpec.validation.microserviceId' property must be defined for CustomUrlPicker. See the docs if you want additional info.`,
    );
  }

  if (uiSchema['ui:fieldsToSave']) {
    const intersection = uiSchema['ui:displayFields'].filter((field: string) =>
      uiSchema['ui:fieldsToSave'].includes(field),
    );

    if (intersection.length !== uiSchema['ui:displayFields'].length) {
      throw new Error(
        `'ui:displayFields' is not a sub-set of 'ui:fieldsToSave'. This may cause some problems in the visualization of the results in the drop-down list.`,
      );
    }
  }

  if (
    !uiSchema['ui:displayFields'].includes(
      uiSchema['ui:options']?.selectedField,
    )
  ) {
    throw new Error(
      `'ui:options'.selectedField is not included in the 'ui:displayFields' parameter. Please, choose as 'selectedField' a field that is contained also in the 'displayFields' parameter.`,
    );
  }

  return uiSchema;
}

/**
 * It checks if there is a configuration inside Witboost that has the same microserviceId of the one
 * inside the template.yaml file. If true, it takes those configurations as default values. If the user
 * specifies the 'url' and 'method' fields in the template.yaml, these lasts will take priority over configuration.
 * @param config
 * @param validatedUiSchema
 * @returns
 */
export function parseConfiguration(
  config: Config | undefined,
  validatedUiSchema: any,
  operation: CustomUrlOperations,
): MicroserviceConfig {
  if (!config) {
    throw new Error('Unable to find configuration instance.');
  }

  const microservicesConfig = config.getOptional<MicroserviceConfig[]>(
    'mesh.builder.scaffolder.microserviceConfiguration',
  );

  if (!microservicesConfig) {
    throw new Error(
      "The configuration 'mesh.builder.scaffolder.microserviceConfiguration' is not found",
    );
  }

  let apiSpec = validatedUiSchema['ui:apiSpec']?.retrieval;

  if (operation === CustomUrlOperations.VALIDATION) {
    apiSpec = validatedUiSchema['ui:apiSpec']?.validation;
  }

  const foundedMicroservice = microservicesConfig.find(
    conf => conf.microserviceId === apiSpec.microserviceId,
  );

  if (!foundedMicroservice) {
    throw new Error(
      `Unable to find the microservice specification for "${apiSpec.microserviceId}"`,
    );
  }

  return {
    ...foundedMicroservice,
    baseUrl: apiSpec.baseUrl ?? foundedMicroservice.baseUrl,
    retrievalPath:
      apiSpec.path ?? foundedMicroservice.retrievalPath ?? '/v1/resources',
    retrievalMethod:
      apiSpec.method ?? foundedMicroservice.retrievalMethod ?? 'POST',
    validationPath:
      apiSpec.path ??
      foundedMicroservice.validationPath ??
      '/v1/resources/validate',
    validationMethod:
      apiSpec.method ?? foundedMicroservice.validationMethod ?? 'POST',
  };
}

/**
 * It performs an HTTP call to a Microservice which specifications are made available in the "microserviceConfig" parameter
 * and by passing "params" in the body of the request. It returns a list of objects filtered by "filter" corresponding to the offset "offset".
 * @param microserviceConfig: the specification of the microservice
 * @param setErrors a function used to return errors to the caller
 * @param offset the offset of the call
 * @param params it contains the body of request and also the "limit" parameter to pass to the request
 * @param filter a string used to filter results
 * @returns
 */
export async function fetchMicroserviceValues(
  microserviceConfig: MicroserviceConfig,
  setErrors: React.Dispatch<React.SetStateAction<Map<string, string>>>,
  offset: number,
  params?: any,
  filter?: string,
) {
  const queryParams = filter ? `&filter=${filter}` : '';

  const { limit, ...restParams } = params;
  const xApiKeyHeader = microserviceConfig.apiKey
    ? { 'X-API-Key': microserviceConfig.apiKey }
    : undefined;
  const response = await fetch(
    `${microserviceConfig.baseUrl}${
      microserviceConfig.retrievalPath
    }?offset=${offset}&limit=${
      limit !== undefined && limit >= 5 ? limit : 5
    }${queryParams}`,
    {
      method: microserviceConfig.retrievalMethod,
      headers: {
        'Content-Type': 'application/json',
        ...xApiKeyHeader,
      },
      body: JSON.stringify(restParams),
    },
  );

  let responseBody = (await response.json()) as any;

  if (response.status !== 200) {
    setErrors(prevErrors =>
      prevErrors.set(
        'retrieval_error',
        'Error while fetching values. Retry later or contact platform team.',
      ),
    );
    responseBody = [];
  }

  return responseBody;
}
