import { AppTheme } from '@backstage/core-plugin-api';
import {
  BackstageTheme,
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BackstageOverrides } from '@backstage/core-components';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';
import React from 'react';
import { Typography } from '@material-ui/core/styles/createTypography';

interface HeaderPalette {
  background: string;
  highlight: string;
  text: string;
  contrastText: string;
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    header: HeaderPalette;
    cardHeader: string;
    backdrop: string;
    background2: string;
    background3: string;
    green: string;
    red: string;
    orange: string;
    yellow: string;
    additional: string;
    disabled: string;
  }
  interface PaletteOptions {
    header: HeaderPalette;
    cardHeader: string;
    backdrop: string;
    background2: string;
    background3: string;
    green: string;
    red: string;
    orange: string;
    yellow: string;
    additional: string;
    disabled: string;
  }
}

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

export const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides => {
  return {
    ...lightTheme.overrides,
    BackstageHeader: {
      header: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        boxShadow: 'none',
        padding: theme.spacing(2, 6),
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: theme.typography.h5.fontSize,
      },
      subtitle: {
        marginTop: '12px',
        letterSpacing: '-0.015rem',
        color: theme.palette.text.primary,
      },
      type: {
        color: theme.palette.text.primary,
      },
      rightItemsBox: {
        color: theme.palette.primary.main,
        margin: 0,
      },
    },
    BackstageHeaderLabel: {
      label: {
        color: theme.palette.primary.main,
      },
      value: {
        color: theme.palette.primary.main,
      },
      root: {
        color: theme.palette.primary.main,
      },
    },
    BackstageContent: {
      root: {
        padding: `0 !important`,
        background: `${theme.palette.background.paper} 0% 0% no-repeat padding-box`,
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
        marginBottom: theme.spacing(6),
      },
    },
    BackstageHeaderTabs: {
      selected: {
        color: `${theme.palette.primary.main} !important`,
      },
      tabsWrapper: {
        paddingLeft: theme.spacing(5),
        paddingBottom: theme.spacing(2),
        '& button': {
          padding: `0 !important`,
          margin: `0 8px !important`,
          minWidth: 0,
          color: theme.palette.text.primary,
          opacity: 1,

          textTransform: 'capitalize',
          fontSize: '1.125rem',
          fontWeight: 500,
          letterSpacing: '0.009rem',
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'inherit',
          },
        },
      },
    },
    BackstageInfoCard: {
      header: {
        padding: theme.spacing(1.5, 2),
        '& span': {
          margin: 0,
        },
      },
      headerSubheader: {
        paddingTop: 0,
      },
    },
    BackstageTable: {
      root: {
        '& > div': {
          backgroundColor: theme.palette.common.white,
        },
        '& tr:hover': {
          backgroundColor: theme.palette.cardHeader,
        },
      },
    },
    BackstageTableToolbar: {
      root: {
        padding: theme.spacing(2, 0, 2, 2),
      },
      title: {
        '& > *': {
          fontSize: theme.typography.h6.fontSize,
          margin: 0,
        },
      },
    },
    BackstageTableHeader: {
      header: {
        backgroundColor: theme.palette.primary.main,
        '& *': {
          fill: theme.palette.getContrastText(theme.palette.primary.dark),
          color: `${theme.palette.getContrastText(
            theme.palette.primary.dark,
          )} !important`,
        },
      },
    },
    // This additional theme customization is required to set the DismissableBanner closeButtonColor
    // This problem is solved in the latest Backstage version (set theme.palette.banner.closeButtonColor in basicTheme below)
    BackstageDismissableBanner: {
      content: {
        color: theme.palette.banner.text,
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 3px 6px #00000029',
      },
    },
    MuiBreadcrumbs: {
      ol: {
        marginTop: theme.spacing(1),
        color: '#B0BEC5',
      },
      li: {
        '&:last-child > p': {
          color: `${theme.palette.primary.main} !important`,
        },
        '& a:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(32, 89, 97, .6)',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
      },
    },
    MuiDialogTitle: {
      root: {
        '& > h2': {
          letterSpacing: '0px',
          fontWeight: 500,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(3),
      },
    },
    MuiDrawer: {
      paper: {
        boxShadow: 'none',
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3),
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '1px solid',
        '&:hover': {
          border: '1px solid',
        },
      },
      sizeSmall: {
        textTransform: 'none',
      },
      sizeLarge: {
        textTransform: 'none',
      },
    },
    MuiTableHead: {
      root: {
        '& th': {
          color: theme.palette.primary.main,
          textTransform: 'uppercase',
          fontWeight: 700,
          '& span': {
            color: `${theme.palette.primary.main}`,
            textTransform: 'uppercase',
            fontWeight: 700,
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: `${theme.palette.highlight} !important`,
        },
      },
      hover: {
        '&:hover': {
          backgroundColor: `${theme.palette.highlight} !important`,
          color: theme.palette.getContrastText(theme.palette.primary.light),
        },
      },
    },
    MuiTableSortLabel: {
      active: {
        color: `${theme.palette.primary.main} !important`,
      },
      root: {
        color: theme.palette.primary.main,
      },
      icon: {
        fill: theme.palette.primary.main,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
      },
      stickyHeader: {
        backgroundColor: theme.palette.background.default,
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: theme.palette.cardHeader,
        color: theme.palette.primary.main,
        padding: theme.spacing(1.5, 2),
      },
      title: {
        fontWeight: 700,
        margin: 0,
        fontSize: theme.typography.h6.fontSize,
      },
      action: {
        marginTop: 0,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: theme.palette.cardHeader,
        color: theme.palette.text.primary,
      },
      deleteIconColorPrimary: {
        color: theme.palette.text.primary,

        '&:hover': {
          color: theme.palette.text.primary,
        },
      },
    },
  };
};

export const basicTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#00ACC1',
      light: '#33BCCD',
      dark: '#007887',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4E6980',
      light: '#718799',
      dark: '#364959',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#4E6980',
    },
    error: {
      main: '#8c4351',
      light: '#F6675C',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F8F8F8',
    },
    background2: '#F5F5F5',
    background3: '#E0F7FA',
    backdrop: '#20596199',
    header: {
      background: '#FFFFFF',
      highlight: '#00ACC1',
      text: '#4E6980',
      contrastText: '#FFFFFF',
    },
    cardHeader: '#E0F7FA',
    highlight: '#E0F7FA',
    banner: {
      info: '#343b58',
      error: '#F3ECED',
      text: '#381A20',
      link: '#381A20',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#002C3F',
      indicator: '#002C3F',
      color: '#d5d6db',
      selectedColor: '#ffffff',
    },
    green: '#00C853',
    red: '#FF5252',
    orange: '#F57C00',
    yellow: '#F9A825',
    additional: '#7C4DFF',
    disabled: '#dcdcdc',
  },
  fontFamily: 'Poppins',
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
  },
});

export const createTypography = (_theme: BackstageTheme): Typography => {
  return {
    ...basicTheme.typography,
    fontFamily: ['Poppins'].join(','),
  };
};

const theme: BackstageTheme = {
  ...basicTheme,
  overrides: {
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(basicTheme),
  },
  typography: {
    ...createTypography(basicTheme),
  },
};

export const LightAgileTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = {
  id: 'theme-light',
  title: 'Light',
  variant: 'light',
  Provider: ({ children }) => {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    );
  },
};
