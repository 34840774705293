import React, { useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: theme.palette.header.highlight,
      color: theme.palette.header.contrastText,
      cursor: 'pointer',
      '& svg': {
        color: theme.palette.header.contrastText,
      },
    },
    icon: {
      minWidth: '24px',
      marginRight: theme.spacing(1),
    },
    menuSubItem: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      color: theme.palette.header.contrastText,
      opacity: '0.53',
      borderBottom: '1px solid rgba(255, 255, 255, .5)',
      '& svg': {
        marginRight: theme.spacing(1),
      },
      '&:hover': {
        opacity: '1',
      },
      '& p': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },

      '&:last-child': {
        borderBottom: '0',
      },
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      pointerEvents: 'auto',
      borderRadius: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.header.highlight,
      color: theme.palette.header.contrastText,
      minWidth: '120px',
      boxShadow: '0px 0px 50px #92929226',
    },
  }),
);

export type MeshAppBarMenuItem = {
  icon?: React.FunctionComponent<any>;
  title: string;
  to: string;
};

export type MeshAppBarItemProps = {
  title: string;
  to?: string;
  icon: React.FunctionComponent<any>;
  pathMatch: string[];
  menuItems?: MeshAppBarMenuItem[];
};

export function MeshAppBarItem(props: MeshAppBarItemProps) {
  const { title, icon: Icon, menuItems, pathMatch, to: itemTo } = props;
  const classes = useStyles();
  const popoverAnchor = React.useRef(null);
  const [openedPopover, setOpenedPopover] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handlePopoverOpen = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const active = useMemo(() => {
    return pathMatch.some(v => location.pathname.split('/')?.[1].includes(v));
  }, [location.pathname, pathMatch]);

  return (
    <>
      <ListItem
        aria-haspopup="menu"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        ref={popoverAnchor}
        className={clsx((openedPopover || active) && classes.active)}
        aria-current={active}
        aria-label={title}
        onClick={() => (itemTo ? navigate(itemTo) : {})}
      >
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} style={{ whiteSpace: 'nowrap' }} />
      </ListItem>
      {menuItems && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          disableScrollLock
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          PaperProps={{
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          }}
        >
          {menuItems.map((obj, key) => {
            const { title: objTitle, to, icon: IconObj } = obj;
            return (
              <Link
                component={NavLink}
                to={to}
                underline="none"
                className={classes.menuSubItem}
                onClick={handlePopoverClose}
                onTouchStart={e => e.stopPropagation()}
                key={key}
              >
                {IconObj && <IconObj />}
                <Typography variant="body1">{objTitle}</Typography>
              </Link>
            );
          })}
        </Popover>
      )}
    </>
  );
}
