import {
  AccessRequestNotification,
  AccessResponseNotification,
  InformativeNotification,
  Notification,
  NotificationType,
  QuestionNotification,
} from '@agilelab/plugin-wb-notification-common';
import { Content, PageWithHeader, Progress } from '@backstage/core-components';
import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import NotificationIcon from '@material-ui/icons/NotificationsNone';
import { Alert, Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { AccessRequestNotificationAccordion } from './AccessRequestNotification';
import { QuestionNotificationAccordion } from './QuestionNotification';
import { InformativeNotificationAccordion } from './InformativeNotification';
import { NotificationsFilters } from './NotificationsFilters';
import {
  NotificationDataType,
  useNotification,
} from './useNotificationProvider';
import { Entity } from '@backstage/catalog-model';
import { AccessResponseNotificationAccordion } from './AccessResponseNotification';

export const userCache = new Map<string, Entity>();

function renderNoNotifications(classes: any) {
  return (
    <Grid
      container
      direction="column"
      className={classes.noNotificationsContainer}
    >
      <Grid item>
        <NotificationIcon
          fontSize="inherit"
          className={classes.noNotificationsIcon}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.noNotifications}>
          No notifications
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  noNotifications: {
    fontSize: theme.typography.fontSize * 1.5,
    color: theme.palette.secondary.main,
  },
  noNotificationsIcon: {
    fontSize: theme.typography.fontSize * 4,
  },
  noNotificationsContainer: {
    alignItems: 'center',
  },
  divider: {
    position: 'relative',
    '& hr': {
      borderTop: `1px solid ${theme.palette.primary.light}`,
    },
    '& span': {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '-11px',
      left: '0',
      backgroundColor: theme.palette.background.paper,
      paddingRight: theme.spacing(2),
    },
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

export const NotificationsPageContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [oldData, setOldData] = useState<NotificationDataType>(undefined);
  const [expandedNotification, setExpandedNotification] = React.useState<
    string | boolean
  >(false);
  const {
    page,
    setPage,
    notificationData,
    loadingNotificationData,
    errorNotificationData,
    kind,
    readStatus,
    requestStatus,
  } = useNotification();
  const handlePaginationChange = (
    _event: React.ChangeEvent<unknown>,
    paginationValue: number,
  ) => {
    setPage(paginationValue);
  };

  const handleNotificationAccordionChange = (newExpanded: string | boolean) => {
    setExpandedNotification(newExpanded);
  };

  const notificationRender = (n: Notification) => {
    switch (n.kind) {
      case NotificationType.QUESTION:
        return (
          <QuestionNotificationAccordion
            key={n.id}
            notification={n as QuestionNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.ACCESS_REQUEST:
        return (
          <AccessRequestNotificationAccordion
            key={n.id}
            notification={n as AccessRequestNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.INFORMATIVE:
        return (
          <InformativeNotificationAccordion
            key={n.id}
            notification={n as InformativeNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.ACCESS_RESPONSE:
        return (
          <AccessResponseNotificationAccordion
            key={n.id}
            notification={n as AccessResponseNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!!notificationData) {
      setOldData(notificationData);
    }
  }, [notificationData]);

  useEffect(() => {
    setExpandedNotification(false);
  }, [kind, readStatus, requestStatus]);

  if (!loadingNotificationData && !notificationData && !errorNotificationData) {
    return (
      <Box style={{ marginTop: theme.spacing(6) }}>
        {renderNoNotifications(classes)}
      </Box>
    );
  }

  return (
    <>
      {loadingNotificationData && <Progress />}
      {errorNotificationData instanceof Error && (
        <Alert severity="error">{errorNotificationData.message}</Alert>
      )}
      {oldData && (
        <>
          {oldData.notifications.length === 0 && renderNoNotifications(classes)}
          {oldData.notifications.map(notificationRender)}
          {oldData.notifications.length > 0 && (
            <div className={classes.paginationWrapper}>
              <Pagination
                count={Math.ceil(oldData.total / 20)}
                showFirstButton
                showLastButton
                page={page}
                onChange={handlePaginationChange}
                size="large"
                color="primary"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export const NotificationsPage = () => {
  return (
    <PageWithHeader title="Notification Center" themeId="home">
      <Content>
        <NotificationsFilters />
        <NotificationsPageContent />
      </Content>
    </PageWithHeader>
  );
};
