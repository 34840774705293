import { Chip, ChipProps, Theme, Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      background: `${theme.palette.cardHeader} !important`,
    },
  },
  clickable: {
    '&:focus': {
      background: `${theme.palette.cardHeader} !important`,
    },
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export interface Tag {
  tagFQN: string;
  description?: string;
  labelType?: string;
  state?: string;
  href?: string;
}

type WbTagProps = ChipProps & {
  tag: Tag;
  tooltip?: boolean;
};

export const WbTag: React.FC<WbTagProps> = ({
  tag,
  tooltip = false,
  onClick,
  ...props
}) => {
  const classes = useStyles();

  const chip = (
    <Chip
      key={tag.tagFQN}
      label={tag.tagFQN}
      size="small"
      color="primary"
      classes={{
        root: classes.root,
        clickable: classes.clickable,
        label: tag.href && classes.link,
      }}
      clickable={Boolean(tag.href) || Boolean(onClick)}
      onClick={e => {
        if (tag.href) {
          e.preventDefault();
          e.stopPropagation();
          window.open(tag.href);
        } else onClick?.(e);
      }}
      {...props}
    />
  );

  const tooltipContent = tag.description ?? tag.tagFQN;

  return tooltip ? (
    <Tooltip key={tag.tagFQN} title={tooltipContent} interactive>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};
