import {
  customAlertApiRef,
  ProgressButton,
  useUsername,
} from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Avatar, Link } from '@backstage/core-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import useAsync from 'react-use/lib/useAsync';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useNotificationStyle } from './NotificationsPageStyle';
import {
  NotificationAccordion,
  NotificationAccordionAction,
  NotificationAccordionDetails,
  NotificationAccordionSummary,
} from './NotificationAccordion';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { JsonObject } from 'type-fest';
import { Entity } from '@backstage/catalog-model';
import { userCache } from './NotificationsPage';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { Controller, useForm } from 'react-hook-form';
import { MarkAsReadButton } from './MarkAsReadButton';
import { QuestionNotification } from '@agilelab/plugin-wb-notification-common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notificationApiRef } from '../../notificationApiRef';
import { CustomError } from '@agilelab/plugin-wb-platform-common';

const AnswerButton = (props: { notification: QuestionNotification }) => {
  const { notification } = props;
  const notificationCatalog = useApi(notificationApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);
  const { profile } = useUserProfile();
  const loggedUsername = useUsername();

  const { control, handleSubmit, reset } = useForm<{ answer: string }>({
    mode: 'onChange',
  });

  const { mutate: answerQuestion, isLoading } = useMutation({
    mutationFn: async (response: { id: string; body: any }) =>
      notificationCatalog.updateNotificationResponse(
        response.id,
        response.body,
        await identityApi.getCredentials(),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['paginatedByRecipient'] });
      alertApi.post({
        message: `Answer sent successfully!`,
        severity: 'success',
        timeoutMillis: 5000,
      });
    },
    onError: (error: Error) => {
      alertApi.post({
        error: new CustomError('Failed to send answer', error.message),
        severity: 'error',
        timeoutMillis: 5000,
      });
    },
  });

  const handleResult = useCallback(
    ({ answer }: { answer: string }) => {
      answerQuestion({
        id: notification.id.toString(),
        body: {
          answer: answer,
          answered_by: loggedUsername?.username || profile.email,
          answered_by_display_name:
            loggedUsername?.displayName || profile.displayName,
          timestamp: new Date().toJSON(),
        },
      });
      reset();
      setIsOpen(false);
    },
    [
      answerQuestion,
      loggedUsername?.displayName,
      loggedUsername?.username,
      notification.id,
      profile.displayName,
      profile.email,
      reset,
    ],
  );

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogTitle>Answer the question</DialogTitle>
        <form
          onSubmit={handleSubmit(handleResult)}
          autoComplete="off"
          noValidate
        >
          <DialogContent>
            <Typography
              variant="body1"
              style={{ marginBottom: theme.spacing(1) }}
            >
              <b>{notification.notification_request.asked_by_display_name}</b>{' '}
              asked a question about the Data Product:
            </Typography>
            <Typography variant="body1" color="primary">
              <b>
                {notification.notification_request.dataproduct.display_name ||
                  notification.notification_request.dataproduct.name}
              </b>{' '}
              v<b>{notification.notification_request.dataproduct.version}</b>
            </Typography>
            <Typography variant="body1" style={{ margin: theme.spacing(2, 0) }}>
              <b>Question:</b> {notification.notification_request.question}
            </Typography>

            <Controller
              control={control}
              name="answer"
              rules={{ required: 'Answer required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Answer the question"
                  style={{ width: '100%' }}
                  variant="filled"
                  multiline
                  rows={6}
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions
            style={{ paddingRight: '1.5rem', paddingBottom: '1rem' }}
          >
            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                reset();
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <ProgressButton
              type="submit"
              color="primary"
              variant="contained"
              inProgress={isLoading}
            >
              Send
            </ProgressButton>
          </DialogActions>
        </form>
      </Dialog>
      <Button
        onClick={() => setIsOpen(true)}
        color="primary"
        variant="contained"
      >
        Answer the question
      </Button>
    </>
  );
};

export const QuestionNotificationAccordion = (props: {
  notification: QuestionNotification;
  onAccordionExpansionsChange: (value: string | boolean) => void;
  expandedAccordion: string | boolean;
}) => {
  const { notification, onAccordionExpansionsChange, expandedAccordion } =
    props;
  const classes = useNotificationStyle();
  const catalogApi = useApi(catalogApiRef);
  const theme = useTheme();

  const { value: askerEntity } = useAsync(async (): Promise<
    Entity | undefined
  > => {
    if (userCache.has(notification.notification_request.asked_by))
      return userCache.get(notification.notification_request.asked_by)!;
    const userEntity = await catalogApi.getEntityByRef({
      name: notification.notification_request.asked_by,
      namespace: 'default',
      kind: 'user',
    });
    if (userEntity) {
      userCache.set(notification.notification_request.asked_by, userEntity);
    }
    return userEntity;
  }, [notification]);

  const onToggle = useCallback(
    (_event: object, expanded: boolean) => {
      onAccordionExpansionsChange(
        expanded ? notification.id.toString() : false,
      );
    },
    [notification.id, onAccordionExpansionsChange],
  );

  return (
    <NotificationAccordion
      expanded={expandedAccordion === notification.id.toString()}
      onChange={onToggle}
      style={{
        backgroundColor: !!notification.read_at
          ? 'transparent'
          : theme.palette.common.white,
      }}
    >
      <NotificationAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.summaryHeader}>
          <Typography variant="body2" className={classes.notificationTime}>
            {DateTime.fromISO(notification.created_at).toFormat(
              'dd/MM/yyyy HH:mm',
            )}
          </Typography>
          <MarkAsReadButton notification={notification} />
        </div>
        <div className={classes.summaryDescription}>
          <QuestionAnswerOutlinedIcon color="primary" />
          <Avatar
            displayName={
              notification.notification_request.asked_by_display_name
            }
            picture={
              (askerEntity?.spec?.profile as JsonObject | undefined)
                ?.picture as string
            }
            customStyles={{
              width: theme.spacing(7),
              height: theme.spacing(7),
            }}
          />
          <div className={classes.summaryDescriptionText}>
            <span>
              <b>{notification.notification_request.asked_by_display_name}</b>{' '}
              asked a question about the data product
            </span>
            <span>
              <b>name:</b>{' '}
              <Link
                to={`/marketplace/products-catalog/${notification.notification_request.id_dataproduct_instance}`}
              >
                <Typography variant="body2" color="primary" component="b">
                  {notification.notification_request.dataproduct.display_name ||
                    notification.notification_request.dataproduct.name}
                </Typography>
              </Link>
            </span>
            <span>
              <b>domain:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.domain}
              </Typography>
            </span>
            <span>
              <b>environment:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.environment}
              </Typography>
            </span>
            <span>
              <b>version:</b>{' '}
              <Typography variant="body2" color="primary" component="b">
                {notification.notification_request.dataproduct.version}
              </Typography>
            </span>
          </div>
        </div>
      </NotificationAccordionSummary>
      <NotificationAccordionDetails>
        <div>
          <Typography variant="body1">
            <b>Question: </b>
            {notification.notification_request.question}
          </Typography>
          {notification.notification_response?.answer && (
            <Typography variant="body1" style={{ marginTop: theme.spacing(2) }}>
              <b>Answer: </b>
              {notification.notification_response.answer}
            </Typography>
          )}
        </div>
      </NotificationAccordionDetails>
      <NotificationAccordionAction>
        {!notification.notification_response?.answer && (
          <AnswerButton notification={notification} />
        )}
      </NotificationAccordionAction>
    </NotificationAccordion>
  );
};
