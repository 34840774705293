import { makeStyles, Theme, createStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import {
  KoFilledIcon,
  OkFilledIcon,
  KoIcon,
  OkIcon,
  OkPlusIcon,
  NotStartedIcon,
  TerminatedIcon,
} from '../Icons';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Severity,
  Status as PolicyStatus,
} from '@agilelab/plugin-wb-governance-common';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { blue, orange, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailIcon: {
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },
    icon: {
      width: '1.3em',
      height: '1.3em',
    },
    warningIcon: {
      color: '#ECA311',
    },
    animatedIcon: {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  }),
);

export const DetailIcon: React.FC<{
  status: DagStatus;
  okWithWarnings?: boolean;
  filled?: boolean;
  severity?: Severity;
  policyStatus?: PolicyStatus;
}> = ({ status, filled, severity, policyStatus, okWithWarnings }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailIcon}>
      {
        {
          [DagStatus.FAILED]: (
            <Tooltip title="Failed. You need to solve the errors reported since they will prevent the deployment">
              {filled ? (
                <KoFilledIcon className={classes.icon} />
              ) : (
                <KoIcon className={classes.icon} />
              )}
            </Tooltip>
          ),
          [DagStatus.ERROR]: (
            <Tooltip title="Failed. You need to solve the errors reported since they will prevent the deployment">
              <KoFilledIcon className={classes.icon} />
            </Tooltip>
          ),
          [DagStatus.PASSED]: (
            <Tooltip
              title={
                okWithWarnings
                  ? 'Completed with warnings'
                  : 'Completed with success'
              }
            >
              {/* eslint-disable no-nested-ternary */}
              {filled ? (
                <OkFilledIcon className={classes.icon} />
              ) : okWithWarnings ? (
                <OkPlusIcon className={classes.icon} />
              ) : (
                <OkIcon className={classes.icon} />
              )}
            </Tooltip>
          ),
          [DagStatus.NOT_EXECUTED]: (
            <Tooltip title="Failed. You need to solve the errors reported since they will prevent the deployment">
              <KoFilledIcon className={classes.icon} />
            </Tooltip>
          ),
          [DagStatus.NOT_STARTED]: (
            <Tooltip title="At least one error was encountered in this execution due to a previous policy, so this check was not executed at all">
              <NotStartedIcon className={`${classes.icon}`} />
            </Tooltip>
          ),
          [DagStatus.TERMINATED]: (
            <Tooltip title="The execution has been aborted">
              <TerminatedIcon className={`${classes.icon}`} />
            </Tooltip>
          ),
          [DagStatus.RUNNING]: (
            <AutorenewIcon
              className={`${classes.icon} ${classes.animatedIcon}`}
            />
          ),
          [DagStatus.INFO]: (
            <Tooltip
              title={`Failed with severity ${severity}. This policy is just a warning, and will not block the deployment, but remember to check what errors made it fail`}
            >
              <ErrorIcon className={`${classes.icon}`} htmlColor={blue[500]} />
            </Tooltip>
          ),
          [DagStatus.NOT_BLOCKING_ERROR]: (
            <Tooltip
              title={
                policyStatus === PolicyStatus.Grace
                  ? 'Failed. This policy is in the grace period, so it is not blocking the deployment. Check and solve the errors, since they will block the deployment when this will become enabled'
                  : 'Failed. This policy is deprecated, so it is not blocking the deployment. Check the errors, but remember that this policy will be removed'
              }
            >
              <ErrorIcon
                className={`${classes.icon}`}
                htmlColor={orange[500]}
              />
            </Tooltip>
          ),
          [DagStatus.OK]: (
            <Tooltip title="Compliant">
              <OkFilledIcon className={classes.icon} />
            </Tooltip>
          ),
          [DagStatus.WARNING]: (
            <Tooltip
              title={`Failed with severity ${severity}. This policy is just a warning, and will not block the deployment, but remember to check what errors made it fail`}
            >
              <ErrorIcon
                className={`${classes.icon}`}
                htmlColor={yellow[500]}
              />
            </Tooltip>
          ),
        }[status]
      }
    </div>
  );
};
