import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Search } from './Search';

interface CommonProps {
  onClear?: () => void;
  style?: React.CSSProperties;
}

type ConditionalProps =
  | {
      onSearch: (value: string) => void;
      searchValue: string | undefined;
    }
  | {
      onSearch?: never;
      searchValue?: never;
    };

type Props = CommonProps & ConditionalProps;

export const WbTableFilters: React.FC<Props> = ({
  children,
  onClear,
  searchValue,
  onSearch,
  style,
}) => {
  return (
    <Box style={{ display: 'flex' }}>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
          alignItems: 'center',
          ...style,
        }}
      >
        {onSearch && (
          <Search
            fullWidth={false}
            style={{ width: '300px' }}
            value={searchValue}
            onChange={onSearch}
            debounced
          />
        )}

        {children}
      </Box>

      <Box
        style={{
          flex: 1,
          minWidth: '100px',
          marginLeft: '20px',
          justifyContent: 'flex-end',
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        {React.Children.count(children) > 0 && onClear && (
          <Button
            style={{ marginTop: '8px' }}
            variant="outlined"
            size="small"
            color="primary"
            onClick={onClear}
          >
            Clear filters
          </Button>
        )}
      </Box>
    </Box>
  );
};
