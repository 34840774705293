import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme, Box, Drawer, Typography } from '@material-ui/core';
import { useControlPanel } from '../useControlPanel';
import { extractDagName, getErrors, getParsedResultField } from '../utils';
import {
  GenericField,
  LogsCard,
  WbCard,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { DagStatus, Log } from '@agilelab/plugin-wb-builder-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../api';
import { getStepIcon, getDagStatusColor } from './useStepIcon';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    height: '100%',
    width: '50%',
  },
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& svg': {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1),
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.cardHeader,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 0,
  },
  resultWrapper: {
    position: 'relative',
    height: 'calc(100% - 150px)',
    '& > svg': {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: '50%',
      transform: 'translateX(-50%)',
      width: `300px`,
      height: `300px`,
      fillOpacity: '0.2',
      strokeOpacity: '0.2',
    },
  },
  codeSnippetWrapper: {
    height: '100%',
    '& > div': {
      overflow: 'auto',
      height: '100%',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: 50,
    flexWrap: 'wrap',
  },
}));

export const DeployStepDetailDrawer = () => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);

  const { setSelectedStep, selectedStep, selectedDeploy } = useControlPanel();

  const step = useMemo(() => {
    if (selectedStep && selectedDeploy) {
      return selectedDeploy.steps.find(s => s.id === selectedStep);
    }
    return null;
  }, [selectedStep, selectedDeploy]);

  const result = useMemo(() => {
    if (step) {
      const res = step.result;
      if (res) {
        try {
          return JSON.stringify(JSON.parse(res), null, 2);
        } catch (_) {
          return res;
        }
      }
    }
    return null;
  }, [step]);

  const errors = getErrors(result);
  const parsedResult = getParsedResultField(result, configApi);

  const [logs, setLogs] = useState<Log[]>([]);
  const panelCatalog = useApi(panelCatalogApiRef);

  useEffect(() => {
    if (selectedDeploy && step) {
      const loadData = async () => {
        const logsResponse = await panelCatalog.getLogs(
          selectedDeploy.id,
          step.id,
        );
        setLogs(logsResponse);
      };

      loadData();
    }
  }, [selectedDeploy, step, panelCatalog]);

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{
        style: {
          width: '80%',
        },
      }}
      transitionDuration={200}
      open={!!selectedStep}
      onClose={() => setSelectedStep('')}
    >
      <Box className={classes.drawerContent}>
        {step && (
          <>
            <Box className={classes.header}>
              {getStepIcon(step.status)}
              <Typography color="primary" variant="h6">
                {extractDagName(step)}
              </Typography>
            </Box>

            <WbCard title="Details">
              <WbCardContent>
                <Box className={classes.row}>
                  <GenericField
                    label="Start Time"
                    value={step.startTime}
                    type="date"
                  />
                  {step.status !== DagStatus.RUNNING && step.stopTime && (
                    <GenericField
                      label="Stop Time"
                      value={step.stopTime}
                      type="date"
                    />
                  )}
                  <GenericField
                    label="Status"
                    value={step.status}
                    type="text"
                    contentProps={{
                      style: { color: getDagStatusColor(step.status) },
                    }}
                  />
                  <GenericField
                    label="Action"
                    value={step.action}
                    type="text"
                  />
                </Box>
              </WbCardContent>
            </WbCard>
            {result && (
              <LogsCard
                title="Result"
                text={errors?.join('\n') || parsedResult || result}
                style={{
                  height: '100%',
                  marginTop: '16px',
                  overflow: 'auto',
                }}
              />
            )}
            <LogsCard
              title="Log File"
              logs={logs}
              style={{ height: '100%', marginTop: '16px', overflow: 'auto' }}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};
