import { compactStringRef } from '@agilelab/plugin-wb-builder-common';
import { z } from 'zod';

export const accessRequestNotificationRequestDataZod = z.object({
  id_dataproduct_instance: z.number(),
  id_output_port: z.number(),
  environment: z.string(),
  identities: z.string().array(),
  requester: z.preprocess(
    value => compactStringRef(value as string),
    z.string().regex(new RegExp('^user:.+$')),
  ),
  requester_display_name: z.string(),
  motivation: z.string(),
  dataproduct: z.object({
    id: z.number(),
    name: z.string(),
    display_name: z.string().optional(),
    domain: z.string(),
    version: z.string(),
    data_product_owner: z.string(),
    data_product_display_name: z.string(),
    external_id: z.string(),
  }),
  outputport: z.object({
    name: z.string(),
    output_port_type: z.string(),
    external_id: z.string(),
  }),
});

export type AccessRequestNotificationRequestData = z.infer<
  typeof accessRequestNotificationRequestDataZod
>;
