import { Typography } from '@material-ui/core';
import { FieldProps } from '@rjsf/core';
import React from 'react';

export const TitleField = (props: FieldProps) => {
  return props?.title ? (
    <Typography variant="h6">{props.title}</Typography>
  ) : (
    <></>
  );
};
