import React, { useMemo } from 'react';
import { ThresholdExpression } from '@agilelab/plugin-wb-governance-common';
import { Typography } from '@material-ui/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import { MetricMetadataAddForm } from '../types';
import { MetricNumericFormatCustom } from './MetricNumericFormatCustom';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { WbSelect, WbTextField } from '@agilelab/plugin-wb-platform';

interface Line {
  width: number;
  color: string;
}

interface Threshold {
  value?: number;
  color: string;
  label: string;
}

const parseNumber = (value: string) => {
  return value === '' || value === undefined ? undefined : Number(value);
};

export const MetricThresholdSlider: React.FC<
  UseFormReturn<MetricMetadataAddForm> & {
    width?: number;
    colors: Array<{ label: string; value: string }>;
  }
> = props => {
  const WIDTH = props.width || 300;
  const HEIGHT = 2;
  const BULLET = 16;
  const BULLET_OFFSET = BULLET / 2 - HEIGHT / 2;
  const DEFAULT_LINE_COLOR = '#707070';

  const min = useMemo(() => parseNumber(props.watch('domain.min')), [props]);
  const max = useMemo(() => parseNumber(props.watch('domain.max')), [props]);
  const range = useMemo(() => props.watch('expression'), [props]);
  const thresholds: Threshold[] = useMemo(
    () =>
      (props.watch('thresholds') || []).map(t => ({
        ...t,
        value: parseNumber(t.value),
        color:
          props.colors.find(c => c.label === t.color)?.value ||
          DEFAULT_LINE_COLOR,
      })),
    [props],
  );

  const lines: Line[] = useMemo(() => {
    const isValid = min !== undefined && max !== undefined && range;

    if (isValid) {
      return (
        thresholds
          .filter(t => {
            if (t.value === undefined || !Boolean(t.color)) {
              return false;
            }

            switch (range) {
              case ThresholdExpression.Above:
                return t.value < max && t.value! >= min;
              case ThresholdExpression.AboveOrEqual:
                return t.value! <= max && t.value! >= min;
              case ThresholdExpression.Below:
                return t.value! <= max && t.value! > min;
              case ThresholdExpression.BelowOrEqual:
                return t.value! <= max && t.value! >= min;
              default:
                return t;
            }
          })
          .map(t => {
            const value = t.value || 0;

            const valueByRange: Record<ThresholdExpression, number> = {
              [ThresholdExpression.BelowOrEqual]: value - min,
              [ThresholdExpression.Below]: value - min,
              [ThresholdExpression.AboveOrEqual]: max - value,
              [ThresholdExpression.Above]: max - value,
            };

            const thresholdLine = max - min;
            const thresholdLineWidth = valueByRange[range];
            const width = (WIDTH * thresholdLineWidth) / thresholdLine;

            return { width, color: t.color || DEFAULT_LINE_COLOR };
          })
          .sort((a, b) => a.width - b.width)
          // EACH LINE WIDTH = ITS WIDTH - PREV LINE WIDTH
          .map((l, index, array) => ({
            ...l,
            width: index > 0 ? l.width - array[index - 1].width : l.width,
          }))
      );
    }

    return [];
  }, [WIDTH, max, min, range, thresholds]);

  const isAbove = useMemo(
    () =>
      range &&
      (range === ThresholdExpression.Above ||
        range === ThresholdExpression.AboveOrEqual),
    [range],
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: 30, alignItems: 'baseline' }}>
        <Controller
          control={props.control}
          name="domain.min"
          render={({ field, fieldState: { error } }) => (
            <WbTextField
              style={{ width: 100 }}
              helperText={error?.message}
              error={Boolean(error)}
              {...field}
              InputProps={{
                inputComponent: MetricNumericFormatCustom as any,
              }}
            />
          )}
        />

        <div style={{ position: 'relative', width: WIDTH }}>
          {/* DEFAULT LINE */}
          <div
            style={{
              width: WIDTH,
              height: HEIGHT,
              background: DEFAULT_LINE_COLOR,
              position: 'absolute',
            }}
          />

          {/* RANGE LINES */}
          <div
            style={{
              display: 'flex',
              flexDirection: isAbove ? 'row-reverse' : 'row',
              marginLeft: 0,
            }}
          >
            {lines.map(line => (
              <div
                style={{
                  width: line.width,
                  background: line.color,
                  height: HEIGHT,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: BULLET,
                    height: BULLET,
                    borderRadius: '100%',
                    background: line.color,
                    ...(isAbove
                      ? { left: -BULLET_OFFSET }
                      : { right: -BULLET_OFFSET }),
                    top: -BULLET_OFFSET,
                    zIndex: 2,
                    position: 'absolute',
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <Controller
          control={props.control}
          name="domain.max"
          render={({ field, fieldState: { error } }) => (
            <WbTextField
              style={{ width: 100 }}
              helperText={error?.message}
              error={Boolean(error)}
              {...field}
              InputProps={{
                inputComponent: MetricNumericFormatCustom as any,
              }}
            />
          )}
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          gap: 25,
          margin: '15px 0px',
        }}
      >
        <Typography>The expression when evaluated value is</Typography>

        <Controller
          control={props.control}
          name="expression"
          render={({ field, fieldState: { error } }) => (
            <WbSelect
              options={Object.values(ThresholdExpression)}
              getOptionLabel={v => snakeCaseToTitleCase(v)}
              style={{ width: 300 }}
              label="Expression"
              helperText={error?.message}
              error={Boolean(error)}
              {...field}
            />
          )}
        />

        <Typography>the threshold.</Typography>
      </div>
    </div>
  );
};
