import { Log } from '../../types';

export function convertTimestamp(timestamp: string) {
  const d = new Date(timestamp);
  const date = d.toISOString().split('T')[0];
  const time = d.toLocaleTimeString();
  return `${date} ${time}`;
}

const scope = (log: Log) => (log.task ? `[${log.task}] ` : '');

export function stringifyLogs(logs: Log[]): string {
  return logs
    .map(
      l =>
        `${convertTimestamp(l.timestamp)} - ${l.severity} - ${scope(l)}${
          l.message
        }`,
    )
    .join('\n');
}
