import { SelectFilter } from '@agilelab/plugin-wb-platform';
import {
  useEntityList,
  useStarredEntities,
  useEntityOwnership,
  UserListFilter,
  UserListFilterKind,
} from '@backstage/plugin-catalog-react';
import React from 'react';

export const CustomUserListPicker: React.FC = () => {
  const { updateFilters, filters } = useEntityList();
  const { isStarredEntity } = useStarredEntities();
  const { isOwnedEntity } = useEntityOwnership();

  const values: Record<string, string> = {
    all: 'All',
    starred: 'Favorites',
  };

  return (
    <SelectFilter<string>
      field="Category"
      options={['starred']}
      value={filters.user?.value === 'all' ? undefined : filters.user?.value}
      renderOption={o => values[o]}
      renderValue={o => values[o]}
      onChange={v => {
        updateFilters({
          user: new UserListFilter(
            (Boolean(v) ? v : 'all') as UserListFilterKind,
            isOwnedEntity,
            isStarredEntity,
          ),
        });
      }}
    />
  );
};
