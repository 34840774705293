import { z } from 'zod';

export enum CustomErrorName {
  CustomError = 'CustomError',
  CoordinatorError = 'CoordinatorError',
  GitRepositoryError = 'GitRepositoryError',
}

export class CustomError extends Error {
  readonly name: CustomErrorName;
  readonly errorMessage: string;

  constructor(message: string, errorMessage: string, options?: ErrorOptions) {
    super(message, options);
    this.name = CustomErrorName.CustomError;
    this.message = message;
    this.errorMessage = errorMessage;
  }
}

export const CoordinatorErrorInfoSchema = z.object({
  code: z.string().min(1),
  userMessage: z.string().min(1),
  moreInfo: z.object({
    problems: z.array(z.string()),
    solutions: z.array(z.string()),
  }),
  input: z.string().optional().nullable(),
  inputErrorField: z.string().optional().nullable(),
});

export type CoordinatorErrorInfo = z.infer<typeof CoordinatorErrorInfoSchema>;

export class CoordinatorError extends Error {
  readonly name = CustomErrorName.CoordinatorError;
  readonly code: string;
  readonly input: string | null | undefined;
  readonly inputErrorField: string | null | undefined;
  readonly moreInfo: {
    problems: string[];
    solutions: string[];
  };

  constructor(errorInfo: CoordinatorErrorInfo, options?: ErrorOptions) {
    super(errorInfo.userMessage, options);
    this.message = errorInfo.userMessage;
    this.code = errorInfo.code;
    this.moreInfo = errorInfo.moreInfo;
    this.input = errorInfo.input;
    this.inputErrorField = errorInfo.inputErrorField;
  }
}

export enum GitRepositoryErrorCode {
  NotLastCommit = 'NOT_LAST_COMMIT',
  GitPushError = 'GIT_PUSH_ERROR',
}

export const GitRepositoryErrorInfoSchema = z.object({
  code: z.nativeEnum(GitRepositoryErrorCode),
  title: z.string(),
  userMessage: z
    .string()
    .min(1)
    .describe('A user-friendly message that can be displayed in the UI'),
  error: z.string().min(1).describe('The actual error message'),
});

export type GitRepositoryErrorInfo = z.infer<
  typeof GitRepositoryErrorInfoSchema
>;
export class GitRepositoryError extends CustomError {
  readonly name = CustomErrorName.GitRepositoryError;
  readonly code: GitRepositoryErrorCode;
  readonly error: string;

  constructor(errorInfo: GitRepositoryErrorInfo, options?: ErrorOptions) {
    super(errorInfo.title, errorInfo.userMessage, options);
    this.error = errorInfo.error;
    this.code = errorInfo.code;
  }
}
