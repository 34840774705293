import {
  Status as PolicyStatus,
  ResourceType,
  TestStatus,
  GovernanceEntityType,
} from '@agilelab/plugin-wb-governance-common';

export type ProxyResponse<T> = {
  httpStatus: number;
  body: T;
};

/**
 * @param descriptor - a json string of the descriptor
 */
export type DeploymentRequest = {
  descriptor: string;
};

export type ValidationPhaseSummary = {
  results: ValidationPhaseResult[];
};

export enum DagStatus {
  NOT_BLOCKING_ERROR = 'NOT_BLOCKING_ERROR',
  OK = 'OK',
  FAILED = 'FAILED',
  INFO = 'INFO',
  WARNING = 'WARNING',
  RUNNING = 'RUNNING',
  PASSED = 'COMPLETED',
  NOT_EXECUTED = 'NOT_EXECUTED',
  NOT_STARTED = 'NOT_STARTED',
  ERROR = 'ERROR',
  TERMINATED = 'TERMINATED', // execution aborted (e.g. timeout, manual termination)
}

export type ValidationPhaseResult = {
  validationPhaseKind: ValidationPhaseKind;
  validatedDescriptor: string | null;
  policyId: string;
  errors: ValidationError;
  status: DagStatus;
};

export type ValidationResponse = ProxyResponse<
  ValidationPhaseSummary | ValidationInvalidInput | ValidationSystemError
>;

export type ValidationResponseStatus = {
  status: 'RUNNING' | 'COMPLETED' | 'FAILED';
  validationSummaryStatus: {
    results: Array<ValidationPhaseResult>;
  };
};

export type ValidationError = {
  errors: string[];
};

export type Pagination = {
  offset: number;
  limit: number | null;
  count: number;
};

export type Dag = {
  id: string;
  name: string;
  displayName: string;
  descriptor: string;
  version: string;
  status: DagStatus;
  action: TaskAction;
  result: string | null;
  startTime: string;
  stopTime: string;
  dependsOnTasks: Dag[];
  componentName?: string;
};

export type ProvisioningPlan = {
  environment: string;
  dag: Dag;
};

export type PaginatedProvisioningPlansResponse = {
  provisioningPlans: ProvisioningPlan[];
  page: Pagination;
};

export type DeployStatusWithResult = {
  status: DagStatus;
  result: string | null;
};

export enum ValidationPhaseKind {
  INTERNAL_VALIDATION_PHASE = 'INTERNAL_VALIDATION_PHASE', // TODO da chiedere ERRORE NON GESTITO => result === null
  POLICY_DATAPRODUCT_VALIDATION_PHASE = 'POLICY_DATAPRODUCT_VALIDATION_PHASE', // CUE -> result policy non ci sarà più
  POLICY_COMPONENT_VALIDATION_PHASE = 'POLICY_COMPONENT_VALIDATION_PHASE', // CUE
  COMPONENT_VALIDATION_PHASE = 'COMPONENT_VALIDATION_PHASE', // result -> specific provisioner
  DATAPRODUCT_VALIDATION_PHASE = 'DATAPRODUCT_VALIDATION_PHASE',
  DATAPRODUCT_CATALOG_VALIDATION_PHASE = 'DATAPRODUCT_CATALOG_VALIDATION_PHASE',
}

// Bug specific down result = string

export type ValidationInvalidInput = {
  errors: string[];
};

export type ValidationSystemError = {
  error: string;
};

export type PolicyValidateResult = {
  status: TestStatus;
  id: string;
  evaluationScope: 'evaluation';
  environment: string;
  evaluationResults: {
    governanceEntityId: string;
    governanceEntityStatus: PolicyStatus;
    governanceEntityType: GovernanceEntityType;
    outcome: string;
    resource?: {
      id: string;
      displayName: string;
      environment: string;
      resourceType: ResourceType;
      descriptor: string;
      filter: string | null;
    };
    result?: {
      details: Record<string, any>;
      satisfiesPolicy?: boolean;
      value?: number;
      thresholdResult?: any;
      isError: boolean;
      errors: string[];
    };
  }[];
};

export enum TaskAction {
  PROVISION = 'PROVISION',
  UNPROVISION = 'UNPROVISION',
  UPDATEACL = 'UPDATEACL',
  MARKETPLACE_UPDATE = 'MARKETPLACE_UPDATE',
  PROVISION_DATAPRODUCT = 'PROVISION_DATAPRODUCT',
  UNPROVISION_DATAPRODUCT = 'UNPROVISION_DATAPRODUCT',
  VALIDATION = 'VALIDATION',
  NOP = 'NOP',
  POLICY_VALIDATE_COMPONENT = 'POLICY_VALIDATE_COMPONENT',
  VALIDATE_COMPONENT = 'VALIDATE_COMPONENT',
  VALIDATE_DATAPRODUCT = 'VALIDATE_DATAPRODUCT',
}

export interface Log {
  timestamp: string;
  severity: string;
  task: string;
  message: string;
  phase?: string;
}
