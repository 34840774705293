import React from 'react';
import { LayoutTemplate, createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';
import { Popper, PopperProps, Tooltip } from '@material-ui/core';

const CustomPopper = (props: PopperProps) => {
  return <Popper {...props} style={{ width: 850 }} placement="bottom-start" />;
};

export const TableRowTemplate: LayoutTemplate = props => {
  return (
    <tr style={{ background: 'white' }}>
      {props.properties.map(element => {
        const errors: string[] =
          element.content.props?.errorSchema?.__errors || [];

        const cloned = React.cloneElement(element.content, {
          uiSchema: {
            customProps: {
              helperText: '',
              label: '',
              InputProps: {
                style: {
                  borderRadius: 0,
                  width: '100%',
                  height: '100%',
                  minWidth: 200,
                },
              },
              PopperComponent: CustomPopper,
            },
            ...element.content.props.uiSchema,
          },
        });
        return (
          <td key={element.content.key} style={{ height: '100%', padding: 0 }}>
            {errors.length ? (
              <Tooltip title={errors.join('\n')}>
                <div style={{ width: '100%', height: '100%' }}>{cloned}</div>
              </Tooltip>
            ) : (
              cloned
            )}
          </td>
        );
      })}

      {(props.schema as any).RowButtons}
    </tr>
  );
};

export const TableRowTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'TableRowTemplate',
    component: TableRowTemplate,
  }),
);
