import { MonacoEditor } from './WbEditor';
import { Range } from 'monaco-editor';

export const highlightLines = (editor: MonacoEditor, lines?: Array<number>) => {
  // Clear previous decorations
  const allDecorations = editor.getModel()?.getAllDecorations();
  const decorationIds = allDecorations?.map(decoration => decoration.id);
  if (decorationIds) editor.removeDecorations(decorationIds);
  // Highlight specific lines
  const decorations = lines?.map(lineNumber => ({
    range: new Range(lineNumber, 1, lineNumber, 1),
    options: { isWholeLine: true, className: 'highlight-code-line' },
  }));
  editor.createDecorationsCollection(decorations);
};

export const goToLine = (editor: MonacoEditor, line: number | undefined) => {
  editor.revealLineInCenter(line ?? 0);
};
