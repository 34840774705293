import React from 'react';
import {
  makeStyles,
  createStyles,
  Chip,
  Tooltip,
  Typography,
  Theme,
} from '@material-ui/core';
import { DetailIcon } from '../DetailIcon';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';
import { Task } from '../../types';

interface Props {
  policy: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
  task: Task;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailName: {
      width: '150px',
      minWidth: '150px',
    },
    policyDesc: {
      flex: 1,
    },
    version: {
      padding: theme.spacing(0, 2),
    },
    chip: {
      margin: 0,
    },
    truncated: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      display: '-webkit-box',
      marginRight: theme.spacing(1),
      wordBreak: 'break-all',
    },
  }),
);

export const PolicyComponentItem: React.FC<Props> = ({ policy, task }) => {
  const classes = useStyles();

  return (
    <>
      <DetailIcon
        status={task.status}
        filled
        severity={policy.content.severity}
        policyStatus={task.governanceEntityStatus}
      />
      <div className={classes.detailName}>
        <Tooltip title={policy?.name ?? ''}>
          <Typography variant="body2" className={classes.truncated}>
            <strong>{policy.name}</strong>
          </Typography>
        </Tooltip>
      </div>
      <div className={classes.version}>
        <Tooltip title={`version: ${policy.version}`}>
          <Typography variant="body2">{`v:${policy.version}`}</Typography>
        </Tooltip>
      </div>
      <div className={classes.policyDesc}>
        <Tooltip title={policy.description ?? ''}>
          <Typography variant="body2" className={classes.truncated}>
            {policy?.description}
          </Typography>
        </Tooltip>
      </div>
      <Chip label="Policy" className={classes.chip} color="primary" />
    </>
  );
};
